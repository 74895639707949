<template>
    <div>
        <v-dialog
            v-model="pin_build_location_model"
            width="500"
            persistent>
            <v-card>
                <v-card-title>Pin Build Location</v-card-title>
                <v-card-text>
                    <v-text-field
                        v-model="address"
                        label="Address"
                        placeholder="Enter your build location address"
                        outlined
                        dense
                        prepend-inner-icon="mdi-map"
                        >
                    </v-text-field>
                    <v-text-field
                        v-model="town"
                        label="Town (Required)"
                        placeholder="Enter your build location town"
                        outlined
                        dense
                        prepend-inner-icon="mdi-map"
                        >
                    </v-text-field>
                    <v-autocomplete
                        :items="get_counties"
                        label="County (Required)"
                        placeholder="Enter your build location county"
                        item-value="id"
                        item-text="name"
                        v-model="county"
                        return-object
                        outlined
                        dense
                        prepend-inner-icon="mdi-map">
                    </v-autocomplete>
                    <v-text-field
                        v-model="eircode"
                        label="Eircode (Required)"
                        placeholder="Enter your build location eircode."
                        outlined
                        dense
                        prepend-inner-icon="mdi-map"
                        >
                    </v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error"
                        class="px-6" @click="evtClosePinBuildLocationDialog()">
                        <v-icon class="mr-2">mdi-close</v-icon>
                        Close
                    </v-btn>
                    <v-btn color="rgb(6, 42, 130)"
                        class="px-6 white--text" @click="evtSavePinBuildLocation()">
                        <v-icon class="mr-2">mdi-content-save</v-icon>
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import GeoLocation from '../modules/GeoLocation.js'

export default {
    data: () => ({
        pin_build_location_model: false,
        address: null,
        town: null,
        county: null,
        eircode: null,
        geo_location: null
    }),
    props: {
        pin_build_location_dialog: Boolean
    },
    computed: {
        ...mapGetters({
            get_counties:               'auth/get_counties',
            getuser:                    'auth/getuser',
            getCountyByCountyName:      'auth/getCountyByCountyName'
        })
    },
    methods: {
        evtClosePinBuildLocationDialog() {
            this.$emit('evtClosePinBuildLocationDialog')
        },
        evtSavePinBuildLocation() {
            this.geo_location.saveClientPinBuildLocation({
                address: this.address,
                town: this.town,
                county: this.county.name,
                zipcode: this.eircode
            })
            this.evtClosePinBuildLocationDialog()
        }
    },
    mounted() {
        this.$axios.get('counties').then(({data}) => {
            this.$store.dispatch('auth/set_counties', data.data)
        })
        this.geo_location = new GeoLocation(this.$store)
    },
    watch: {
        pin_build_location_dialog() {
            this.pin_build_location_model = this.pin_build_location_dialog
            if ( this.pin_build_location_dialog == true ) {
                this.address = this.getuser.info.address
                this.town = this.getuser.info.town
                this.county = this.getCountyByCountyName(this.getuser.info.county).length > 0 ? this.getCountyByCountyName(this.getuser.info.county)[0] : null
                this.eircode = this.getuser.info.zipcode
            }
        }
    }
}
</script>
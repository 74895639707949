<template>
    <v-container fluid class="v-container-padding mt-5 flex">
        <v-card>
            <v-card-title style="background: #062a82;">
                <v-icon class="mr-2" style="color: white;">mdi-video</v-icon>
                <small style="color: white;">Video Archive</small>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <VideoArchivedDisplay></VideoArchivedDisplay>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VideoArchivedDisplay from './modules/components/VideoArchivedDisplay.vue';

export default {
    data: () => ({

    }),
    components: {
        VideoArchivedDisplay
    }, 
    computed: {
        ...mapGetters({
            get_user:   'auth/getuser'
        })
    },
    methods: {
        ...mapActions({
            'fetch_video_archives':         'video_archived/fetch_video_archives_actions'
        })
    },
    mounted() {
        this.fetch_video_archives();        
    }
}
</script>

<style lang="scss" scoped>
.v-container-padding {
    padding-bottom: 100px;
}
</style>
import Vue from "vue"

export default {
    namespaced: true,
    state: {
        user: {},
        snackbar: {
            show: false,
            message: "",
            button: false,
        },
        emailfrequency: [],
        supplier_data: {},
        counties: [],
        service_types: [],
        errormessage: {
            title: "",
            message: "",
            opendialog: false
        }
    },
    mutations: {
        setuser(state, payload){
            state.user = payload
        },
        setMessage(state, v) {
            state.snackbar.show = v.show
            state.snackbar.message = v.message
            state.snackbar.button = v.button
        },
        updateProjectId(state, payload){
            state.user.project_id = payload
        },
        setemailfrequency(state, payload){
            state.emailfrequency = [...payload]
        },
        async mutateemailfrequency(state){
            await Vue.axios.get('profile/gdatafrequency')
            .then(({data}) => {
                const emailfrequencydata = data.data
                state.emailfrequency = [...emailfrequencydata]
            })
        },
        updateuserinfo(state, payload){
            state.user.info = payload
        },
        setcompanyprofile(state, payload){
            state.user.company = payload
        },
        set_supplier_data(state, payload){
            state.supplier_data = payload
        },
        set_counties(state, payload){
            state.counties = [...payload]
        },
        set_service_types(state, payload){
            state.service_types = [...payload]
        },
        set_newly_added_project_mutations(state, payload) {
            state.user.projects.splice(state.user.projects.length, 0, payload);            
        },
        set_selected_project(state, payload) {
            state.user.project_id = payload;
        },
        update_edited_project_mutations(state, payload) {
            state.user.project_id = payload;
            const searchIndex = state.user.projects.findIndex(project => project.id == payload.id);
            state.user.projects.splice(searchIndex, 1, payload);
        },
        delete_selected_project_mutations(state, payload) {
            /*const searchIndex = state.user.projects.findIndex(project => project.id == payload.id);
            state.user.projects.splice(searchIndex, 1);
            if (state.user.projects.length > 0) {
                state.user.project_id = state.user.projects[0];
            } else {
                state.user.project_id = null;
            }*/
            const searched_project = state.user.projects.find(project => project.id == payload.id);
            const remaining_projects = state.user.projects.filter( project => project.name != searched_project.name);
            state.user.projects = remaining_projects;

            if (state.user.projects.length > 0) {
                state.user.project_id = state.user.projects[0];
            } else {
                state.user.project_id = null;
            }
        },
        set_errormessage_dialog_mutation(state, payload) {
            state.errormessage = payload;
        },
        updateUserAccessIdMutation(state, payload) {
            state.user.info.access_id = payload.access_id
        },
        updateUserBuildLocationMutation(state, payload) {
            state.user.info.address = payload.address
            state.user.info.town = payload.town
            state.user.info.county = payload.county
            state.user.info.zipcode = payload.zipcode
        }
    },
    getters: {
        getuser(state){
            return state.user
        },
        mSnackbar(state){
            return state.snackbar
        },
        getproject(state){
            return state.user.project_id
        },
        getemailfrequency(state){
            return state.emailfrequency
        },
        get_supplier_data(state){
            return state.supplier_data
        },
        get_counties : state => state.counties,
        get_service_types : state => state.service_types,
        get_projects: state => state.user.projects,
        get_errormessage: state => state.errormessage,
        getCountyByCountyName: state => (county) => {
            return state.counties.filter( (item) => item.name === county)
        }
    },
    actions: {
        setuser({commit}, payload){
            commit('setuser', payload)
        },
        setcompanyprofile({commit}, payload){
            commit('setcompanyprofile', payload)
        },
        set_supplier_data({commit}, payload){
            commit('set_supplier_data', payload)
        },
        set_counties({commit}, payload){
            commit('set_counties', payload)
        },
        set_service_types({commit}, payload){
            commit('set_service_types', payload)
        },
        set_newly_added_project_action({commit}, payload) {
            commit('set_newly_added_project_mutations', payload);
        },
        set_selected_project({commit}, payload) {
            commit('set_selected_project', payload);
        },
        async edit_selected_project_actions({commit}, payload) {
            await Vue.axios.patch('projectplanner/etemplate', payload)
            .then( ({data}) => {
                // Update ProjectID
                commit('updateProjectId', data.project_id);
                // Update Project List
                commit('update_edited_project_mutations', data.project_id);
            });
        },
        delete_selected_project_actions({commit}, payload) {
            commit('delete_selected_project_mutations', payload);          
        },
        update_project_id_actions({commit}, payload) {
            commit('updateProjectId', payload);
        },
        set_errormessage_dialog_action(context, payload) {
            context.commit('set_errormessage_dialog_mutation', payload);
        },
        updateUserAccessIdAction({commit}, payload) {
            commit('updateUserAccessIdMutation', payload)
        },
        updateUserBuildLocationAction({commit}, payload) {
            commit('updateUserBuildLocationMutation', payload)
        }
    }
}
<template>
    <div>
        <v-chip
            class="ma-2"
            color="success"
            outlined
            v-for="(item, index) in data"
            :key="index"
            @click="fetch_category(item)"
        >
            <v-icon
              class="mr-2"
            >
              {{ item.icon }}
            </v-icon>
            <label
                style="font-weight: 900; font-size: 16px;"
            >{{ item.name }}</label>
        </v-chip>
    </div>
</template>

<script>
export default {
  components: {
  },
  props: [
    'data'
  ],
  data () {
    return {
    }
  },
  mounted () {
  },
  created () {
  },
  computed: {
  },
  methods: {
    fetch_category(data){
      this.$store.dispatch('shop/filter_by_category', data.name)
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>
<template>
    <div>
        <v-card class="pb-10">
            <v-card-title style="background: #062a82;">
                <v-icon class="mr-2" style="color: white;"></v-icon>
                <small style="color: white;">
                    Site Diary {{ number }}
                    <v-icon color="white" style="cursor: pointer;" @click="show_tutorial = true">mdi-help-circle-outline</v-icon>
                </small>
                <v-spacer></v-spacer>
                <v-btn                                        
                    class="pl-16 pr-16 white--text"
                    color="#0b4ef3"
                    rounded
                    @click="show_add_sitediary_dialog()">
                <v-icon
                  class="mr-2">
                  mdi-plus
                </v-icon>
                add entry
              </v-btn>
            </v-card-title>
            <v-card-text>
                <v-toolbar class="mt-5 pt-3"
                    elevation="1">
                    <v-text-field
                        v-model="search_sitediary"                     
                        prepend-inner-icon="mdi-magnify"
                        placeholder="search by log name | notes | tags..."
                        label="search by log name | notes | tags...">
                    </v-text-field>
                    <v-spacer></v-spacer>
                    <v-switch
                        v-model="deleted_sitedairy"
                        label="Show Deleted Site Diary"></v-switch>
                </v-toolbar>

                <!-- Site Diary Data-->
                <v-data-table
                    :headers="headers"
                    :items="get_sitediaries"   
                    class="elevation-1"
                    :search="search_sitediary"
                    v-if="!deleted_sitedairy">                                                  
                    <template v-slot:item="{ item }">
                        <tr>
                            <td @click="editchild(item)" style="cursor: pointer;">{{ item.log_name }}</td>
                            <td @click="editchild(item)" style="cursor: pointer;">{{ item.notes }}</td>
                            <td @click="editchild(item)" style="cursor: pointer;">
                                <label
                                v-for="(item, itemindex) in item.tags"
                                :key="itemindex">                      
                                <v-badge>
                                    <span slot="badge">{{item}}</span>
                                </v-badge>
                                <br/>
                                </label>
                            </td>
                            <td @click="editchild(item)" style="cursor: pointer;">{{ item.created_at }}</td>
                            <td @click="editchild(item)" style="cursor: pointer;">{{ item.site_diary_date }}</td>
                            <td @click="viewimage(item)" style="cursor: pointer;">
                                <img :src="`${img_url}frontend/images/${item.image}`" @error="show_error_image($event)" style="max-width:125px; max-height:125px;"/>
                            </td>
                        </tr>
                    </template>
                </v-data-table>

                <!-- Deleted Site Diary Data-->
                <v-data-table
                    :headers="deleted_headers"
                    :items="get_deleted_sitediaries"                   
                    class="elevation-1"
                    :search="search_sitediary"
                    v-else>                                                  
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>{{ item.log_name }}</td>
                            <td>{{ item.notes }}</td>
                            <td>
                                <label
                                v-for="(item, itemindex) in item.tags"
                                :key="itemindex">                      
                                <v-badge>
                                    <span slot="badge">{{item}}</span>
                                </v-badge>
                                <br/>
                                </label>
                            </td>
                            <td>{{ item.created_at }}</td>
                            <td>{{ item.site_diary_date }}</td>
                            <td>
                                <img :src="`${img_url}frontend/images/${item.image}`" @error="show_error_image($event)" style="max-width:125px; max-height:125px;"/>
                            </td>
                            <td>
                                <v-btn text color="success" small
                                    @click="restore_sitediary(item)">
                                    <v-icon>mdi-restore</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        
        <v-dialog
            persistent
            v-model="show_tutorial"
            width="1000">
          <v-card>
            <v-card-title>
              Tutorial
                <v-spacer></v-spacer>
                <v-icon
                    small
                    color="red"
                    @click="show_tutorial = false;">
                mdi-close
              </v-icon>
            </v-card-title>
            <v-divider></v-divider>
            <vue-pdf-embed source="site_diary.pdf" />
          </v-card>
        </v-dialog>
        
        <!-- Add Site Diary -->
        <AddSiteDiary
            :show_add_sitediary_dialog="show_add_sitediary_dialog_component"
            :project_id="getproject.id"
            @closedialog="close_add_sitediary_dialog()"></AddSiteDiary>
        
        <!-- Edit Site Diary -->
        <EditSiteDiary
            :show_edit_sitediary_dialog="open_edit_sitediary_dialog_component"
            :item="edit_item"
            @closedialog="close_edit_sitediary_dialog()"></EditSiteDiary>
        
        <!-- Confirm Dialog Box -->
        <v-row justify="center">
            <v-dialog
                max-width="420"
                v-model="restore_confirm_dialog"
                persistent>
                <v-card>
                    <v-card-title style="fontSize: 18px;">
                        <small>Are you sure you want to restore this entry?</small>
                    </v-card-title>

                    <v-card-text>
                        <small>All of the data that belongs to this entry will be restored and ready to be used. Are you sure you want to restore?</small>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text color="success" class="pl-16 pr-16" @click="restore_deleted_sitediary_data()">Yes</v-btn>
                        <v-btn color="#093cba" class="pl-16 pr-16" dark @click="restore_confirm_dialog = false">No</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <!-- ./Confirm Dialog Box -->

        <!-- View Image -->
        <ViewImage
            :image_src="imageviewer.src"
            :open_imageview_dialog="imageviewer.open_imageviewer"
            @closedialog="close_imageview_dialog()">
        </ViewImage>
        <!-- ./View Image -->
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AddSiteDiary from './AddSiteDiary.vue';
import EditSiteDiary from './EditSiteDiary.vue';
import ViewImage from './ViewImage.vue';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';

export default {
    data: () => ({
        number: 0,
        show_tutorial: false,
        deleted_sitedairy: false,
        headers: [
            { text: 'Log Name', value: 'log_name', filterable: true },
            { text: 'Notes', value: 'notes', filterable: true},
            { text: 'Tags', value: 'tags', filterable: true},
            { text: 'Date', value: 'created_at', filterable: true, sortable: true},
            { text: 'Site Date', value: 'site_diary_date', filterable: true, sortable: true},
            { text: 'Media', filterable: true}
        ],
        deleted_headers: [
            { text: 'Log Name', value: 'log_name', filterable: true },
            { text: 'Notes', value: 'notes', filterable: true},
            { text: 'Tags', value: 'tags', filterable: true},
            { text: 'Date', value: 'created_at', filterable: true, sortable: true},
            { text: 'Site Date', value: 'site_diary_date', filterable: true, sortable: true},
            { text: 'Media', filterable: true},
            { text: 'Action', value: ''}
        ],
        img_url: process.env.VUE_APP_URL,
        show_add_sitediary_dialog_component: false,
        open_edit_sitediary_dialog_component: false,
        edit_item: {},
        search_sitediary: '',       
        restore_confirm_dialog: false,
        data_to_restore: {},
        imageviewer: {
            src: null,
            open_imageviewer: false,
            error_image: '@/assets/logo.png',
        }           
    }),
    components: {
        VuePdfEmbed,
        AddSiteDiary,
        EditSiteDiary,
        ViewImage
    },
    computed: {
        ...mapGetters({
            getuser:                    'auth/getuser',
            getproject:                 'auth/getproject',
            get_sitediaries:            'sitediary/get_sitediaries_getter',
            get_deleted_sitediaries:    'sitediary/get_deleted_sitediaries_getter'
        })
    },
    methods: {
        ...mapActions({
            fetch_sitediaries:               'sitediary/fetch_sitediaries_action',
            fetch_deleted_sitediaries:       'sitediary/fetch_deleted_sitediaries_action',
            restore_deleted_sitediary:       'sitediary/restore_deleted_sitediary_action'
        }),
        editchild(item) {
            this.edit_item = item;
            this.open_edit_sitediary_dialog_component = true;
        },
        show_add_sitediary_dialog() {
            this.show_add_sitediary_dialog_component = true;
        },
        close_add_sitediary_dialog() {
            this.show_add_sitediary_dialog_component = false;
        },
        close_edit_sitediary_dialog() {
            this.open_edit_sitediary_dialog_component = false;
        },
        restore_sitediary(item) {
            this.restore_confirm_dialog = true;
            this.data_to_restore = item;
        },
        restore_deleted_sitediary_data() {
            this.restore_deleted_sitediary({
                item: this.data_to_restore
            });
            this.restore_confirm_dialog = false;
        },
        show_error_image(event) {
            event.target.src = require("@/assets/logo.png");
        },
        viewimage(item) {
            this.imageviewer.src = `${this.img_url}frontend/images/${item.image}`;
            this.imageviewer.open_imageviewer = true;
        },
        close_imageview_dialog() {
            this.imageviewer.src = null;
            this.imageviewer.open_imageviewer = false;
        }
    },
    mounted() {        
        /** All fetching of data should be put here. */
        this.fetch_sitediaries({
            user_id: this.getuser.id,
            project_id: this.getproject.id
        });

        this.fetch_deleted_sitediaries({
            user_id: this.getuser.id,
            project_id: this.getproject.id
        });
    },
    created() {
        
    }
}
</script>
<template>
    <v-row justify="center">
        <v-dialog
            :value="value"
            persistent
            width="560"
        >
        <v-card>
            <v-card-title>
                <label
                    style="font-weight: 500; font-size: medium;"
                >
                    {{ get_to_edit_item.name }}
                </label>
            </v-card-title>
            <v-card-subtitle>
                Edit this item
            </v-card-subtitle>
            <v-card-text
                v-if="Object.keys(get_to_edit_item).length > 0"
            >
                <v-row>
                    <v-col
                        cols="12"
                        lg="12"
                        sm="12"
                    >
                        <label
                            style="font-weight: 500; font-size: small;"
                        >Item Name</label>
                        <v-text-field
                            outlined
                            dense
                            v-model="get_to_edit_item.name"
                            prepend-icon="mdi-database-plus"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        lg="12"
                        sm="12"
                    >
                        <label
                            style="font-weight: 500; font-size: small;"
                        >Image</label>
                        <v-file-input
                            truncate-length="15"
                            v-model="get_to_edit_item.file"
                            outlined
                            dense
                        ></v-file-input>
                    </v-col>
                    <v-row>
                        <v-col
                            cols="12"
                            lg="12"
                            sm="12"
                        >
                            <label
                                style="font-weight: 500; font-size: small;"
                            >
                                Rating
                            </label>
                            <v-rating
                                length="5"
                                v-model="get_to_edit_item.rating"
                                half-increments
                            ></v-rating>
                        </v-col>
                        <v-col
                            cols="4"
                            lg="4"
                            sm="4"
                        >
                            <label
                                style="font-weight: 500; font-size: small;"
                            >
                                (zł) Original Price
                            </label>
                            <v-text-field
                                v-model="get_to_edit_item.orignal_price"
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                        <v-col
                            cols="4"
                            lg="4"
                            sm="4"
                        >
                            <label
                                style="font-weight: 500; font-size: small;"
                            >
                                Price
                            </label>
                            <v-text-field
                                v-model="get_to_edit_item.price_raw"
                                outlined
                                dense
                                prepend-icon="mdi-currency-eur"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col
                            cols="4"
                            lg="4"
                            sm="4"
                        >
                            <label
                                style="font-weight: 500; font-size: small;"
                            >
                                % Markup
                            </label>
                            <v-text-field
                                v-model="get_to_edit_item.markup"
                                outlined
                                dense
                                prepend-icon="mdi-percent-outline"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-col
                        cols="12"
                        lg="12"
                        sm="12"
                    >
                        <label
                            style="font-weight: 500; font-size: small;"
                        >
                            Location
                        </label>
                        <v-text-field
                            dense
                            outlined
                            v-model="get_to_edit_item.location"
                            prepend-icon="mdi-database-plus"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        lg="12"
                        sm="12"
                    >
                        <v-combobox
                            chips
                            clearable
                            label="Add Tags"
                            multiple
                            prepend-icon="mdi-database-plus"
                            dense
                            outlined
                        ></v-combobox>
                    </v-col>
                    <v-col
                        cols="12"
                        lg="12"
                        sm="12"
                    >
                        <label
                            style="font-weight: 500; font-size: small;"
                        >Description</label>
                        <wysiwyg
                            v-model="get_to_edit_item.description"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="green darken-1"
                text
                @click="close_dialog()"
            >
                Close
            </v-btn>
            <v-btn
                outlined
                class="pl-10 pr-10"
                rounded
                @click="dialog = false"
            >
                Save
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
  },
  props: [
    'value'
  ],
  data () {
    return {
    }
  },
  mounted () {
  },
  created () {
  },
  computed: {
    ...mapGetters({
        get_to_edit_item:           'admin_shop/get_to_edit_item'
    })
  },
  methods: {
    close_dialog(){
        this.$emit('close_dialog')
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
@import "~vue-wysiwyg/dist/vueWysiwyg.css";
</style>
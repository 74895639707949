import store from '@/states/index.js'
import DesignTracker from '../DesignTracker.vue'

export default [
    {
        path: '/designtracker',
        component: DesignTracker,
        name: 'designtracker',
        beforeEnter: (to, from, next) => {
            const role = store.getters['auth/getuser']           
            if(role.info.role === 3){
                next()
            }else{
                next('/')
            }
            return
        }
    }
]
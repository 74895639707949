<template>
    <div>
        <v-list class="pb-16">
            <v-list-group
                v-for="item in get_dt_appliance"
                :key="item.title"
                v-model="item.active"
                no-action
                :disabled="userAccessParent(item)">
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title style="color: #0b4ef3;">
                            <v-badge
                                :color="userAccessParent(item) == true ? 'warning' : '#0b4ef3'"
                                bordered>
                                <v-icon slot="badge" v-if="userAccessParent(item) == true">mdi-exclamation-thick</v-icon>
                                <v-icon slot="badge" v-else>mdi-check-bold</v-icon>
                                <span slot="default"><strong>{{item.room}}</strong></span>
                            </v-badge>
                            <v-progress-linear
                                :buffer-value="100"
                                :value="item_percentage(item)"
                                striped
                                height="15"
                                color="#7b9ff9">
                                <template v-slot:default="{ value }">
                                    <strong>{{ value.toFixed(2) }}%</strong>
                                </template>
                            </v-progress-linear>
                        </v-list-item-title>
                    </v-list-item-content>
                </template>
                <v-data-table
                    :headers="header"
                    :items="item.getchild"
                    item-key="task_name"
                    class="elevation-1 custom-table"
                    style="font-weight:700;"
                    :hide-default-footer="true"
                    disable-pagination
                    mobile-breakpoint="0">
                    <template v-slot:item="{item}">
                        <tr v-if="userAccessChild(item) == false">
                            <td><v-checkbox v-model="item['is_done']" @change="mark_dtappliance(item)"></v-checkbox></td>
                            <td @click="edit_dt_appliance(item)" style="cursor: pointer; white-space: pre-line;"><small>{{item['item']}}</small></td>
                            <td @click="edit_dt_appliance(item)" style="cursor: pointer;"><small v-if="item['quantity'] == null">add a quantity</small><small v-else>{{item['quantity']}}</small></td>
                            <td @click="edit_dt_appliance(item)" style="cursor: pointer;"><small v-if="item['color'] == null">add a color</small><small v-else>{{item['color']}}</small></td>
                            <td @click="edit_dt_appliance(item)" style="cursor: pointer;"><small v-if="item['supplier_id'] == null">add a supplier</small><small v-else>{{item['supplier_id']}}</small></td>
                            <td @click="edit_dt_appliance(item)" style="cursor: pointer;"><small v-if="item['product_code'] == null">add a product code</small><small v-else>{{item['product_code']}}</small></td>
                            <td style="cursor: pointer;"><small @click="edit_dt_appliance(item)" v-if="item['link_to_product'] == null">add a link to product</small><a :href="item['link_to_product']" target="_blank" v-else><small>{{ item['link_to_product'] }}</small></a></td>
                            <td @click="edit_dt_appliance(item)" style="cursor: pointer;"><small v-if="item['notes'] == null">add a note</small><small v-else>{{item['notes']}}</small></td>
                            <td @click="edit_dt_appliance(item)" style="cursor: pointer;"><small v-if="item['budget'] == null">add a budget</small><small v-else>{{item['budget']}}</small></td>
                            <td @click="edit_dt_appliance(item)" style="cursor: pointer;"><small v-if="item['actual'] == null">add an actual</small><small v-else>{{item['actual']}}</small></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-list-group>
        </v-list>

        <v-row justify="center">
            <v-dialog
                v-model="edit_appliance_data_dialog"               
                persistent
                scrollable
                width="520">
                <v-card>
                    <v-card-title>
                        <v-icon
                            class="mr-2">
                            mdi-pencil
                        </v-icon>
                        <small>edit <strong>{{item}}</strong></small>
                    </v-card-title>
                    <v-card-text>
                        <div
                            class="mt-5">
                                <v-text-field
                                    label="Item"
                                    hint="Item"
                                    placeholder="Item"
                                    outlined
                                    dense
                                    type="text"
                                    prepend-icon="mdi-tooltip-text-outline"                                    
                                    v-model="item"></v-text-field>
                                
                                <v-text-field
                                    label="Quantity"
                                    hint="Quantity"
                                    placeholder="Quantity"
                                    outlined
                                    dense
                                    type="number"                                   
                                    prepend-icon="mdi-tooltip-text-outline"
                                    v-model="quantity"></v-text-field>

                                <v-text-field
                                    label="Colour"
                                    hint="Colour"
                                    placeholder="Colour"
                                    outlined
                                    dense
                                    type="text"                                    
                                    prepend-icon="mdi-tooltip-text-outline"
                                    v-model="color"></v-text-field>

                                <v-text-field
                                    label="Supplier"
                                    hint="Supplier"
                                    placeholder="Supplier"
                                    outlined
                                    dense
                                    type="text"                                    
                                    prepend-icon="mdi-tooltip-text-outline"
                                    v-model="supplier"></v-text-field>
                                
                                <v-text-field
                                    label="Product Code"
                                    hint="Product Code"
                                    placeholder="Product Code"
                                    outlined
                                    dense
                                    type="text"                                    
                                    prepend-icon="mdi-tooltip-text-outline"
                                    v-model="product_code"></v-text-field>
                                
                                <v-text-field
                                    label="Link to Product"
                                    hint="Link to Product"
                                    placeholder="Link to Product"
                                    outlined
                                    dense
                                    type="text"                                    
                                    prepend-icon="mdi-tooltip-text-outline"
                                    v-model="link_to_product"></v-text-field>
                                
                                <v-text-field
                                    label="Budget"
                                    hint="Budget"
                                    placeholder="Budget"
                                    outlined
                                    dense
                                    type="number"                                    
                                    prepend-icon="mdi-tooltip-text-outline"
                                    v-model="budget"></v-text-field>
                                
                                <v-text-field
                                    label="Actual"
                                    hint="Actual"
                                    placeholder="Actual"
                                    outlined
                                    dense
                                    type="number"                                    
                                    prepend-icon="mdi-tooltip-text-outline"
                                    v-model="actual"></v-text-field>
                        </div>
                        <v-divider></v-divider>
                        <div
                            class="mt-5">
                            <v-textarea
                                v-model="notes"
                                outlined
                                label="Notes"                               
                                hint="Notes"                                
                                prepend-inner-icon="mdi-tooltip-text-outline"></v-textarea>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="close_appliace_dialog()"                 
                        class="mx-5">
                        Close
                    </v-btn>
                    <v-btn
                        color="#0b4ef3"
                        dark                        
                        class="pr-16 pl-16"
                        @click="save_appliance_data()">
                        Save
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DesignTracker from '../modules/DesignTracker.js';

export default {
    data: () => ({
        header: [
            { text: '' },
            { text: 'Item' },
            { text: 'Quantity' },
            { text: 'Colour' },
            { text: 'Supplier' },
            { text: 'Product Code' },
            { text: 'Link To Product' },
            { text: 'Notes' },
            { text: 'Budget' },
            { text: 'Actual' }
        ],
        id: null,
        parent_id: null,
        item: null,
        quantity: 0,
        color: null,
        supplier: null,
        product_code: null,
        link_to_product: null,
        notes: null,
        budget: 0,
        actual: 0,
        edit_appliance_data_dialog: false,
        design_tracker: new DesignTracker(),
    }),
    props: {
        User: Object,
        Project: Object
    },
    computed: {
        ...mapGetters({
            get_dt_appliance:       'designtracker/get_dt_appliance_getter'
        })
    },
    methods: {
        ...mapActions({
            fetch_dt_appliance:     'designtracker/fetch_dt_appliance_action',
            save_dt_appliance:      'designtracker/save_dt_appliance_action',
            mark_dt_appliance:      'designtracker/mark_dt_appliance_action'
        }),
        mark_dtappliance(item) {
            this.mark_dt_appliance({
                id: item.id,
                parent_id: item.parent_id,
                data: {
                    is_done: item.is_done
                }
            })
        },
        edit_dt_appliance(item) {
            this.id = item.id
            this.parent_id = item.parent_id
            this.item = item.item
            this.quantity = item.quantity
            this.color = item.color
            this.supplier = item.supplier_id
            this.product_code = item.product_code
            this.link_to_product = item.link_to_product
            this.notes = item.notes
            this.budget = item.budget
            this.actual = item.actual

            this.edit_appliance_data_dialog = true
        },
        close_appliace_dialog() {
            this.edit_appliance_data_dialog = false
        },
        save_appliance_data() {
            this.save_dt_appliance({
                id: this.id,
                parent_id: this.parent_id,
                data: {
                    item: this.item,
                    quantity: this.quantity,
                    color: this.color,
                    supplier_id: this.supplier,
                    product_code: this.product_code,
                    link_to_product: this.link_to_product,
                    notes: this.notes,
                    budget: this.budget,
                    actual: this.actual
                }
            })
            this.edit_appliance_data_dialog = false
        },
        item_percentage(item) {
            return (item.getchild.filter(item => item.is_done == true).length / item.getchild.length) * 100
        },
        userAccessParent(item) {
           if ( this.User.info.access_id == 1 ) {                              
                return item.room == this.design_tracker.getApplianceDefaultData().room ? false : true                    
           } else {
                return false
           }
        },
        userAccessChild(item) {
            if ( this.User.info.access_id == 1 ) {                           
                if ( item.room == this.design_tracker.getApplianceDefaultData().room ) {
                    const child = this.design_tracker.getApplianceDefaultData().items.filter( child => child.item == item.item )
                    return child.length > 0 ? false : true
                } else {
                    return true
                }
            } else {
                return false
            }
        }
    },
    mounted() {
        this.fetch_dt_appliance({user_id: this.User.id, project_id: this.Project.id})
    }
}
</script>
import { render, staticRenderFns } from "./SupplierLostQuotes.vue?vue&type=template&id=a1b48600&scoped=true"
import script from "./SupplierLostQuotes.vue?vue&type=script&lang=js"
export * from "./SupplierLostQuotes.vue?vue&type=script&lang=js"
import style0 from "./SupplierLostQuotes.vue?vue&type=style&index=0&id=a1b48600&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a1b48600",
  null
  
)

export default component.exports
<template>
    <div>
        <div v-if="setup.group">
            <v-row
                class="mb-5 mt-5 justify-center"
            >
                <v-btn
                  color="#ffcf00"
                  v-for="(itemBtn, indexBtn) in setup.btngroupdata"
                  :key="indexBtn"
                  @click="itemBtn.url_name.name == null ? buttonaction(itemBtn.what) : $router.push(itemBtn.url_name)"
                  class="mr-3 mb-2"
                  rounded
                  elevation="0"
                >
                  <v-icon class="mr-2">{{itemBtn.icon}}</v-icon>{{itemBtn.name}}
                </v-btn>
            </v-row>
        </div>
        <div v-if="setup.single">
          <v-btn
            color="#0b4ef3"
            dark
            rounded
            outlined
            class="pl-16 pr-16"
            @click="setup.url_name.name == null ? buttonaction() : $router.push(setup.url_name.name)"
          >
            {{setup.label}}
          </v-btn>
        </div>
    </div>
</template>

<script>
export default {
  components: {
  },
  props: [
    'setup'
  ],
  data: () => ({
    toggle_none: null
  }),
  mounted () {
  },
  created () {
  },
  computed: {
  },
  methods: {
    buttonaction(data){
      this.$emit('buttonaction', data)
    },
    test2(){
      console.log('test2')
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>
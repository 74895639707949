import Vue from 'vue';
import { MessageBox } from '@/components/messagebox/module/messagebox';

export default {
    namespaced: true,
    state: {
        reminders: []
    },
    mutations: {
        set_reminders_data_mutation(state, payload) {
            state.reminders = [...payload];
        },
        set_complete_reminder_mutation(state, payload) {
            const reminder = state.reminders.find(reminder => reminder.id === payload.id);
            reminder.is_completed = true;
            reminder.status = 'Completed';
        },
        set_uncomplete_reminder_mutation(state, payload) {
            const reminder = state.reminders.find(reminder => reminder.id === payload.id);
            reminder.is_completed = false;
            reminder.status = 'Pending';
        },
        add_reminder_mutation(state, payload) {
            state.reminders.push(payload);
        }
    },
    getters: {
        get_reminders_data_getter: state => (is_done) => {
            if (is_done == false) {
                return state.reminders.filter(reminder => reminder.status == 'Pending');
            } else {
                return state.reminders.filter(reminder => reminder.status == 'Completed');
            }
        }
    },
    actions: {
        fetch_all_reminders_data_action(context, payload) {
            const messagebox = new MessageBox(context);
            Vue.axios.get(`/reminders/get_all_reminders_data/${payload.users_id}/${payload.project_id}`)
                .then((data) => {
                    if (data.status == 200) {
                        const result = data.data;
                        if (result.response == true) {
                            context.commit('set_reminders_data_mutation', result.data);
                        } else {
                            messagebox.set_messagebox_details({
                                title: `${data.status}: ${data.statusText}`,
                                message: `File: ${data.data.file}, \r Line: ${data.data.line}, \r Message: ${data.data.message}`,
                            });
                            messagebox.show_message_dialog();
                        }
                    } else {
                        messagebox.set_messagebox_details({
                            title: `${data.status}: ${data.statusText}`,
                            message: `File: ${data.data.file}, \r Line: ${data.data.line}, \r Message: ${data.data.message}`,
                        });
                        messagebox.show_message_dialog();
                    }
                });
        },
        set_complete_reminder_action(context, payload) {
            const messagebox = new MessageBox(context);
            Vue.axios.post(`/reminders/update_reminder_data`, {
                'id': payload.id,
                'data': {
                    'status': 'Completed'
                }
            }).then((data) => {
                if (data.status == 200) {
                    const result = data.data;
                    if (result.response == true) {
                        context.commit('set_complete_reminder_mutation', payload);
                    } else {
                        messagebox.set_messagebox_details({
                            title: `${data.status}: ${data.statusText}`,
                            message: `File: ${data.data.file}, \r Line: ${data.data.line}, \r Message: ${data.data.message}`,
                        });
                        messagebox.show_message_dialog();
                    }
                } else {
                    messagebox.set_messagebox_details({
                        title: `${data.status}: ${data.statusText}`,
                        message: `File: ${data.data.file}, \r Line: ${data.data.line}, \r Message: ${data.data.message}`,
                    });
                    messagebox.show_message_dialog();
                }
            });
        },
        set_uncomplete_reminder_action(context, payload) {
            const messagebox = new MessageBox(context);
            Vue.axios.post(`/reminders/update_reminder_data`, {
                'id': payload.id,
                'data': {
                    'status': 'Pending'
                }
            }).then((data) => {
                if (data.status == 200) {
                    const result = data.data;
                    if (result.response == true) {
                        context.commit('set_uncomplete_reminder_mutation', payload);
                    } else {
                        messagebox.set_messagebox_details({
                            title: `${data.status}: ${data.statusText}`,
                            message: `File: ${data.data.file}, \r Line: ${data.data.line}, \r Message: ${data.data.message}`,
                        });
                        messagebox.show_message_dialog();
                    }
                } else {
                    messagebox.set_messagebox_details({
                        title: `${data.status}: ${data.statusText}`,
                        message: `File: ${data.data.file}, \r Line: ${data.data.line}, \r Message: ${data.data.message}`,
                    });
                    messagebox.show_message_dialog();
                }
            });
        },
        add_reminder_action(context, payload) {
            const messagebox = new MessageBox(context);
            Vue.axios.post(`/reminders/create_reminder_data`, payload).then((data) => {
                if (data.status == 200) {
                    const result = data.data;
                    if (result.response == true) {
                        context.commit('add_reminder_mutation', result.data);
                    } else {
                        messagebox.set_messagebox_details({
                            title: `${data.status}: ${data.statusText}`,
                            message: `File: ${data.data.file}, \r Line: ${data.data.line}, \r Message: ${data.data.message}`,
                        });
                        messagebox.show_message_dialog();
                    }
                } else {
                    messagebox.set_messagebox_details({
                        title: `${data.status}: ${data.statusText}`,
                        message: `File: ${data.data.file}, \r Line: ${data.data.line}, \r Message: ${data.data.message}`,
                    });
                    messagebox.show_message_dialog();
                }
            });
        }
    }
}
<template>
    <v-row justify="center">
        <v-dialog
            v-model="add_reminder_dialog"
            persistent
            max-width="650">
            <v-card>
                <v-card-title>
                    <v-icon class="mr-2">mdi-reminder</v-icon>
                    Add Reminder
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-form
                        ref="add_reminder_form"
                        v-model="valid"
                        lazy-validation>
                        <v-text-field
                            v-model="reminder_name"
                            label="Remindeer"
                            hint="Reminder"
                            :rules="rules.reminder_name"
                            prepend-icon="mdi-tooltip-text-outline"
                            required
                            class="mt-3"
                            autofocus>
                        </v-text-field>
                    </v-form>
                </v-card-text>                
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="success"
                        @click="evt_close_add_reminder()">
                        Close
                    </v-btn>
                    <v-btn color="#093cba" class="pl-16 pr-16 white--text"
                        @click="evt_add_reminder()">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data: () => ({
        add_reminder_dialog: false,
        valid: true,
        reminder_name: null,
        rules: {
            reminder_name: [
                v => !!v || 'You are required to have a reminder name.'
            ]
        }
    }),
    props: {
        open_add_reminder_dialog: Boolean
    },
    computed: {
        ...mapGetters({
            getuser:              'auth/getuser',
            getproject:           'auth/getproject',
        }),
    },
    methods: {
        ...mapActions({
            add_reminder:   'reminders/add_reminder_action'
        }),
        evt_add_reminder() {
            this.valid = this.$refs.add_reminder_form.validate();
            if ( this.valid == true ) {
                this.add_reminder({
                    'users_id': this.getuser.id,
                    'project_id': this.getproject.id,
                    'todo': this.reminder_name,
                    'status': 'Pending'
                });
                this.evt_close_add_reminder();
                this.$refs.add_reminder_form.reset();
            }
        },
        evt_close_add_reminder() {
            this.$emit('close_add_reminder_dialog');
        }
    },
    watch: {
        open_add_reminder_dialog() {
            this.add_reminder_dialog = this.open_add_reminder_dialog
        }
    }
}
</script>
<template>
    <v-container
        fluid
    >
        <v-col
            cols="4"
        >
            <AddCat
                @add_category="add_category"
            />
        </v-col>
        <v-col
            cols="12"
        >
            <CatList 
                :data="get_categories"
                @filter_item_via_category="filter_item_via_category"
            />
        </v-col>
        <v-col
            cols="12"
        >
            <v-text-field
                v-model="search"
                outlined
                dense
                label="Search here..."
                prepend-inner-icon="mdi-magnify"
            >
            </v-text-field>
            <v-btn
                class="float-right"
                rounded
                outlined
                @click="add_item"
            >
                <v-icon>
                    mdi-plus
                </v-icon>
                add item
            </v-btn>
            <ItemList
                :data_item="get_items"
            />
            <AddItem
                :value="add_item_model"
                @cancel_dialog_add="cancel_dialog_add"
            />
        </v-col>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
// category related
import AddCat from './components/AddCategory.vue'
import CatList from './components/CategoriesList.vue'
// end category related
// item related
import ItemList from './components/ItemList.vue'
import AddItem from './components/AddItem.vue'
// end item related
export default {
  components: {
    CatList,
    AddCat,
    ItemList,
    AddItem
  },
  props: [
  ],
  data () {
    return {
        search: null,
        add_item_model: false
    }
  },
  async mounted () {
    await this.$store.dispatch('admin_shop/get_categories')
  },
  created () {
  },
  computed: {
    ...mapGetters({
        get_categories:         'admin_shop/get_categories',
    }),
    get_items(){
        return this.$store.getters['admin_shop/get_items'](this.search)
    }
  },
  methods: {
    async add_category(item){
        let tp = {
            name:   item,
            icon:   'mdi-toy-brick-outline'
        }
        await this.$store.dispatch('admin_shop/add_category', tp)
        await this.$store.dispatch('admin_shop/get_categories')
    },
    filter_item_via_category(item){
        this.$store.dispatch('admin_shop/get_items_via_cat_id', item)
    },
    add_item(){
        this.add_item_model = true
    },
    cancel_dialog_add(){
        this.add_item_model = false
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>
import Vue from "vue";

export default {
    namespaced: true,
    state: {
        todos: [],
        dailylogs: [],
        filemanager: [],
        snags: [],
        decisions: [],
        fff: [],
        appliances: [],
        fc: []
    },
    mutations: {
        set_todos_mutation(state, payload) {
            state.todos = payload;
        },
        set_dailylogs_mutation(state, payload) {
            state.dailylogs = payload;
        },
        set_snags_mutation(state, payload) {
            state.snags = payload;
        },
        set_filemanager_mutation(state, payload) {
            state.filemanager = payload;
        },
        set_decisions_mutation(state, payload) {
            state.decisions = payload;
        },
        set_designtracker_fff_mutation(state, payload) {
            state.fff = payload;
        },
        set_designtracker_appliances_mutation(state, payload) {
            state.appliances = payload;
        },
        set_designtracker_fc_mutation(state, payload) {
            state.fc = payload;
        }
    },
    getters: {
        get_todos_getter: state => {           
            return {               
                series: [state.todos.pending_count ?? 0, state.todos.completed_count ?? 0],
                chart_options: {
                    labels: ['Pending', 'Completed'],
                    chart: {
                        type: 'donut'
                    },
                    fill: {
                        colors: ['#2396f1', '#53af5c']
                    },
                    dataLabels: {
                        enabled: true
                    },
                    legend: {
                        position: 'bottom'
                    },
                }
            }
        },
        get_sitediary_getter: state => {            
            return {
                series: state.dailylogs.series ?? [],
                chart_options: {
                    labels: state.dailylogs.labels ?? [],
                    chart: {
                        type: 'polarArea'
                    },
                    dataLabels: {
                        enabled: true
                    },
                    yaxis: {
                        show: false
                    },
                    legend: {
                        position: 'bottom'
                    },
                    toolbar: {
                        show: true,
                        tools: {
                            zoom: true
                        }
                    }
                }
            }
        },
        get_snags_getter: state => {
            return {               
                series: [state.snags.count_pending ?? 0, state.snags.count_completed ?? 0],
                chart_options: {
                    labels: ['Pending', 'Completed'],
                    chart: {
                        type: 'donut'
                    },
                    fill: {
                        colors: ['#2396f1', '#53af5c']
                    },
                    dataLabels: {
                        enabled: true
                    },
                    legend: {
                        position: 'bottom'
                    },
                }
            }
        },
        get_filemanager_getter: state => {
            return {
                series: state.filemanager.series ?? [],
                chart_options: {
                    labels: state.filemanager.categories ?? [],
                    chart: {
                        type: 'polarArea'
                    },
                    dataLabels: {
                        enabled: true
                    },
                    yaxis: {
                        show: false
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }
        },
        get_decisions_getter: state => {            
            return {
                series: state.decisions.series ?? [{name: 'series-1', data: []}],
                chart_options: {
                    chart: {
                        type: 'bar',                        
                        stacked: true,
                        stackType: '100%'
                    },
                    xaxis: {
                        categories: state.decisions.categories ?? []
                    }
                }
            }
        },
        get_designtracker_fff_getter: state => {
            return {
                series: state.fff.series ?? [{name: 'series-1', data: []}],
                chart_options: {
                    chart: {
                        type: 'bar',                        
                        stacked: true,
                        stackType: '100%'
                    },
                    xaxis: {
                        categories: state.fff.categories ?? []
                    }
                }
            }
        },
        get_designtracker_appliances_getter: state => {
            return {
                series: state.appliances.series ?? [{name: 'series-1', data: []}],
                chart_options: {
                    chart: {
                        type: 'bar',                        
                        stacked: true,
                        stackType: '100%'
                    },
                    xaxis: {
                        categories: state.appliances.categories ?? []
                    }
                }
            }
        },
        get_designtracker_fc_getter: state => {
            return {
                series: state.fc.series ?? [{name: 'series-1', data: []}],
                chart_options: {
                    chart: {
                        type: 'bar',                        
                        stacked: true,
                        stackType: '100%'
                    },
                    xaxis: {
                        categories: state.fc.categories ?? []
                    }
                }
            }
        }
    },
    actions: {
        fetch_reminders_action({commit}, payload) {           
            Vue.axios.get(`/dashboard/get_reminders_data/${payload.users_id}/${payload.project_id}`)
            .then( ({data}) => {
                if (data.response == true) {               
                    commit('set_todos_mutation', data.data);
                }
            });
        },
        fetch_sitediary_action({commit}, payload) {           
            Vue.axios.get(`/dashboard/get_sitediary_data/${payload.users_id}/${payload.project_id}`)
            .then( ({data}) => {                
                commit('set_dailylogs_mutation', data.data);
            });
        },
        fetch_snags_action({commit}, payload) {            
            Vue.axios.get(`/dashboard/get_snags_data/${payload.users_id}/${payload.project_id}`)
            .then( ({data}) => {               
                commit('set_snags_mutation', data.data);
            });
        },
        fetch_filemanager_action({commit}, payload) {            
            Vue.axios.get(`/dashboard/get_filemanager_data/${payload.users_id}/${payload.project_id}`)
            .then( ({data}) => {
                commit('set_filemanager_mutation', data.data);
            });
        },
        fetch_decisions_action({commit}, payload) {            
            Vue.axios.get(`/dashboard/get_decision_data/${payload.users_id}/${payload.project_id}`)
            .then( ({data}) => {
                commit('set_decisions_mutation', data.data);
            });
        },
        fetch_designtracker_fff_action({commit}, payload) {            
            Vue.axios.get(`/dashboard/get_designtracker_fff_data/${payload.users_id}/${payload.project_id}`)
            .then( ({data}) => {
                commit('set_designtracker_fff_mutation', data.data);
            })
        },
        fetch_designtracker_appliances_action({commit}, payload) {            
            Vue.axios.get(`/dashboard/get_designtracker_appliance_data/${payload.users_id}/${payload.project_id}`)
            .then( ({data}) => {
                commit('set_designtracker_appliances_mutation', data.data);
            })
        },
        fetch_designtracker_fc_action({commit}, payload) {            
            Vue.axios.get(`/dashboard/get_designtracker_fc_data/${payload.users_id}/${payload.project_id}`)
            .then( ({data}) => {
                commit('set_designtracker_fc_mutation', data.data);
            })
        }
    }
}
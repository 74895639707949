<template>
    <v-container fluid class="mt-5 flex">
        <small>* The templates below are intended as a guide only. <v-icon style="cursor: pointer;" @click="show_tutorial = true">mdi-help-circle-outline</v-icon></small>
        <!-- Only on Accounts where are not allowed to have multiple projects -->       
        <div v-if="is_add_project == true || Object.keys(get_selected_project).length == 0">
            <AddProject
                @cancel_add_project="cancel_add_project()"></AddProject>
        </div>
        <div v-else>
            <v-list flat two-line class="mt-3">
                <v-list-item>
                    <v-list-item-title>            
                        <v-autocomplete
                            v-if="granting_user_access() == true"
                            v-model="selected_project"
                            :items="get_projects"
                            item-value="id"
                            item-text="name" 
                            :label="selected_project.name"           
                            dense
                            outlined
                            class="pt-3"              
                            return-object></v-autocomplete>
                        <h3 v-else>Project Name: {{ this.selected_project.name }}</h3>
                    </v-list-item-title>
                    <v-list-item-action 
                        v-if="granting_user_access() == true && getuser.info.parent_user_id == null"
                        class="mr-3 pb-6">
                            <v-btn 
                            color="#0b4ef3"
                            class="white--text"
                            rounded
                            @click="add_new_project()">Add Project</v-btn>
                    </v-list-item-action>
                </v-list-item>
            </v-list>

            <FreeTierProjectSections
                v-if="granting_user_access() == false"
                :items="selected_project.sections ? selected_project.sections : []"
                :checklist_item="selected_project.checklist"
                :project_id="selected_project.id"
                :template_name="template_name">
            </FreeTierProjectSections>

            <PaidTierProjectSections
                v-if="granting_user_access() === true"
                :items="selected_project.sections ? selected_project.sections : []"
                :checklist_item="selected_project.checklist"
                :project_id="selected_project.id"
                :template_name="template_name">
            </PaidTierProjectSections>
        </div>
        <v-dialog
            persistent
            v-model="show_tutorial"
            width="1000"
        >
            <v-card>
            <v-card-title>
                Tutorial
                <v-spacer></v-spacer>
                <v-icon
                    small
                    color="red"
                    @click="show_tutorial = false;"
                >
                    mdi-close
                </v-icon>
            </v-card-title>
            <v-divider></v-divider>
                <vue-pdf-embed source="project_planner.pdf" />
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FreeTierProjectSections from './components/FreeTierProjectSections.vue';
import PaidTierProjectSections from './components/PaidTierProjectSections.vue';
import AddProject from './components/AddProject.vue';
import { UserAccess } from '../helpertraining/modules/UserAccess';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
    data: () => ({
        selected_project: [],
        is_add_project: false,
        access_tobe_granted: ['Premium Tier', 'Standard Tier'],
        template_name: null,
        show_tutorial: false,
    }),
    components: {      
        FreeTierProjectSections,
        PaidTierProjectSections,
        AddProject,
        VuePdfEmbed
    },
    computed: {
        ...mapGetters({
            get_projects:                   'project_planner_v2/get_projects_getter',
            get_selected_project:           'project_planner_v2/get_selected_project_getter',
            getuser:                        'auth/getuser',
            get_project_template:           'project_planner_v2/get_project_template_getter'
        })
    },
    methods: {
        ...mapActions({
            fetch_projects_api:         'project_planner_v2/fetch_projects_api_actions',
            set_selected_project:       'project_planner_v2/set_selected_project_actions',
            fetch_project_templates:    'project_planner_v2/fetch_project_templates_actions',
            update_project_id:          'auth/update_project_id_actions'
        }),
        add_new_project() {
            // If the user is free teir, it can only add one project
            this.is_add_project = true;
        },
        cancel_add_project() {
            this.is_add_project = false;                  
        },
        granting_user_access() {
            const userAccess = new UserAccess(this.getuser.info.access_id, this.access_tobe_granted);       
            return userAccess.get_access_grant() && 
                   userAccess.allow_multiple_projects(this.getuser.info, this.get_projects.length);
        }
    },
    async mounted() {
        // If free tier or paid tier, only one project, premium and standard tier, multiple project
        await this.fetch_projects_api(this.granting_user_access());      
        if ( Object.keys(this.get_selected_project).length == 0 ) {    
            if ( Object.keys(this.get_projects).length > 0 ) {      
                this.set_selected_project(this.get_projects[0]);  
            }
        }

        if ( Object.keys(this.get_selected_project).length > 0 ) {
            this.selected_project = this.get_selected_project;        
            this.update_project_id(this.selected_project);
        }

        // Only Execute if adding a new project
        if ( this.is_add_project == true ) {
            this.fetch_project_templates();
        }
    },
    watch: {
        selected_project() {            
            if ( Object.keys(this.get_selected_project).length > 0 ) {
                /** Check if the first is closeout, then do reverse - temporary fix */                
                if (this.selected_project.sections[0].name.toLowerCase() == 'closeout') {
                    this.selected_project.sections.reverse();                    
                }
                this.set_selected_project(this.selected_project);
                const template = this.get_project_template.find(template => template.id == this.selected_project.template_id);                
                
                if ( typeof template !== 'undefined' ) {
                    this.template_name = template.template_name;
                }
                
                // Update Project Id
                this.update_project_id(this.selected_project);
            }
        },
        get_selected_project() {
            if ( Object.keys(this.get_selected_project).length > 0 ) {               
                this.selected_project = this.get_selected_project;               
                const template = this.get_project_template.find(template => template.id == this.selected_project.template_id);
                if ( typeof template !== 'undefined' ) {
                    this.template_name = template.template_name;
                    this.update_project_id(this.selected_project);
                }
            }
        },
        get_project_template() {
            const template = this.get_project_template.find(template => template.id == this.selected_project.template_id);
            if ( typeof template !== 'undefined' ) {
                this.template_name = template.template_name;
                this.update_project_id(this.selected_project);
            }
        }
    }
}
</script>
<template>
    <v-row justify="center">
        <v-dialog
            v-model="transaction_form_dialog"
            persistent
            max-width="900">
            <v-card>
                <v-card-title>
                    <v-icon class="mr-2">mdi-clipboard-text-clock</v-icon>
                    Transaction Summary
                    <v-spacer></v-spacer>
                    <v-btn class="px-16 white--text"
                        color="rgb(11, 78, 243)"
                        @click="evt_addtransaction_formdialog()">
                        <v-icon class="mr-2">mdi-plus</v-icon>
                        Add Transaction
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>                  
                    <v-text-field
                        v-model="search"                     
                        append-icon="mdi-magnify"
                        placeholder="search transaction..."
                        label="search transaction...">
                    </v-text-field>          
                    <v-data-table
                        :search="search"
                        :headers="headers"
                        :items="get_budgetmanager_transaction_by_id(budgetmanager_item.id)"
                        class="elevation-1"
                        mobile-breakpoint="0">
                        <template v-slot:[`item.amount`]="{ item }">
                            <span>€ {{ item.amount | formatNumber }}</span>
                        </template>
                        <template v-slot:[`item.action`]="{ item }">
                            <v-btn text small color="primary" fab @click="evt_open_edittransitem_dialog(item)">
                                <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn text small color="error" fab
                                @click="evt_delete_transaction(item)">
                                <v-icon small>mdi-delete-outline</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="px-6" text color="error"
                        @click="close_dialog()">
                        <v-icon class="mr-2">mdi-close</v-icon>Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Add Transaction Component Dialog -->
        <AddTransaction
            :open_add_transaction_formdialog="open_addtransaction_formdialog"
            :bm_item="get_budgetmanager_item_by_id(budgetmanager_item.id, budgetmanager_item.parent_id)"
            @close_dialog="evt_close_addtransaction_formdialog()"></AddTransaction>
        
        <v-row justify="center">
            <v-dialog
                v-model="delete_trans_confirm_dialog"
                persistent
                max-width="450">
                <v-card>
                    <v-card-title>Are you sure you want to delete this item?</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="pt-3">
                        <small>All of the data that belongs to this item wll be deleted, are you sure about this?</small>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="px-6 white--text"
                            text color="error"
                            @click="evt_close_trans_configm_dialog()">
                            <v-icon class="mr-2">mdi-cancel</v-icon>No
                        </v-btn>
                        <v-btn class="px-6 white--text"
                            color="rgb(11, 78, 243)"
                            @click="evt_delete_trans_confirm_dialog()">
                            <v-icon class="mr-2">mdi-check-circle-outline</v-icon>Yes
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

        <EditBudgetTransactionItem
            :editTransItemDialog="openEditTransItemDialog"
            :transItem="itemToEdit"
            @evtCloseTransItemDialog="evt_closeTransItemDialog()"></EditBudgetTransactionItem>
    </v-row>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import AddTransaction from './AddTransaction.vue';
import EditBudgetTransactionItem from './EditBudgetTransactionItem.vue';

export default {
    data: () => ({
        transaction_form_dialog: false,
        headers: [
            { text: 'Amount', value: 'amount' },
            { text: 'Payment Method', value: 'payment_method' },
            { text: 'Date', value: 'created_at' },
            { text: 'Name', value: 'name' },
            { text: 'Action', value: 'action' }
        ],
        search: null,
        open_addtransaction_formdialog: false,
        delete_trans_confirm_dialog: false,
        item_to_delete: {},
        itemToEdit: {},
        openEditTransItemDialog: false        
    }),
    components: {
        AddTransaction,
        EditBudgetTransactionItem
    },
    props: {
        open_transaction_formdialog: Boolean,
        budgetmanager_item: Object
    },
    computed: {
        ...mapGetters({
            get_budgetmanager_transaction_by_id:         'budget_transaction/get_budgetmanager_transaction_by_id_getter',
            get_budgetmanager_item_by_id:                'budgetmanager/get_budgetmanager_item_by_id_getter',
            get_budgetmanager_transactions:              'budget_transaction/get_budgetmanager_transactions_getter',
            get_auth_users:                              'auth/getuser'            
        }),
    },
    methods: {
        ...mapActions({
            delete_budget_transaction:                  'budget_transaction/delete_budget_transaction_action'
        }),
        close_dialog() { 
            this.$emit('close_dialog');
        },
        evt_addtransaction_formdialog() {
            this.open_addtransaction_formdialog = true;
        },
        evt_close_addtransaction_formdialog() {                       
            this.open_addtransaction_formdialog = false;
        },
        evt_delete_transaction(item) {
            this.item_to_delete = item;
            this.delete_trans_confirm_dialog = true;
        },
        evt_delete_trans_confirm_dialog() {
            this.delete_budget_transaction(this.item_to_delete);
            this.delete_trans_confirm_dialog = false;
        },
        evt_close_trans_configm_dialog() {
            this.delete_trans_confirm_dialog = false           
        },
        evt_open_edittransitem_dialog(item) {
            this.itemToEdit = item
            this.openEditTransItemDialog = true
        },
        evt_closeTransItemDialog() {
            this.openEditTransItemDialog = false        
        }
    },
    mounted() {
        
    },
    watch: {
        open_transaction_formdialog() {
            this.transaction_form_dialog = this.open_transaction_formdialog;
        }
    }
}
</script>
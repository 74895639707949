<template>
    <div>
      <div v-if="setup.empty">
        <v-card>
          <v-card-title style="background: #062a82;">
            <v-icon class="mr-2" style="color: white;">{{setup.icon}}</v-icon>
            <small style="color: white;">{{setup.name}}</small>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="setup.tableheader"
              :items="items"
              class="elevation-1"
              :hide-default-footer="true"
              disable-pagination
            >
              <template v-slot:item="props">
                <tr>
                  <td style="cursor: pointer;">{{ props.item.log_name }}</td>
                  <td style="cursor: pointer;">{{ props.item.notes }}</td>
                  <td style="cursor: pointer;">
                    <label
                      v-for="(item, itemindex) in props.item.tags"
                      :key="itemindex"
                    >
                      <v-badge>
                        <span slot="badge"><label v-if="item.length < 10">{{item}}</label><small v-else>{{item}}</small></span>
                      </v-badge>
                      <br/>
                    </label>
                  </td>
                  <td style="cursor: pointer;">{{ props.item.created_at }}</td>
                  <td style="cursor: pointer;">{{ props.item.site_diary_date }}</td>
                  <td style="cursor: pointer;">
                    <img :src="`${img_url}frontend/images/${props.item.image}`" style="max-width:250px; max-height:250px;"/>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </div>
      <div v-if="setup.reminder">
        <v-data-table
          :headers="setup.headers"
          :items="items"
          :items-per-page="10"
          class="elevation-1"
          style="width: 500px;"
        >
          <template v-slot:item="props">
            <tr>
              <td>
                <v-checkbox
                  @change="markasdone(props)"
                ></v-checkbox>
              </td>
              <td>{{ props.item.todo }}</td>
            </tr>
          </template>
        </v-data-table>
      </div>
      <div v-if="setup.sb3d">
        <v-data-table
          :headers="setup.headers"
          class="elevation-1"
          :items="items"
        >
          <template v-slot:item="{ item, expand, isExpanded }" >
            <tr
              style="cursor:pointer;" 
              @click="expand(!isExpanded)"
            >
              <td>
                {{ item.string }}
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row>
                  <v-sheet
                    color="white"
                    height="250"
                    width="250"
                    v-for="(iitem, iindex) in item.getchildata"
                    :key="iindex"
                    class="mt-10 mb-10 ml-5 mr-5"
                  >
                    {{iitem.extension_file}} - <small>{{iitem.created_at}}</small>
                    <div
                      v-if="iitem.extension_file === 'pdf'"
                    >
                      <img :src="`https://media.istockphoto.com/vectors/icon-major-file-format-vector-icon-illustration-vector-id1298834280?k=20&m=1298834280&s=612x612&w=0&h=SxEbyHKi18H1XpPpKKzoppWgMM3x0tc3veN5e6wl7Y8=`" style="max-width:125px; max-height:125px;"/>
                    </div>
                    <div
                      v-if="iitem.extension_file === 'jpg' || iitem.extension_file === 'png' || iitem.extension_file === 'jpeg'"
                    >
                      <img :src="`${img_url}${iitem.path}`" style="max-width:250px; max-height:250px;"/>
                    </div>
                    <div
                      v-if="iitem.extension_file === '.link'"
                    >
                      <a :href="iitem.link_to_assets" target="_blank">{{iitem.name}}</a>
                    </div>
                  </v-sheet>
                </v-row>
              </td>
          </template>
        </v-data-table>
      </div>
      <div v-if="setup.sitediary">
        <v-card>
          <v-card-title style="background: #062a82;">
            <v-icon class="mr-2" style="color: white;">{{setup.icon}}</v-icon>
            <small style="color: white;">{{setup.name}} 
              <v-icon color="white" style="cursor: pointer;" @click="show_tutorial = true">mdi-help-circle-outline</v-icon>
              <!-- <GuidelineComponent :color="color" /> -->
            </small>
            <v-spacer></v-spacer>
            <div v-if="setup.button.length != 0">
              <v-btn
                v-for="(btn, btnIndex) in setup.button"
                :key="btnIndex"
                :dark="btn.dark"
                :text="btn.text"
                :class="btn.class"
                :color="btn.color"
                rounded
                @click="btn.action ? addsitediary() : ''"
              >
                <v-icon
                  :class="btn.classicon"
                >
                  {{btn.icon}}
                </v-icon>
                {{btn.label}}
              </v-btn>
            </div>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="setup.tableheader"
              :items="items"
              class="elevation-1"
              :hide-default-footer="true"
              disable-pagination
            >
              <template v-slot:item="props">
                <tr>
                  <td @click="editchild(props.item)" style="cursor: pointer;">{{ props.item.log_name }}</td>
                  <td @click="editchild(props.item)" style="cursor: pointer;">{{ props.item.notes }}</td>
                  <td @click="editchild(props.item)" style="cursor: pointer;">
                    <label
                      v-for="(item, itemindex) in props.item.tags"
                      :key="itemindex"
                    >
                      <!-- todo here -->
                      <v-badge>
                        <span slot="badge">{{item}}</span>
                      </v-badge>
                      <br/>
                      <!-- <ul>
                        <li
                          v-for="(iitem, iitemindex) in props.item.tags"
                          :key="iitemindex"
                        >
                          {{iitem}}
                        </li>
                      </ul> -->
                    </label>
                  </td>
                  <td @click="editchild(props.item)" style="cursor: pointer;">{{ props.item.created_at }}</td>
                  <td @click="editchild(props.item)" style="cursor: pointer;">{{ props.item.site_diary_date }}</td>
                  <td @click="viewimage(props.item)" style="cursor: pointer;">
                    <img :src="`${img_url}frontend/images/${props.item.image}`" style="max-width:125px; max-height:125px;"/>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
        <v-dialog
          persistent
          v-model="show_tutorial"
          width="1000"
        >
          <v-card>
            <v-card-title>
              Tutorial
              <v-spacer></v-spacer>
              <v-icon
                small
                color="red"
                @click="show_tutorial = false;"
              >
                mdi-close
              </v-icon>
            </v-card-title>
            <v-divider></v-divider>
            <vue-pdf-embed source="site_diary.pdf" />
          </v-card>
      </v-dialog>
      </div>
      <div v-if="setup.filemanager">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search In File Manager..."
          single-line
          hide-details
          class="mx-1"
        ></v-text-field>
        <v-data-table
          :search="search"
          :headers="setup.headers"
          :items="items"
          class="elevation-1"
          mobile-breakpoint="0"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:item="props">
            <tr style="cursor:pointer;" @click="openfiles(props.item)">
              <td>{{ props.item.name }}</td>
              <td><v-icon class="mr-1">mdi-file-cabinet</v-icon>{{ props.item.contains }}</td>
              <td><v-icon class="mr-1">mdi-clipboard-file</v-icon>{{ props.item.total_size | bytes}}</td>
              <td>{{ props.item.updated_at }}</td>
            </tr>
          </template>
        </v-data-table>
      </div>
      <!-- Snag Table -->
      <div v-if="setup.issnagsample">
        <v-data-table
          :headers="setup.headers"
          :items="items"
          class="elevation-1"
          mobile-breakpoint="0"
        >
          <template v-slot:item="props">
            <tr style="cursor:pointer;">
              <td>
                <v-checkbox
                  @change="markasdone(props)"
                ></v-checkbox>
              </td>
              <td>
                <small v-if="props.item.deleted_at == null">{{ props.item.description }}</small>
                <small v-else style="text-decoration: line-through;">{{ props.item.description }}</small>
              </td>
              <td>
                <div
                  v-if="props.item.deleted_at == null"
                >
                  <label
                    v-for="(item, itemindex) in props.item.who"
                    :key="itemindex"
                  >
                    <v-badge>
                      <span slot="badge"><label v-if="item.length < 10">{{item}}</label><small v-else>{{item}}</small></span>
                    </v-badge>
                    <br/>
                  </label>
                </div>
                <div v-else>
                  <label
                    v-for="(item, itemindex) in props.item.who"
                    :key="itemindex"
                  >
                    <v-badge>
                      <span slot="badge"><label v-if="item.length < 10">{{item}}</label><small v-else>{{item}}</small></span>
                    </v-badge>
                    <br/>
                  </label>
                </div>
              </td>
              <td>
                <small>{{ props.item.created_at }}</small>
              </td>
              <td>
                <img :src="`${img_url}frontend/images/${props.item.image}`" style="max-width: 250px; max-height: 250px;"/>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
      <div v-if="setup.issnag">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search In Snags..."
          single-line
          hide-details
          class="mx-1"
        ></v-text-field>
        <v-data-table
          :search="search"
          :headers="setup.headers"
          :items="items"
          class="elevation-1"
          mobile-breakpoint="0"
        >
          <template v-slot:item="props">
            <tr style="cursor:pointer;">
              <td>
                <v-checkbox
                  @change="markasdone(props)"
                ></v-checkbox>
              </td>
              <td @click="editsnags(props.item)">
                <small v-if="props.item.deleted_at == null">{{ props.item.description }}</small>
                <small v-else style="text-decoration: line-through;">{{ props.item.description }}</small>
              </td>
              <td @click="editsnags(props.item)">
                <div
                  v-if="props.item.deleted_at == null"
                >
                  <small
                    v-for="(item, itemindex) in props.item.who"
                    :key="itemindex"
                  >
                    {{item}}<br/>
                  </small>
                </div>
                <div v-else>
                  <small
                    v-for="(item, itemindex) in props.item.who"
                    :key="itemindex"
                    style="text-decoration: line-through;"
                  >
                    {{item}}<br/>
                  </small>
                </div>
              </td>
              <td @click="editsnags(props.item)">
                <small>{{ props.item.created_at }}</small>
              </td>
              <td @click="editsnags(props.item)">
                <img :src="`${img_url}frontend/images/${props.item.image}`" style="max-width: 250px; max-height: 250px;"/>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
      <!-- End Snag Table -->

      <!-- Budget Manager -->
      <div v-if="setup.budgetmanager">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search In Budget Manager..."
          single-line
          hide-details
          class="mx-1"
        ></v-text-field>
        <v-data-table
          :search="search"
          :headers="setup.headers"
          :items="items"
          class="elevation-1"
          mobile-breakpoint="0"
          :hide-default-footer="true"
          disable-pagination
        >
          <template v-slot:item="props">
            <tr style="cursor: pointer;">
              <td @click="updatebudgetmanagerrecord(props)"><small>{{props.item.index}}</small></td>
              <td @click="updatebudgetmanagerrecord(props)"><small>{{props.item.item_description}}</small></td>
              <td @click="updatebudgetmanagerrecord(props)"><small v-if="props.item.budget == null">{{ get_auth_users.info.currency_symbol }} 0.00</small><small v-else>{{ get_auth_users.info.currency_symbol }} {{props.item.budget | formatNumber}}</small></td>
              <td @click="updatebudgetmanagerrecord(props)"><small v-if="props.item.actual_price == null">{{ get_auth_users.info.currency_symbol }} 0.00</small><small v-else>{{ get_auth_users.info.currency_symbol }} {{props.item.actual_price | formatNumber}}</small></td>
              <td><small v-if="props.item.variation == null">{{ get_auth_users.info.currency_symbol }} 0.00</small><small v-else>{{ get_auth_users.info.currency_symbol }} {{props.item.variation | formatNumber}}</small></td>
              <td><small v-if="props.item.percent == null">0.00 %</small><small v-else>{{props.item.percent | formatNumber}} %</small></td>
              <td @click="viewtransactionhistory(props)"><small v-if="props.item.paid_to_date == null">{{ get_auth_users.info.currency_symbol }} 0.00</small><small v-else>{{ get_auth_users.info.currency_symbol }} {{props.item.paid_to_date | formatNumber}}</small></td>
              <td @click="edit_outstanding_value(props)"><small v-if="props.item.outstanding == null">{{ get_auth_users.info.currency_symbol }} 0.00</small><small v-else>{{ get_auth_users.info.currency_symbol }} {{props.item.outstanding | formatNumber}}</small></td>
              <td><small><v-icon @click="deleteitembudgetmanager(props.item)">mdi-delete-outline</v-icon></small></td>
            </tr>
          </template>
          <template slot="body.append">
            <tr>
              <th></th>
              <th>Overall Project Outlay**</th>
              <th>{{ get_auth_users.info.currency_symbol }} {{ sumField('budget') | formatNumber}}</th>
              <th>{{ get_auth_users.info.currency_symbol }} {{ sumField('actual_price') | formatNumber}}</th>
              <th>{{ get_auth_users.info.currency_symbol }} {{ sumField('variation') | formatNumber}}</th>
              <th>{{ sumField('variation') / sumField('budget') | formatNumber }} %</th>
              <th>{{ get_auth_users.info.currency_symbol }} {{ sumField('paid_to_date') | formatNumber}}</th>
              <th>{{ get_auth_users.info.currency_symbol }} {{ sumField('outstanding') | formatNumber}}</th>
            </tr>
          </template>
        </v-data-table>
      </div>
      <!-- End Budget Manager -->

      <!-- Cost Estimation -->
      <div v-if="setup.costestimation">
        <v-data-table
          :headers="setup.headers"
          :items="items"
          class="elevation-1"
          mobile-breakpoint="0"
          :hide-default-footer="true"
          disable-pagination
        >
          <template v-slot:item="props">
            <tr style="cursor: pointer;">
              <td><small>{{props.item.index}}</small></td>
              <td><small>{{props.item.name}}</small></td>
              <td @click="editbudgetcostestimation(props.item)"><small>{{props.item.budget}} %</small></td>
              <td><small>{{ get_auth_users.info.currency_symbol }} {{props.item.result | formatNumber}}</small></td>
              <td><small>{{ get_auth_users.info.currency_symbol }} {{props.item.amount | formatNumber}}</small></td>
            </tr>
          </template>
          <template slot="body.append">
            <tr>
              <th>Total</th>
              <th></th>
              <th><label v-if="sumCostEstimate('budget') > 100" style="color: red;">{{ sumCostEstimate('budget') }}</label><label v-else>{{ sumCostEstimate('budget') }}</label></th>
              <th>{{ get_auth_users.info.currency_symbol }} {{ sumCostEstimate('result') | formatNumber}}</th>
              <th></th>
            </tr>
          </template>
        </v-data-table>
      </div>
      <!-- End Cost Estimation -->

      <!-- Bill Of Materials -->
      <div v-if="setup.isbillofmaterials">
        <v-row>
          <v-data-table
            :headers="setup.headersvariables"
            :items="itemsvariables"
            class="elevation-1"
            mobile-breakpoint="0"
            :hide-default-footer="true"
            disable-pagination
            style="width: 250px;"
          >
            <template v-slot:item="props">
              <tr style="cursor: pointer;">
                <td>{{props.item.cname}}</td>
                <td style="cursor:pointer;" @click="billofmaterialsupdatevalue(props.item)">
                  <label
                    v-if="props.item.value == null"
                  >
                    {{props.item.constant}}
                  </label>
                  <label v-else>{{props.item.value}}</label>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-spacer></v-spacer>
          <v-btn class="mt-5 mr-3" style="background-color:#0b4ef3;color:white;">
          <vue-excel-xlsx
            :data="items"
            :columns="setup.headersexport"
            :file-name="'bill of materials'"
            :file-type="'xlsx'"
            :sheet-name="'Sheet 1'"
            >
            DOWNLOAD
          </vue-excel-xlsx></v-btn>
        </v-row>
        <div class="mt-5">
          <v-data-table
            :headers="setup.headersmaterialmanager"
            :items="items"
            class="elevation-1"
            mobile-breakpoint="0"
            disable-pagination
            hide-default-footer
          >
            <template v-slot:item="props">
              <tr style="cursor: pointer;">
                <td>{{props.item.index}}</td>
                <td><strong>{{props.item.name}}</strong></td>
                <td>
                  <v-edit-dialog
                    style="cursor: pointer;"
                    :return-value.sync="props.item.quantity"
                    large
                    @save="UpdateItem('quantity', props.item.quantity, props.item.id, props.item)"
                  >
                    <div style="cursor: pointer;">{{ props.item.quantity }}</div>
                    <template v-slot:input>
                      <div class="mt-4 text-h6">
                        Update Quantity
                      </div>
                      <v-text-field
                        v-model="props.item.quantity"
                        label="Edit"
                        single-line
                        counter
                        autofocus
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </td>
                <td>{{props.item.unit}}</td>
                <td>
                  <small>Supplier Link: </small><a :href="`${props.item.supplier1name}`" target="_blank"><small><strong>{{ props.item.supplier1name }}</strong></small></a><br/>
                  <small @click="updatesupplier(props.item)">
                    Unit Cost: {{ get_auth_users.info.currency_symbol }} <strong>{{props.item.supplier1 | formatNumber}}</strong><br/>
                    Total Cost: {{ get_auth_users.info.currency_symbol }} <strong>{{props.item.supplier1_tc | formatNumber}}</strong>
                  </small>
                </td>
                <td>
                  <small>Supplier Link: </small><a :href="`${props.item.supplier2name}`" target="_blank"><small><strong>{{ props.item.supplier2name }}</strong></small></a><br/>
                  <small @click="updatesupplier(props.item)">
                    Unit Cost: {{ get_auth_users.info.currency_symbol }} <strong>{{props.item.supplier2 | formatNumber}}</strong><br/>
                    Total Cost: {{ get_auth_users.info.currency_symbol }} <strong>{{props.item.supplier2_tc | formatNumber}}</strong>
                  </small>
                </td>
                <td>
                  <small>Supplier Link: </small><a :href="`${props.item.supplier3name}`" target="_blank"><small><strong>{{ props.item.supplier3name }}</strong></small></a><br/>
                  <small @click="updatesupplier(props.item)">
                    Unit Cost: {{ get_auth_users.info.currency_symbol }} <strong>{{props.item.supplier3 | formatNumber}}</strong> <br/>
                    Total Cost: {{ get_auth_users.info.currency_symbol }} <strong>{{props.item.supplier3_tc | formatNumber}}</strong>
                  </small>
                </td>
                <td><small>{{ get_auth_users.info.currency_symbol }} {{props.item.variation | formatNumber}}</small></td>
              </tr>
            </template>
            <template v-slot:footer>
              <div class="pb-5"></div>
            </template>
          </v-data-table>
        </div>
      </div>
      <!-- End Bill Of Materials -->

      <!-- Supplier Request Table -->
      <div v-if="setup.supplierrequest">
        <v-data-table
          :headers="setup.headers"
          :items="items"
          class="elevation-1 mb-15"
          mobile-breakpoint="0"
          :expanded.sync="expanded"
          single-expand
          item-key="id"
        >
          <template v-slot:item="{ item, expand, isExpanded }" >
            <!--<h4 class="my-2" @click="expand(!isExpanded)">{{ item.name }} located in {{ item.name }}</h4>-->
            <tr
              style="cursor:pointer;" 
              @click="expand(!isExpanded)"
            >
              <td><strong>{{item.project_name}}</strong></td>
              <td><strong v-if="item.get_is_quoted.length == 0">Pending</strong><strong v-else>Quoted</strong></td>
              <td><strong>{{item.description}}</strong></td>
              <td><strong>{{item.created_at}}</strong></td>
              <td><strong v-if="item.service != null">{{item.service.service_type}}</strong><strong v-else>N/A</strong></td>
              <td><strong>{{item.build_stage}}</strong></td>
              <td><strong>{{item.address_line}} {{item.city}} {{item.state}} {{item.country}} {{item.postal_code}}</strong></td>
              <td style="height:200px;">
                <div v-if="item.attachments != null">
                  <div v-if="item.attachments[0].split('.').pop() == 'png' || item.attachments[0].split('.').pop() == 'jpg' || item.attachments[0].split('.').pop() == 'jpeg'">
                    <v-img contain :src="`${img_url}frontend/images/${item.attachments[0]}`" style="max-width:250px; max-height:200px;"/>
                  </div>
                  <div v-if="item.attachments[0].split('.').pop() == 'pdf'">
                    <v-img contain :src="`https://media.istockphoto.com/vectors/icon-major-file-format-vector-icon-illustration-vector-id1298834280?k=20&m=1298834280&s=612x612&w=0&h=SxEbyHKi18H1XpPpKKzoppWgMM3x0tc3veN5e6wl7Y8=`" style="max-width:250px; max-height:150px;"/>
                  </div>
                  <div v-if="item.attachments[0].split('.').pop() == 'docx'">
                    <v-img contain :src="`https://seeklogo.com/images/M/microsoft-word-logo-E648C182A5-seeklogo.com.png`" style="max-width:250px; max-height:150px;"/>
                  </div>
                  <div v-if="item.attachments[0].split('.').pop() == 'xlsx'">
                    <v-img contain :src="`https://seeklogo.com/images/E/excel-logo-974BFF9CB9-seeklogo.com.png`" style="max-width:250px; max-height:150px;"/>
                  </div>
                  <div v-if="item.attachments.length > 1">+ {{item.attachments.length - 1}} more</div>
                </div>
                <div v-else>
                  None
                </div>
              </td>
              <td>
                <v-row>
                  <v-col
                    cols="6"
                  >
                    <v-icon
                      class="mr-3"
                      @click="delete_supplier_request_item(item)"
                    >mdi-delete</v-icon>
                  </v-col>
                  <v-col
                    cols="6"
                  >
                    <v-icon
                      @click="edit_supplier_request(item)"
                    >mdi-pen</v-icon>
                  </v-col>
                </v-row>
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-row
                class="mt-5 ml-3 mr-3 mb-5"
              >
                <v-col
                  cols="6"
                >
                  <label>project type</label>
                  <v-text-field
                    readonly
                    filled
                    dense
                    :value="item.project_name"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="6"
                >
                  <label>description</label>
                  <v-text-field
                    readonly
                    filled
                    dense
                    :value="item.description"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="6"
                >
                  <label>address</label>
                  <v-text-field
                    readonly
                    filled
                    dense
                    :value="`${item.address_line} ${item.city} ${item.state} ${item.country}`"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="6"
                >
                  <label>build stage</label>
                  <v-text-field
                    readonly
                    filled
                    dense
                    :value="`${item.build_stage}`"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <strong>attachments</strong>
                  <br>
                  <div v-if="item.attachments != null">
                    <v-btn
                      v-for="(attachment, index) in item.attachments"
                      :key="index"
                      outlined
                      class="elevation-3"
                      @click="download_attachment(item.attachments[index])"
                      style="display: inline-block;margin-right:5px;margin-bottom:5px"
                    >
                      <v-icon
                        class="mr-3"
                      >
                        mdi-briefcase-download-outline                              
                      </v-icon>
                      see attached media
                    </v-btn>
                  </div>
                  <div v-else>
                    None
                  </div>
                </v-col>
                <v-col
                  cols="12"
                >
                  <strong>scope of work</strong>
                  <!-- <v-row>
                    <v-btn
                      v-for="(iitem, iitemindex) in item.descriptions"
                      :key="iitemindex"
                      outlined
                      rounded
                      class="mx-3"
                      small
                      style="display: block; margin-top: 20px;"
                    >
                      {{iitem}}
                    </v-btn>
                  </v-row> -->
                  <ul>
                    <li
                      v-for="(iitem, iitemindex) in item.descriptions"
                      :key="iitemindex"
                    >
                      {{iitem}}
                    </li>
                  </ul>
                </v-col>
              </v-row>
              <div
                v-for="(items, itemindex) in item.get_is_quoted"
                :key="itemindex"
              >
                <v-card
                  class="mb-5"
                  style="border: 1px solid black;"
                >
                  <v-card-title
                    style="fontSize: 18px;"
                  >
                    <small>
                      <v-badge
                        bordered
                        bottom
                        color="deep-purple accent-4"
                        dot
                        offset-x="10"
                        offset-y="10"
                      >
                        <v-avatar size="40">
                          <v-img
                            v-if="items.get_company.logo == null"
                            lazy-src="https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg"
                            max-height="500"
                            max-width="100%"
                            src="https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg"
                          ></v-img>
                          <v-img v-else
                            :lazy-src="`${img_url}frontend/images/${items.get_company.logo}`"
                            max-height="100%"
                            max-width="100%"
                            :src="`${img_url}frontend/images/${items.get_company.logo}`"
                          ></v-img>
                        </v-avatar>
                      </v-badge>
                      {{items.get_company.company_name}}
                    </small>
                    <v-spacer></v-spacer>
                    <div
                      v-if="item.is_assigned == 1"
                    >
                      <div v-if="items.is_approved == 1">
                        <small style="fontSize: 14px;"><v-icon>mdi-check</v-icon>accepted</small>
                      </div>
                      <div v-if="items.is_approved == 0 && items.is_complete == 0">
                        <small style="fontSize: 14px;"><v-icon>mdi-alert-box</v-icon>canceled</small>
                      </div>
                    </div>
                    <div v-else>
                      <v-btn
                        color="primary" 
                        rounded
                        @click="acceptquotation(items)"
                      >
                        <v-icon class="mr-5">mdi-check</v-icon>
                        Accept Quotation
                      </v-btn>
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <v-divider></v-divider>
                    <v-row>
                      <v-col 
                        cols="12" 
                        class="mt-3"
                      >
                        quotation information
                      </v-col>
                      <v-col
                        cols="6"
                      >
                        <strong>price {{ get_auth_users.info.currency_symbol }} {{items.quote_price | formatNumber}}</strong>
                      </v-col>
                      <v-col
                        cols="6"
                      >
                        <!-- <small 
                          v-if="items.attachment != null"
                          style="
                            fontColor: blue; 
                            text-decoration: underline; 
                            cursor: pointer;
                            float: right;
                            fontSize: 16px;
                          "
                          @click="show_attachment_supplier_request(items)"
                          >
                          see attachment
                        </small> -->
                      </v-col>
                      <v-col
                        cols="12"
                      >
                        <v-btn-toggle
                          v-model="text"
                          rounded
                          dense
                        >
                          <div v-if="item.get_chat != null">
                            <v-btn
                              v-if="Object.keys(item.get_chat).length === 0"
                              outlined
                              class="elevation-3"
                              @click="chat_supplier(items, item)"
                            >
                              <v-icon
                                class="mr-3"
                              >
                                mdi-chat-outline
                              </v-icon>
                              Chat
                            </v-btn>
                          </div>
                          <v-btn
                            v-else
                            outlined
                            class="elevation-3"
                          >
                            <v-icon
                              class="mr-3"
                            >
                              mdi-chat-outline
                            </v-icon>
                            See conversation in inbox page
                        </v-btn>

                          <v-btn
                            outlined
                            class="elevation-3"
                            @click="view_supplier(items)"
                          >
                            <v-icon
                              class="mr-3"
                            >
                              mdi-account-hard-hat-outline
                            </v-icon>
                            View Supplier Profile
                          </v-btn>

                          <v-btn
                            outlined
                            class="elevation-3"
                            @click="download_attachment(items.attachment)"
                          >
                            <v-icon
                              class="mr-3"
                            >
                              mdi-briefcase-download-outline                              
                            </v-icon>
                            Download Attachment
                          </v-btn>

                          <!-- <v-btn
                            outlined
                            class="elevation-3"
                          >
                            <v-icon
                              class="mr-3"
                            >
                              mdi-close-circle-outline
                            </v-icon>
                            Cancel Quote
                          </v-btn> -->
                        </v-btn-toggle>
                      </v-col>
                      <v-col
                        cols="12"
                      >
                        <strong>Description: </strong>
                        <v-textarea
                          :value="items.description"
                          readonly
                          filled
                          dense
                          rows="6"
                        >
                        </v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </div>
            </td>
          </template>
        </v-data-table>
      </div>
      <!-- End Supplier Request Table -->

      <!-- Dialog Component -->
      <v-row justify="center" v-if="Object.keys(chat_data).length != 0">
        <v-dialog
          v-model="is_dialog"
          persistent
          max-width="690"
        >
          <v-card>
            <v-card-title style="fontSize: 14px;">
              <strong><small>chat {{chat_data.get_company.company_name}}</small></strong>
            </v-card-title>
            <v-card-subtitle>
              project type: {{chat_data.project_name}}
            </v-card-subtitle>
            <v-card-text
              class="text-center"
            >
              <v-textarea
                v-model="chat_message"
                rows="10"
                outlined
                dense
                label="Your Message"
                prepend-inner-icon="mdi-message-outline"
              >
              </v-textarea>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="close_chat_dialog"
            >
              close
            </v-btn>
            <v-btn
              color="primary"
              dark
              class="pl-14 pr-14"
              @click="send_message"
            >
              send message
            </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- End Dialog Component -->
      <!-- supplier profile -->
      <v-row justify="center" v-if="Object.keys(supplier_data_for_view).length != 0">
        <v-dialog
          v-model="is_dialog_supplier_profile"
          persistent
          max-width="660"
        >
          <v-card
            class="mx-auto"
            outlined
          >
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">
                  <v-icon
                    class="mr-3"
                    small
                  >mdi-google-my-business
                  </v-icon>COMPANY PROFILE
                </div>
                <v-list-item-title class="text-h5 mb-1">
                  <v-icon
                    class="mr-3"
                    small
                  >mdi-card-account-details-outline
                  </v-icon>
                  {{supplier_data_for_view.get_company.company_name}}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <v-icon
                    class="mr-3"
                    small
                  >mdi-at
                  </v-icon>{{supplier_data_for_view.get_company.email}}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <v-icon
                    class="mr-3"
                    small
                  >mdi-phone-settings
                  </v-icon>{{supplier_data_for_view.get_company.phone_number}}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <v-icon
                    class="mr-3"
                    small
                  >mdi-map-marker
                  </v-icon>{{supplier_data_for_view.get_company.phy_address_line}} {{supplier_data_for_view.get_company.phy_city}} {{supplier_data_for_view.get_company.phy_country}} {{supplier_data_for_view.get_company.phy_postal_code}}
                </v-list-item-subtitle>
                <v-rating
                  v-model="supplier_data_for_view.get_company.average_rating"
                  icon-label="custom icon label text {0} of {1}"
                  half-increments
                  size="20"
                ></v-rating>
              </v-list-item-content>

              <v-list-item-avatar
                size="220"
                color="grey"
              >
                <v-img
                  v-if="supplier_data_for_view.get_company.logo == null"
                  lazy-src="https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg"
                  max-height="100%"
                  max-width="100%"
                  src="https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg"
                ></v-img>
                <v-img v-else
                  :lazy-src="`${img_url}frontend/images/${supplier_data_for_view.get_company.logo}`"
                  max-height="100%"
                  max-width="100%"
                  :src="`${img_url}frontend/images/${supplier_data_for_view.get_company.logo}`"
                >
                </v-img>
              </v-list-item-avatar>
            </v-list-item>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                @click="close_chat_dialog"
              >
                close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- end supplier profile -->
    </div>
</template>

<script>
// import GuidelineComponent from './GuidelineComponent.vue'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import { mapGetters } from 'vuex'
export default {
  components: {
    // GuidelineComponent
    VuePdfEmbed
  },
  props: [
   'setup',
   'items',
   'itemsvariables'
  ],
  data: () => ({
    img_url: null,
    search: '',
    expanded: [],
    text: 'center',
    is_dialog: false,
    chat_data: {},
    chat_whole_data: {},
    chat_message: null,
    is_dialog_supplier_profile: false,
    supplier_data_for_view: {},
    color: 'white',
    show_tutorial: false,
  }),
  mounted () {
    
  },
  created () {
    this.img_url = process.env.VUE_APP_URL
  },
  computed: {
    ...mapGetters({
        get_auth_users:                         'auth/getuser'
    })
  },
  methods: {
    showSnackBar(message){
      this.$store.commit("auth/setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    UpdateItem(label, value, id, props){ // UPDATE ITEM
      let payload = {
        label: label,
        value: value,
        id: id,
        props: props,
      }
      this.$emit('savequantity', payload)
    },
    expandRow(item) {
      this.expanded = item === this.expanded[0] ? [] : [item]
    },
    sumField(key) {
      // sum data in give key (property)
      return this.items.reduce((a, b) => a + (b[key] || 0), 0)
    },
    sumCostEstimate(key){
      return this.items.reduce((a, b) => a + (b[key] || 0), 0)
    },
    markasdone(data){
      this.$emit('markasdone', data.item)
    },
    addsitediary(){
      this.$emit('addsitediary')
    },
    viewimage(data){
      this.$store.dispatch('sitediary/setimage', data.image)
      this.$emit('viewimage')
    },
    editchild(data){
      this.$store.dispatch('sitediary/setedititem', data)
      this.$emit('editchild')
    },
    openfiles(data){
      this.$store.dispatch('filemanager/setchildfiles', data)
      this.$emit('openfiles')
    },
    editsnags(data){
      this.$store.dispatch('snags/seteditsnag', data)
      this.$emit('editsnags')
    },
    updatebudgetmanagerrecord(data){
      this.$store.dispatch('budgetmanager/setbudgetmanageredit', data.item)
      this.$emit('updatebudgetmanagerrecord')
    },
    deleteitembudgetmanager(data){
      this.$store.dispatch('budgetmanager/setbudgetmanageritemdelete', data)
      this.$emit('deleteitembudgetmanager')
    },
    export_bom(){
      this.$emit('export_bom')
    },
    viewtransactionhistory(data){
      this.$store.dispatch('budgetmanager/setbudgetmanageraddtransactionhistory', data.item)
      this.$store.dispatch('budgetmanager/setbudgetmanageredit', data.item)
      this.$emit('viewtransactionhistory')
    },
    editbudgetcostestimation(data){
      this.$store.dispatch('costestimation/settoeditbudget', data)
      this.$emit('editbudgetcostestimation')
    },
    billofmaterialsupdatevalue(data){
      this.$store.dispatch('billofmaterials/settoupdatevalue', data)
      this.$emit('billofmaterialsupdatevalue')
    },
    updatesupplier(data){
      this.$store.dispatch('billofmaterials/setselectedrowforsupplierupdate', data)
      this.$emit('updatesupplier')
    },
    acceptquotation(data){
      this.$emit('acceptquotation', data)
    },
    delete_supplier_request_item(data){
      this.$emit('delete_supplier_request_item', data)
    },
    show_attachment_supplier_request(data){
      this.$emit('show_attachment_supplier_request', data)
    },
    chat_supplier(data, dataparent){
      data.ischat = true
      data.project_name = dataparent.project_name
      this.chat_data = data
      this.chat_whole_data = dataparent
      this.is_dialog = true
    },
    close_chat_dialog(){
      this.is_dialog = false
      this.is_dialog_supplier_profile = false
      this.chat_data = {}
      this.chat_whole_data = {}
      this.chat_message = null
    },
    async send_message(){
      await this.$axios.post('supplierrequest/send_message', {
        parent_data:    this.chat_whole_data,
        message:        this.chat_message,
        supplier_data:  this.chat_data
      })
      .then(({data}) => {
        if(data.response){
          this.showSnackBar(data.message)
          this.close_chat_dialog()
          return
        }
        this.showSnackBar(data.message)
        return
      })
    },
    view_supplier(data){
      this.supplier_data_for_view = data
      console.log(this.supplier_data_for_view)
      this.is_dialog_supplier_profile = true
    },
    download_attachment(data){
      window.open(this.img_url+ 'frontend/images/' + data)
    },
    edit_supplier_request(data){
      this.$emit('edit_supplier_request', data)
    },
    edit_outstanding_value(data) {
      this.$emit('editoutstandingvalue', data);
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>
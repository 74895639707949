<template>
    <v-container fluid class="mt-5 flex">
        <v-card>
            <v-card-title style="background: #062a82;">
                <v-icon class="mr-2" style="color: white;">mdi-credit-card-outline</v-icon>
                <small style="color: white;">Budget Manager</small>
            </v-card-title>
            <v-card-text>
                <v-tabs
                    v-model="bm_tabs"
                    fixed-tabs
                    slide-color="white">
                    <v-tab
                        v-for="item in items"
                        :key="item"
                        :href="'#tab-' + item">
                        {{ item }}
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="currentItem">
                    <v-tab-item
                        v-for="item in items.concat(more)"
                        :key="item"
                        :value="'tab-' + item">
                        <v-card flat>
                        <v-card-text>
                            <h2>{{ item }}</h2>
                            {{ text }}
                        </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
export default {
    data: () => ({
        currentItem: 'tab-Web',
        items: [
            'Budget Manager', 'Cost Estimation', 'Bill of Materials',
        ],       
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    }),
    components: {

    },
    computed: {

    }
}
</script>


export default {
    namespaced: true,
    state: {
        geo_location: {},
        gps_coordinate: {}
    },
    mutations: {
        setGeoLocationDataMutation(state, payload) {
            state.geo_location = payload
        },
        setGeoLocationCoordinateMutation(state, payload) {
            state.gps_coordinate = payload
        }
    },
    getters: {
        getGeoLocationDataGetter: state => state.geo_location
    },
    actions: {
        setGeoLocationDataAction({commit}, payload) {
            commit('setGeoLocationDataMutation', payload)
        },
        setGeoLocationCoordinateAction({commit}, payload) {
            commit('setGeoLocationCoordinateMutation', payload)
        }
    }
}
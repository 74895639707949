<template>
  <v-container fluid class="mt-10 flex">
    <v-card
      elavation="0"
    >
      <v-card-title style="background: #062a82"><v-icon class="mr-2" style="color: white;">{{home.icon}}</v-icon><small style="position: relative; top:2px; color: white;">{{home.name}} <v-icon color="white" style="cursor: pointer;" @click="show_tutorial = true">mdi-help-circle-outline</v-icon></small></v-card-title>
      <v-card-text>
        <GeoLocation></GeoLocation>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text>
        <ButtonComponent 
          :setup="setup"
          @buttonaction="buttonaddreminder"
          class="mb-8"
        />
        <AddRemindereDialog
          :open_add_reminder_dialog="add_reminder_dialog"
          @close_add_reminder_dialog="evt_close_add_reminder_dialog()">
        </AddRemindereDialog>
        <!--<Dialog
          v-model="dialogvalue.modal"
          :value="dialogvalue.modal"
          :dialogvalue="dialogvalue"
          @closedialog="closedialog"
          @savedata="savereminder"
        />-->
        
        <!-- edit profile dialog -->

        <Dialog
          v-model="showdialogprofiledit"
          :value="showdialogprofiledit"
          :dialogvalue="dialogprofileedit"
          :userinfo="getuserinfo"
          :getemailfrequency="getemailfrequency"
          @closedialog="closedialog"
          @addpartner="addpartner"
          @saveprofile="saveprofile"
        />

        <!-- end edit profile dialog -->

        <!-- add partner dialog -->
        <Dialog
          v-model="showdialogaddpartner"
          :value="showdialogaddpartner"
          :dialogvalue="dialogaddpartner"
          @closedialog="closeaddparentdialog"
          @savepartner="savepartner"
        />
        <!-- end add partner dialog -->
       <!-- <center>
          <DataTable
            class="mt-10" 
            :setup="setup_table_reminder"
            :items="getuserinfo.project_id === null ? defaultdata : getTodo"
            @markasdone="markasdone"
          />
        </center>
        <center class="mt-5 mb-5">
          <ButtonComponent
            :setup="setupsingle"
            @buttonaction="showcompleted"
          />
        </center>
        <center>
          <div v-if="completed_table">
            <DataTable 
              :setup="setup_table_reminder"
              :items="getuserinfo.project_id === null ? defaultdatacompleted : getDoneTodo"
              @markasdone="markasdone"
            />
          </div>
        </center>-->
        <RemindersTable></RemindersTable>
      </v-card-text>
    </v-card>
    <DashboardComponent></DashboardComponent>
    <DialogWarning 
      v-model="showdialogcreateproject"
      :value="showdialogcreateproject"
      :dialogsvalue="dialogcreateproject"
      @createproject="createproject"
    />
    <v-dialog
      persistent
      v-model="show_tutorial"
      width="1000">
      <v-card>
        <v-card-title>
          Tutorial
          <v-spacer></v-spacer>
          <v-icon
            small
            color="red"
            @click="show_tutorial = false;"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <vue-pdf-embed source="add_reminders.pdf" />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ButtonComponent from '../../components/ButtonComponent.vue'
import Dialog from '../../components/DialogComponent.vue'
//import DataTable from '../../components/DataTable.vue'
import DialogWarning from '../../components/DialogWarning.vue'
import { mapActions, mapGetters } from 'vuex'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import DashboardComponent from './components/DashboardComponent.vue';
import RemindersTable from './components/RemindersTable.vue'
import AddRemindereDialog from './components/AddRemindereDialog.vue'
import GeoLocation from './components/GeoLocation.vue'

export default {
  components: {
    GeoLocation,
    ButtonComponent,
    Dialog,
    //DataTable,
    DialogWarning,
    VuePdfEmbed,
    DashboardComponent,
    RemindersTable,
    AddRemindereDialog
  },
  props: [
  ],
  data: () => ({
    show_tutorial: false,
    defaultdata: [
      {id: 1, todo: 'Example: Call David for delivery of windows on Friday'},
      {id: 2, todo: 'Example: Price Tilers'},
      {id: 3, todo: 'Example: Buy some paint for outside wall'},
      {id: 4, todo: 'Example: Call the painter'},
      {id: 5, todo: 'Example: Review the landscaping quote'},
      {id: 6, todo: 'Example: Order the ridge caps'}
    ],
    defaultdatacompleted: [
      {id: 1, todo: 'Sample Completed TODO / Reminder Entry'}
    ],
    project_info: null,
    home: {
      name: 'What would you like to do today?',
      icon: 'mdi-home'
    },
    dialogvalue: {
      modal: false,
      reminder: true,
      title: 'Add Reminder',
      icon: 'mdi-reminder',
      textfield: [
        {
          id:     1,
          label:  'Reminder',
          hint:   'reminder',
          icon:   'mdi-tooltip-text-outline',
          type:   'text'
        }
      ]
    },
    setupsingle: {
      single: true,
      label: 'Completed Reminder',
      url_name: {
        name: null
      }
    },
    setup: {
      group: true,
      btngroupdata: [
        {
          name:      'add reminders',
          icon:      'mdi-reminder',
          url_name: {
            name:   null
          }
        },
        {
          name:     'Site Diary',
          icon:     'mdi-notebook',
          url_name: {
            name: 'sitediaryv2'
          }
        },
        {
          name:     'Budget Manager',
          icon:     'mdi-scale-balance',
          url_name: {
            name: 'budgetmanager'
          }
        },
        {
          name:     'File Manager',
          icon:     'mdi-file',
          url_name: {
            name: 'filemanager'
          }
        },
        {
          name:     'Project Planner',
          icon:     'mdi-notebook-multiple',
          url_name: {
            name: 'projectplannerv2'
          }
        },
        {
          name:     'Snags',
          icon:     'mdi-calendar-check',
          url_name: {
            name: 'snags'
          }
        },
        {
          name:     'Profile Edit',
          icon:     'mdi-face-man-profile',
          url_name: {
            name: null
          },
          what:     'profile_edit'
        }
      ]
    },
    setup_table_reminder: {
      reminder: true,
      headers: [
        { text: '', width: '2%' },
        { text: 'Reminders', width: '98%' }
      ],
    },
    completed_table: false,
    showdialogcreateproject: false,
    dialogcreateproject: {
      createproject: true,
      title:      'You need to create a project',
      content:    'Kindly click the Proceed button below to create a project, and we can continue to add entries',
      buttons: [
        {
          id:       1,
          label:    'Proceed',
          action:   true,
          class:    'pl-10 pr-10',
          text:     false,
          dark:     true,
          color:    '#0b4ef3',
          block:    true
        },
      ]
    },
    showdialogprofiledit: false,
    dialogprofileedit: {
      profileedit:  true,
      title:        'edit profile',
      icon:         'mdi-face-man-profile',
      textfield:    [
        {
          id:         1,
          label:      'name',
          hint:       'name',
          icon:       'mdi-tooltip-text-outline',
          disabled:   false,
          type:       'text'
        },
        {
          id:         2,
          label:      'username',
          hint:       'username',
          icon:       'mdi-tooltip-text-outline',
          disabled:   false,
          type:       'text'
        },
        {
          id:         3,
          label:      'email',
          hint:       'email',
          icon:       'mdi-tooltip-text-outline',
          disabled:   true,
          type:       'text'
        },
        {
          id:         4,
          label:      'phone',
          hint:       'phone',
          icon:       'mdi-tooltip-text-outline',
          disabled:   false,
          type:       'number'
        }
      ],
      button: [
        {
          id:         1,
          label:      'add partner',
          action:     true,
          icon:       'mdi-plus',
          color:      '#0b4ef3',
          dark:       true,
          text:       false
        }
      ],
      dd: [
        {
          id:         1,
          label:      'email frequency',
          hint:       'email frequency',
          icon:       'mdi-tooltip-text-outline',
        }
      ]
    },
    showdialogaddpartner: false,
    dialogaddpartner: {
      addpartner: true,
      title:      'add partner',
      icon:       'mdi-account-supervisor',
      textfield:    [
        {
          id:         1,
          label:      'name',
          hint:       'name',
          icon:       'mdi-tooltip-text-outline',
          disabled:   false,
          type:       'text'
        },
        {
          id:         2,
          label:      'username',
          hint:       'username',
          icon:       'mdi-tooltip-text-outline',
          disabled:   false,
          type:       'text'
        },
        {
          id:         3,
          label:      'email',
          hint:       'email',
          icon:       'mdi-tooltip-text-outline',
          disabled:   false,
          type:       'text'
        },
        {
          id:         4,
          label:      'password',
          hint:       'password',
          icon:       'mdi-lock',
          disabled:   false,
          type:       'text'
        },
        {
          id:         5,
          label:      'phone',
          hint:       'phone',
          icon:       'mdi-tooltip-text-outline',
          disabled:   false,
          type:       'number'
        }
      ],
    },
    add_reminder_dialog: false
  }),
  mounted () {
    
  },
  async created () {
    if((localStorage.getItem('id') !== null) || localStorage.getItem('name') !== null){
      await this.$axios.post('nav/logout').then(({data}) => {
        if(data.response){
          localStorage.clear()
          this.$router.push({name: 'login'})
          location.reload()
        }
      })
      return
    }
    const ld = JSON.parse(localStorage.getItem('vuex'))
    this.project_info = ld.auth.user.project_id.id    
    this.freminders()
    this.donereminders()
    this.gfrequency()
  },
  computed: {
    ...mapGetters({
      getTodo:            'home/getTodo',
      getDoneTodo:        'home/getDoneTodo',
      getuserinfo:        'auth/getuser',
      getemailfrequency:  'auth/getemailfrequency'
    })
  },
  methods: {
    ...mapActions({
      fetch_reminders:                    'dashboard/fetch_reminders_action'
    }),
    showSnackBar(message){
      this.$store.commit("auth/setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    async gfrequency(){
      this.$store.commit('auth/mutateemailfrequency')
    },
    async freminders(){
      if ( typeof this.project_info == 'undefined' ) {
        this.showdialogcreateproject = true
      } else {
        await this.$axios.get(`/home/getreminders/${this.project_info}`)
        .then(({data}) => {
          if(data.response){
            this.$store.dispatch('home/setTodo', data.data)
          }else{
            this.showdialogcreateproject = true
          }
        })
      }
    },
    async donereminders(){
      await this.$axios.get(`/home/getdonereminders/${this.project_info}`)
      .then(({data}) => {
        this.$store.dispatch('home/setDoneTodo', data.data)
      })
    },
    buttonaddreminder(data){
      if(data == 'profile_edit'){
        this.showdialogprofiledit = true
        return
      }
      this.add_reminder_dialog = true;
      //this.dialogvalue.modal = true      
      return
    },
    closedialog(){
      this.dialogvalue.modal = false
      this.showdialogprofiledit = false
    },
    async savereminder(data){
      const tp = {
        reminder: data.input[1],
        project_id: this.project_info
      }
      await this.$axios.post('/home/addReminders', tp)
      .then(({data}) => {
        if(data.response){
          this.closedialog()
          this.freminders()
          this.fetch_reminders({users_id: this.getuserinfo.id, project_id: this.getuserinfo.project_id.id})
        }else{
          this.closedialog()
          this.showdialogcreateproject = true
        }
      })
    },
    async markasdone(data){
      let tp = {
        status:   'Completed',
        id:       data.id
      }
      await this.$axios.patch('/home/updatereminder', tp)
      .then(({data}) => {
        if(data.response){
          this.freminders()
          this.donereminders()
          this.fetch_reminders({users_id: this.getuserinfo.id, project_id: this.getuserinfo.project_id.id})
        }
      })
    },
    showcompleted(){
      this.completed_table ? this.completed_table = false : this.completed_table = true
    },
    createproject(){
      this.$router.push({name: 'projectplannerv2'})
    },
    addpartner(){
      this.showdialogaddpartner = true
    },
    async saveprofile(data){
      let tp = {
        email_frequency_id:     data.dropdown.id,
        name:                   data.textfield[0].name == undefined || '' ? '' : data.textfield[0].name,
        phone:                  data.textfield[0].phone == undefined || '' ? '' : data.textfield[0].phone,
        username:               data.textfield[0].username == undefined || '' ? '' : data.textfield[0].username,
        email:                  data.textfield[0].email == undefined || '' ? '' : data.textfield[0].email
      }
      await this.$axios.post(`profile/editProfile/${this.getuserinfo.id}`, tp)
      .then(({data}) => {
        if(data.response){
          this.$store.commit('auth/updateuserinfo', data.info)
          this.showSnackBar(data.message)
          this.closedialog()
        }else{
          this.showSnackBar(data.message)
        }
      })
    },
    closeaddparentdialog(){
      this.showdialogaddpartner = false
    },
    async savepartner(data){
      let tp = {
        email:      data.textfield[0].email,
        name:       data.textfield[0].name,
        password:   data.textfield[0].password,
        phone:      data.textfield[0].phone,
        username:   data.textfield[0].username
      }
      await this.$axios.post('profile/registerPartner', tp)
      .then(({data}) => {
        if(data.response){
          this.showSnackBar(data.message)
          this.closeaddparentdialog()
        }else{
          this.showSnackBar(data.message)
        }
      })
    },
    evt_close_add_reminder_dialog() {
      this.add_reminder_dialog = false;
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>
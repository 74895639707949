<template>
    <v-row>
        <v-col
            cols="12"
            lg="3"
            sm="12"
            v-for="(item, itemindex) in data_item"
            :key="itemindex"
        >
            <v-card
                class="mx-auto my-12"
                max-width="374"
            >
                <v-icon
                    class="float-right pa-2"
                    style="cursor: pointer;"
                    @click="delete_item(item)"
                >
                    mdi-close
                </v-icon>
                <v-img
                    height="250"
                    :src="`${image}${item.image}`"
                ></v-img>

                <v-card-title>
                    <small
                        v-if="item.name.length > 50"
                    >
                        {{ item.name }}
                    </small>
                    <label
                        v-else
                    >
                        {{ item.name }}
                    </label>
                </v-card-title>
                <v-card-subtitle>
                    <a :href="item.product_url" target="_blank">{{ item.product_url }}</a>
                </v-card-subtitle>

                <v-card-text>
                <v-row
                    align="center"
                    class="mx-0"
                >
                    <v-rating
                        :value="item.rating"
                        color="amber"
                        dense
                        half-increments
                        readonly
                        size="14"
                    ></v-rating>

                    <div class="grey--text ms-4">
                        {{item.rating}}
                        <small
                            v-if="item.rating == 0"
                            class="ml-5"
                            style="font-weight: 700; font-size: small;"
                        >
                            Rate this product first
                        </small>
                    </div>
                </v-row>

                <div class="my-4 text-subtitle-1">
                    <strong><v-icon small>mdi-currency-eur</v-icon>{{ item.price }}</strong> • {{item.location}}
                </div>

                <div>
                    <vue-show-more-text
                        :text="item.description"
                        :lines="4"
                    />
                </div>
                </v-card-text>

                <v-divider class="mx-4"></v-divider>

                <v-card-title>Other information</v-card-title>

                <v-card-text>
                <v-chip-group
                    active-class="deep-purple accent-4 white--text"
                    column
                >
                    <v-chip
                        v-for="(iitem, iindex) in JSON.parse(item.chip_data)"
                        :key="iindex"
                    >{{ iitem }}</v-chip>

                </v-chip-group>
                </v-card-text>

                <v-card-actions>
                    <v-spacer/>
                    <!-- <v-btn
                        outlined
                        rounded
                        class="pl-10 pr-10"
                        @click="edit_item(item)"
                    >
                        edit
                    </v-btn> -->
                    <v-btn
                        outlined
                        rounded
                        class="pl-10 pr-10"
                    >
                        edit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
        <DialogWarming
            :value="dialog_warning_model"
            :item="get_to_delete_item"
            @close_dialog_delete="close_dialog_delete"
            @continue_delete_item="continue_delete_item"
        />
        <DialogEdit
            :value="dialog_edit_model"
            @close_dialog="close_dialog"
        />
    </v-row>
</template>

<script>
import vueShowMoreText from 'vue-show-more-text'
import { mapGetters } from 'vuex'
import DialogWarming from './DialogComponent.vue'
import DialogEdit from './DialogEdit.vue'
export default {
  components: {
    vueShowMoreText,
    DialogWarming,
    DialogEdit
  },
  props: [
    'data_item'
  ],
  data () {
    return {
        image: process.env.VUE_APP_URL,
        dialog_warning_model: false,
        dialog_edit_model: false
    }
  },
  mounted () {
  },
  created () {
  },
  computed: {
    ...mapGetters({
        get_selected_category:      'admin_shop/get_selected_category',
        get_to_delete_item:         'admin_shop/get_to_delete_item',
    })
  },
  methods: {
    async delete_item(item){
        this.$store.dispatch('admin_shop/set_to_delete_item', item)
        this.dialog_warning_model = true
    },
    close_dialog_delete(){
        this.$store.dispatch('admin_shop/set_to_delete_item', {})
        this.dialog_warning_model = false
    },
    async continue_delete_item(){
        await this.$store.dispatch('admin_shop/delete_item', this.get_to_delete_item)
        await this.$store.dispatch('admin_shop/get_items_via_cat_id', this.get_selected_category)
        await this.$store.dispatch('admin_shop/set_to_delete_item', {})
        this.close_dialog_delete()
    },
    edit_item(item){
        this.$store.dispatch('admin_shop/set_to_edit_item', item)
        this.dialog_edit_model = true
    },
    close_dialog(){
        this.dialog_edit_model = false
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>
<template>
    <div>
        <v-dialog
            v-model="pin_geo_location_model"
            width="700"
            persistent
            scrollable>
            <v-card>
                <v-card-title>
                    <v-icon class="mr-2" color="success">mdi-map</v-icon>
                    Map
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pt-6">
                    <iframe
                        width="650"
                        height="350"
                        frameborder="0" style="border:0"
                        referrerpolicy="no-referrer-when-downgrade"
                        :src="getMapSource()"
                        allowfullscreen>
                    </iframe>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" text class="px-6" @click="evtClosePinGeoLocationDialog()">
                        <v-icon class="mr-2">mdi-close</v-icon>
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {
    data: () => ({
        pin_geo_location_model: false,
        google_autocomplete: null,
        search: 'Shannon+Clare+V14XY54' 
    }),
    props: {
        pin_geo_location_dialog: Boolean,
        user_info: Object
    },
    computed: {
        
    },
    methods: {
        evtClosePinGeoLocationDialog() {
            this.$emit('evntClosePinGeoLocationDialog')
        },
        getMapSource() {
            if ( this.user_info != null ) {
                this.search = `${this.user_info.town.replace(" ", "")}+${this.user_info.county.replace(" ", "")}+${this.user_info.zipcode.replace(" ", "")}`
                return "https://www.google.com/maps/embed/v1/search?key=AIzaSyCG-7viCkdk2BpKOFhHO1mTt_W7iMTOUTI&q=" + this.search + "&zoom=18&maptype=satellite"
            }
        }    
    },
    mounted() {
    },   
    watch: {
        pin_geo_location_dialog() {
            this.pin_geo_location_model = this.pin_geo_location_dialog      
        }
    }
}
</script>
<template>
<v-row>
    <v-col
        cols="12"
        v-for="(item, index) in data"
        :key="index"
        sm="12"
        lg="3"
    >
        <v-card
            class="mx-auto my-12"
            max-width="374"
        >
            <v-img
                height="250"
                :src="`${image}${item.image}`"
            ></v-img>

            <v-card-title>
                <small
                    v-if="item.name.length > 50"
                >
                    {{ item.name }}
                </small>
                <label
                    v-else
                >
                    {{ item.name }}
                </label>
            </v-card-title>

            <v-card-text>
            <v-row
                align="center"
                class="mx-0"
            >
                <v-rating
                    :value="item.rating"
                    color="amber"
                    dense
                    half-increments
                    readonly
                    size="14"
                ></v-rating>

                <div class="grey--text ms-4">
                    {{item.rating}}
                    <small
                        v-if="item.rating == 0"
                        class="ml-5"
                        style="font-weight: 700; font-size: small;"
                    >
                        Rate this product first
                    </small>
                </div>
            </v-row>

            <div class="my-4 text-subtitle-1">
                <strong>€ {{ item.price }}</strong> • {{item.location}}
            </div>

            <div>
                <vue-show-more-text
                    :text="item.description"
                    :lines="4"
                />
            </div>
            </v-card-text>

            <v-divider class="mx-4"></v-divider>

            <v-card-title>Other information</v-card-title>

            <v-card-text>
            <v-chip-group
                active-class="deep-purple accent-4 white--text"
                column
            >
                <v-chip
                    v-for="(iitem, iindex) in JSON.parse(item.chip_data)"
                    :key="iindex"
                >{{ iitem }}</v-chip>

            </v-chip-group>
            </v-card-text>

            <v-card-actions>
            <v-spacer/>
            <v-btn
                color="deep-purple lighten-2"
                outlined
                rounded
                class="pl-8 pr-8"
                @click="add_to_cart(item)"
            >
                <v-icon>
                    mdi-cart
                </v-icon>
                Add to cart
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-col>
</v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import vueShowMoreText from 'vue-show-more-text'
export default {
  components: {
    vueShowMoreText
  },
  props: [
    'data'
  ],
  data () {
    return {
        image: process.env.VUE_APP_URL
    }
  },
  mounted () {
  },
  created () {
  },
  computed: {
    ...mapGetters({
        user:       'auth/getuser'
    })
  },
  methods: {
    async add_to_cart(data){
        data.users_id = this.user.id
        await this.$store.dispatch('shop/add_to_cart', data)
        await this.$store.dispatch('shop/fetch_cart_api', this.user.id)
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>
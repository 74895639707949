<template>
<v-card
    flat
>
    <v-card-text>
        <v-data-table
            :headers="headers"
            :items="get_cart"
            class="elevation-1"
            mobile-breakpoint="600"
            hide-default-header
        >
            <template v-slot:header="{ props }">
                <th 
                    v-for="(head, headindex) in props.headers"
                    :key="headindex"
                >
                    <label
                        style="font-weight: 500; font-size: large; font-family: 'Nunito', sans-serif;"
                    >
                        {{ head.text }}
                    </label>
                </th>
            </template>
            <template v-slot:item="props">
                <tr>
                    <td><strong>{{ props.item.name }}</strong></td>
                    <td>
                        <v-img
                            width="300"
                            :aspect-ratio="16/9"
                            :src="`${image}${props.item.image}`"
                            class="ma-5"
                        />
                    </td>
                    <td>
                        <v-text-field
                            label="QTY"
                            v-model="props.item.qty"
                            @change="update_qty(props.item)"
                            suffix="enter to save"
                            outlined
                            dense
                        >
                        </v-text-field>
                    </td>
                    <td>
                        <label
                            style="font-weight: 500; font-size: small;"
                        >
                            {{ props.item.created_at }}
                        </label>
                    </td>
                    <td>
                        <v-btn
                            icon
                            @click="remove_item(props.item)"
                        >
                            <v-icon>mdi-minus</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </template>
        </v-data-table>
    </v-card-text>
    <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn
        color="green darken-1"
        text
        @click="close_dialog()"
    >
        Close
    </v-btn>
    <v-btn
        color="green darken-1"
        outlined
        class="pl-10 pr-10"
        @click="check_out(get_cart)"
    >
        Submit for price, availability and Haulage Check
    </v-btn>
    </v-card-actions>
</v-card>
</template>

<script>
export default {
  components: {
  },
  props: [
    'headers',
    'get_cart',
    'get_user'
  ],
  data () {
    return {
        image: process.env.VUE_APP_URL,
    }
  },
  mounted () {
  },
  created () {
  },
  computed: {
  },
  methods: {
    close_dialog(){
        this.$emit('close_dialog')
    },
    showSnackBar(message){
      this.$store.commit("auth/setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    async check_out(data){
        this.showSnackBar("Please wait...")
        await this.$axios.post('shop/checkout', data)
        .then(({data}) => {
            if(data.response){
                this.showSnackBar(data.message)
                this.$store.commit('shop/clear_cart')
            }
        })
    },
    remove_item(item){
        item.users_id = this.get_user.id
        this.$store.dispatch('shop/remove_item', item)
    },
    async update_qty(item){
        await this.$store.dispatch('shop/update_qty', item)
        await this.$store.dispatch('shop/fetch_cart_api', this.get_user.id)
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>
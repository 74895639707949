import Vue from 'vue';

export default class Users {
    constructor() {

    }

    getUserAccessDataAPI(store) {
        Vue.axios.get('profile/get_user_access_data').then( (data) => {
            if ( data.status == 200 ) {
                const result = data.data
                if ( result.response  == true ) {
                    //console.log(result.data[0])
                    store.dispatch('auth/updateUserAccessIdAction', result.data[0])
                }
            }
        })
    }
}
<template>
    <v-container fluid class="mt-5 flex">
        <small>* The cost calculated below is an estimate and is intended only as a guide. Please check and verify all costs throughout your build with your engineer or QS. If you would like exact costs specific to your location and site conditions please contact www.selfbuild3d.com <v-icon style="cursor: pointer;" @click="show_tutorial = true">mdi-help-circle-outline</v-icon></small>
        <v-autocomplete
            v-model="chosenmaterialstemplate"
            outlined
            small-chips
            dense
            :items="getbillofmaterialstemplate"
            item-text="name"
            item-value="id"
            label="choose a template"
            return-object
            prepend-inner-icon="mdi-form-dropdown"
            @change="chosenmaterialstemplatefn"
            class="mt-5"
        >
        </v-autocomplete>
        <div>
          <DataTable
            :setup="datatablesetup"
            :itemsvariables="billofmaterialsvariables"
            :items="getbillofmaterialsdata"
            @billofmaterialsupdatevalue="billofmaterialsupdatevalue"
            @updatesupplier="updatesupplier"
            @savequantity="savequantity"
          />
          <DialogComponent
            v-model="dialogmodelvalue"
            :value="dialogmodelvalue"
            :dialogvalue="addvarialblebillofmaterials"
            @closedialog="closedialog"
            @savevalueinbillofmaterials="savevalueinbillofmaterials"
          />
          <DialogComponent
            v-model="dialogupdatesuppliers"
            :value="dialogupdatesuppliers"
            :dialogvalue="updatesuppliers"
            @closedialog="closedialog"
            @savesupplier="savesupplier"
          />
        </div>
        <v-dialog
          persistent
          v-model="show_tutorial"
          width="1000"
        >
          <v-card>
            <v-card-title>
              Tutorial
              <v-spacer></v-spacer>
              <v-icon
                small
                color="red"
                @click="show_tutorial = false;"
              >
                mdi-close
              </v-icon>
            </v-card-title>
            <v-divider></v-divider>
            <vue-pdf-embed source="bill_of_materials.pdf" />
          </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import DataTable from '../../../components/DataTable.vue'
import DialogComponent from '../../../components/DialogComponent.vue'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
export default {
  components: {
    DataTable,
    DialogComponent,
    VuePdfEmbed
  },
  props: [
  ],
  data: () => ({
    show_tutorial: false,
    datatablesetup: {
      isbillofmaterials: true,
      headersvariables: [
        { text: 'square meters' },
        { text: 'value' }
      ],
      headersmaterialmanager: [
        { text: 'id' },
        { text: 'name' },
        { text: 'qty' },
        { text: 'unit' },
        { text: 'supplier 1' },
        { text: 'supplier 2' },
        { text: 'supplier 3' },
        { text: 'variance' }
      ],
      headersexport: [
        { label: 'ID', field: 'id' },
        { label: 'Name', field: 'name' },
        { label: 'Quantity', field: 'quantity' },
        { label: 'Unit', field: 'unit' },
        { label: 'Supplier 1 Name', field: 'supplier1name' },
        { label: 'Supplier 1 Unit Cost', field: 'supplier1' },
        { label: 'Supplier 1 Total Cost', field: 'supplier1_tc' },
        { label: 'Supplier 2 Name', field: 'supplier2name' },
        { label: 'Supplier 2 Unit Cost', field: 'supplier2' },
        { label: 'Supplier 2 Total Cost', field: 'supplier2_tc' },
        { label: 'Supplier 3 Name', field: 'supplier3name' },
        { label: 'Supplier 3 Unit Cost', field: 'supplier3' },
        { label: 'Supplier 3 Total Cost', field: 'supplier3_tc' },
        { label: 'Variance', field: 'variation' },
      ]
    },
    dialogmodelvalue: false,
    addvarialblebillofmaterials: {
      addvaluesbillofmaterials: true,
      label:          'add / edit value',
      icon:           'mdi-plus',
      class:          'mr-2',
      textfield: [
        {
          id:         1,
          label:      'value',
          hint:       'value',
          icon:       'mdi-tooltip-text-outline',
          iconclass:  'mr-2',
          type:       'text'
        }
      ]
    },
    dialogupdatesuppliers: false,
    updatesuppliers: {
      updatesuppliersvalue:  true,
      label:                'update / add suppliers',
      icon:                 'mdi-plus',
      iconclass:            'mr-2',
      group: [
        {
          label:            'supplier 1',
          icon:             'mdi-account-plus',
          iconclass:        'mr-2 mb-3',
          textfield: [
            {
              id:           1,
              label:        'supplier\'s link',
              hint:         'supplier 1',
              icon:         'mdi-tooltip-text-outline',
              iconclass:    'mr-2'
            },
            {
              id:           2,
              label:        'price',
              hint:         'price',
              icon:         'mdi-tooltip-text-outline',
              type:         'number'
            }
          ]
        },
        {
          label:            'supplier 2',
          icon:             'mdi-account-plus',
          iconclass:        'mr-2 mb-3',
          textfield: [
            {
              id:           3,
              label:        'supplier\'s link',
              hint:         'supplier 2',
              icon:         'mdi-tooltip-text-outline',
              iconclass:    'mr-2'
            },
            {
              id:           4,
              label:        'price',
              hint:         'price',
              icon:         'mdi-tooltip-text-outline',
              type:         'number'
            }
          ]
        },
        {
          label:            'supplier 3',
          icon:             'mdi-account-plus',
          iconclass:        'mr-2 mb-3',
          textfield: [
            {
              id:           5,
              label:        'supplier\'s link',
              hint:         'supplier 3',
              icon:         'mdi-tooltip-text-outline',
              iconclass:    'mr-2'
            },
            {
              id:           6,
              label:        'price',
              hint:         'price',
              icon:         'mdi-tooltip-text-outline',
              type:         'number'
            }
          ]
        }
      ],
    }
  }),
  mounted () {
  },
  created () {
    this.chosenmaterialstemplatefn()
  },
  computed: {
    ...mapGetters({
      getbillofmaterialstemplate:               'billofmaterials/getbillofmaterialstemplate',
      getbillofmaterialsdata:                   'billofmaterials/getbillofmaterialsdata',
      billofmaterialsvariables:                 'billofmaterials/billofmaterialsvariables',
      gettoupdatevalue:                         'billofmaterials/gettoupdatevalue',
      getselectedrowforsupplierupdate:          'billofmaterials/getselectedrowforsupplierupdate'
    }),
    chosenmaterialstemplate: {
      get(){
        return this.$store.getters['billofmaterials/getselectedmaterialstemplate']
      },
      set(value){
        this.$store.dispatch('billofmaterials/setselectedmaterialstemplate', value)
      }
    }
  },
  methods: {
    async chosenmaterialstemplatefn(){      
      if ( Object.keys(this.chosenmaterialstemplate).length > 0 ) {
        await this.$axios.get(`billofmaterials/setmaterials/${this.chosenmaterialstemplate.id}`)
        .then(({data}) => {
          this.$store.dispatch('billofmaterials/setbillofmaterialsdata', data)
        })
      }
    },
    closedialog(){
      this.dialogmodelvalue = false
      this.dialogupdatesuppliers = false
    },
    billofmaterialsupdatevalue(){
      this.dialogmodelvalue = true
    },
    async savevalueinbillofmaterials(data){
      let tp = {
        value:          data.textfield[0].value,
        id:             this.gettoupdatevalue.id
      }
      await this.$axios.post('billofmaterials/addvariable', tp)
      .then(({data}) => {
        if(data.response){
          this.adjustqty()
        }
      })
    },
    async adjustqty(){
      await this.$axios.patch(`billofmaterials/aqty/${this.gettoupdatevalue.material_template_id}`)
      .then(({data}) => {
        if(data.response){
          this.chosenmaterialstemplatefn()
          this.closedialog()
        }
      })
    },
    updatesupplier(){
      this.dialogupdatesuppliers = true
    },
    async savesupplier(data){
      let tp = {
        id:                 this.getselectedrowforsupplierupdate.id,
        supplier1:          data.textfield[0].supplier1price == undefined ? this.getselectedrowforsupplierupdate.supplier1 : data.textfield[0].supplier1price,
        supplier1name:      data.textfield[0].supplier1 == undefined ? this.getselectedrowforsupplierupdate.supplier1name : data.textfield[0].supplier1,
        supplier2:          data.textfield[0].supplier2price == undefined ? this.getselectedrowforsupplierupdate.supplier2 : data.textfield[0].supplier2price,
        supplier2name:      data.textfield[0].supplier2 == undefined ? this.getselectedrowforsupplierupdate.supplier2name : data.textfield[0].supplier2,
        supplier3:          data.textfield[0].supplier3price == undefined ? this.getselectedrowforsupplierupdate.supplier3 : data.textfield[0].supplier3price,
        supplier3name:      data.textfield[0].supplier3 == undefined ? this.getselectedrowforsupplierupdate.supplier3name : data.textfield[0].supplier3
      }
      await this.$axios.post('billofmaterials/updatematerialmanager', tp)
      .then(({data}) => {
        if(data.response){
          this.chosenmaterialstemplatefn()
          this.closedialog()
        }
      })
    },
    async savequantity(payload){
      await this.$axios.post('billofmaterials/updatequantity', payload)
      .then(({data}) => {
        console.log(data)
        this.chosenmaterialstemplatefn()
      })
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>
export default {
    namespaced: true,
    state: {
      billofmaterialstemplate: [],
      selectedmaterialstemplate: {},
      billofmaterialsdata: [],
      billofmaterialsvariables: [],
      toupdatevalue: {},
      selectedrowforsupplierupdate: {}
    },
    mutations: {
      setbillofmaterialstemplate(state, payload){
        state.billofmaterialstemplate = [...payload]
      },
      setselectedmaterialstemplate(state, payload){
        state.selectedmaterialstemplate = payload
      },
      setbillofmaterialsdatamm(state, payload){
        state.billofmaterialsdata = [...payload]
      },
      setbillofmaterialsdatavariables(state, payload){
        state.billofmaterialsvariables = [...payload]
      },
      settoupdatevalue(state, payload){
        state.toupdatevalue = payload
      },
      setselectedrowforsupplierupdate(state, payload){
        state.selectedrowforsupplierupdate = payload
      }
    },
    getters: {
      getbillofmaterialstemplate(state){
        return state.billofmaterialstemplate
      },
      getselectedmaterialstemplate(state){
        return state.selectedmaterialstemplate
      },
      getbillofmaterialsdata(state){
        return state.billofmaterialsdata
      },
      billofmaterialsvariables(state){
        return state.billofmaterialsvariables
      },
      gettoupdatevalue(state){
        return state.toupdatevalue
      },
      getselectedrowforsupplierupdate(state){
        return state.selectedrowforsupplierupdate
      }
    },
    actions: {
      setbillofmaterialstemplate({commit}, payload){
        commit('setbillofmaterialstemplate', payload)
      },
      setselectedmaterialstemplate({commit}, payload){
        commit('setselectedmaterialstemplate', payload)
      },
      setbillofmaterialsdata({commit}, payload){
        const mm = payload.mm.map((item, index) => ({
            ...item,
            index: index + 1
        }))
        commit('setbillofmaterialsdatamm', mm)
        commit('setbillofmaterialsdatavariables', payload.variables)
      },
      settoupdatevalue({commit}, payload){
        commit('settoupdatevalue', payload)
      },
      setselectedrowforsupplierupdate({commit}, payload){
        commit('setselectedrowforsupplierupdate', payload)
      }
    }
}
<template>
    <v-container fluid class="mt-5 flex">
        <div v-if="getuser.project_id == null" align="center">
            <strong>You need to create a project</strong>
            <p>Kindly click the Proceed button below to create a project, and we can continue to add entries</p>
            <v-btn 
                color="#0b4ef3;" 
                dark 
                class="pl-16 pr-16" 
                rounded
                @click="$router.push({name: 'projectplanner'})"
            >
            Proceed
            </v-btn>
        </div>
        <div v-else>
          <small>* The templates below are intended as a guide only.</small>
            <v-card>
                <v-card-title style="background: #062a82;">
                <v-icon class="mr-2" style="color: white;">{{desingtrackersetup.icon}}</v-icon>
                <small style="color: white;">{{desingtrackersetup.title}} 
                  <v-icon color="white" style="cursor: pointer;" @click="show_tutorial = true">mdi-help-circle-outline</v-icon>
                  <!-- <GuidelineComponent :color="color" /> -->
                </small>
                </v-card-title>
                <v-card-text>
                    <v-tabs
                        v-model="tab"
                        centered
                        icons-and-text
                    >
                    <v-tabs-slider></v-tabs-slider>
                        <v-tab
                          v-for="(tab, tabindex) in tabdata"
                          :key="tabindex"
                          :href="tab.href"
                        >
                        {{tab.label}}
                        <v-icon>{{tab.icon}}</v-icon>
                        </v-tab>
                        <v-tabs-items v-model="tab">
                            <v-tab-item
                              value="tab-1">
                                <!--<ListItems
                                  :setup="fffsettings"
                                  :items="getfff"
                                  :header="fffsettings.headers"
                                  @markdonefff="markdonefff"
                                  @efff="efff"
                                />-->
                                <FFF
                                  :User="getuser"
                                  :Project="getproject"
                                  ></FFF>
                                <!--<DialogComponent
                                  v-model="editfffdialog"
                                  :value="editfffdialog"
                                  :dialogvalue="dialogfffedit"
                                  :childdata="geteditfff"
                                  @closedialog="closedialog"
                                  @saveeditedfff="saveeditedfff"
                                />-->
                            </v-tab-item>
                            <v-tab-item
                              value="tab-2">
                              <AppliancesComponent
                                :User="getuser"
                                :Project="getproject"
                                ></AppliancesComponent>
                              <!--<ListItems
                                :setup="appliances"
                                :items="getappliancess"
                                :header="appliances.headers"
                                @markdoneappliance="markdoneappliance"
                                @eappliances="eappliances"
                              />-->
                              <!--<DialogComponent
                                v-model="editappliancesdialog"
                                :value="editappliancesdialog"
                                :dialogvalue="dialogappliancesedit"
                                :childdata="geteditappliances"
                                @closedialog="closedialog"
                                @saveeditedappliances="saveeditedappliances"
                              />-->
                            </v-tab-item>
                            <v-tab-item
                              value="tab-3">
                              <FC
                                :User="getuser"
                                :Project="getproject"
                                ></FC>
                              <!--<ListItems
                                :setup="flooring"
                                :items="getflooringcalculationd"
                                :header="flooring.headers"
                                @markdoneflooring="markdoneflooring"
                                @eflooring="eflooring"
                              />
                              <DialogComponent
                                v-model="editflooringdialog"
                                :value="editflooringdialog"
                                :dialogvalue="dialogflooringedit"
                                :childdata="geteditflooring"
                                @closedialog="closedialog"
                                @saveeditedflooring="saveeditedflooring"
                              />-->
                            </v-tab-item>
                        </v-tabs-items>
                    </v-tabs>
                </v-card-text>
            </v-card>
        </div>
        <v-dialog
          persistent
          v-model="show_tutorial"
          width="1000"
        >
          <template
            v-slot:activator="{ on, attrs }"
          >
            <v-icon
              :color="color"
              v-on="on"
              v-bind="attrs"
            >
              help
            </v-icon>
          </template>

          <v-card>
            <v-card-title>
              Tutorial
              <v-spacer></v-spacer>
              <v-icon
                small
                color="red"
                @click="show_tutorial = false;"
              >
                mdi-close
              </v-icon>
            </v-card-title>
            <v-divider></v-divider>
            <vue-pdf-embed source="design_tracker.pdf" />
          </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
// import GuidelineComponent from '@/components/GuidelineComponent.vue'
import FFF from './components/FFF.vue'
import AppliancesComponent from './components/AppliancesComponent.vue'
import FC from './components/FC.vue'
import Users from '@/modules/auth/modules/Users.js'

export default {
  components: {   
    VuePdfEmbed,
    // GuidelineComponent
    FFF,
    AppliancesComponent,
    FC
  },
  props: [
  ],
  data: () => ({
    show_tutorial: false,
    desingtrackersetup: {
      title:    'Design Tracker',
      icon:     'mdi-pencil-ruler',
    },
    tab: null,
    tabdata: [
      {
        label:      'furniture, fixtures & fittings',
        //icon:       'mdi-file-multiple',
        href:       '#tab-1'
      },
      {
        label:      'appliances',
        //icon:       'mdi-greenhouse',
        href:       '#tab-2'
      },
      {
        label:      'flooring calculations',
        //icon:       'mdi-greenhouse',
        href:       '#tab-3'
      }
    ],
    fffsettings: {
      isfff: true,
      headers: [
        { text: '' },
        { text: 'Item' },
        { text: 'Quantity' },
        { text: 'Colour' },
        { text: 'Supplier' },
        { text: 'Product Code' },
        { text: 'Link To Product' },
        { text: 'Notes' },
        { text: 'Budget' },
        { text: 'Actual' }
      ]
    },
    editfffdialog: false,
    dialogfffedit: {
      editfff:      true,
      title:        'edit',
      icon:         'mdi-pencil',
      iconclass:    'mr-2',
      textfield: [
        {
          id:       8,
          label:    'item',
          hint:     'item',
          icon:     'mdi-tooltip-text-outline',
          type:     'text'
        },
        {
          id:       1,
          label:    'quantity',
          hint:     'quantity',
          icon:     'mdi-tooltip-text-outline',
          type:     'number'
        },
        {
          id:       2,
          label:    'colour',
          hint:     'colour',
          icon:     'mdi-tooltip-text-outline',
          type:     'text'
        },
        {
          id:       3,
          label:    'supplier',
          hint:     'supplier',
          icon:     'mdi-tooltip-text-outline',
          type:     'text'
        },
        {
          id:       4,
          label:    'product code',
          hint:     'product code',
          icon:     'mdi-tooltip-text-outline',
          type:     'text'
        },
        {
          id:       5,
          label:    'link to product',
          hint:     'link to product',
          icon:     'mdi-tooltip-text-outline',
          type:     'text'
        },
        {
          id:       6,
          label:    'budget',
          hint:     'budget',
          icon:     'mdi-tooltip-text-outline',
          type:     'number'
        },
        {
          id:       7,
          label:    'actual',
          hint:     'actual',
          icon:     'mdi-tooltip-text-outline',
          type:     'number'
        }
      ],
      textarea: [
        {
          id:       1,
          label:    'notes',
          hint:     'notes',
          icon:     'mdi-tooltip-text-outline'
        }
      ]
    },
    // appliances
    appliances: {
      isappliances: true,
      headers: [
        { text: '' },
        { text: 'Item' },
        { text: 'Quantity' },
        { text: 'Colour' },
        { text: 'Supplier' },
        { text: 'Product Code' },
        { text: 'Link To Product' },
        { text: 'Notes' },
        { text: 'Budget' },
        { text: 'Actual' }
      ]
    },
    editappliancesdialog: false,
    dialogappliancesedit: {
      editappliances: true,
      title:          'edit',
      icon:           'mdi-pencil',
      iconclass:      'mr-2',
      textfield: [
        {
          id:       8,
          label:    'item',
          hint:     'item',
          icon:     'mdi-tooltip-text-outline',
          type:     'text'
        },
        {
          id:       1,
          label:    'quantity',
          hint:     'quantity',
          icon:     'mdi-tooltip-text-outline',
          type:     'number'
        },
        {
          id:       2,
          label:    'colour',
          hint:     'colour',
          icon:     'mdi-tooltip-text-outline',
          type:     'text'
        },
        {
          id:       3,
          label:    'supplier',
          hint:     'supplier',
          icon:     'mdi-tooltip-text-outline',
          type:     'text'
        },
        {
          id:       4,
          label:    'product code',
          hint:     'product code',
          icon:     'mdi-tooltip-text-outline',
          type:     'text'
        },
        {
          id:       5,
          label:    'link to product',
          hint:     'link to product',
          icon:     'mdi-tooltip-text-outline',
          type:     'text'
        },
        {
          id:       6,
          label:    'budget',
          hint:     'budget',
          icon:     'mdi-tooltip-text-outline',
          type:     'number'
        },
        {
          id:       7,
          label:    'actual',
          hint:     'actual',
          icon:     'mdi-tooltip-text-outline',
          type:     'number'
        }
      ],
      textarea: [
        {
          id:       1,
          label:    'notes',
          hint:     'notes',
          icon:     'mdi-tooltip-text-outline'
        }
      ]
    },
    //end appliances
    // flooring calculations
    flooring: {
      isflooring: true,
      headers: [
        { text: '' },
        { text: 'Location' },
        { text: 'SQM' },
        { text: 'Floor Type' },
        { text: 'Price Per SQM' },
        { text: 'Total' },
        { text: 'Supplier' },
        { text: 'Notes' },
      ]
    },
    editflooringdialog: false,
    dialogflooringedit: {
      editflooring:   true,
      title:          'edit',
      icon:           'mdi-pencil',
      iconclass:      'mr-2',
      textfield: [
        {
          id:       1,
          label:    'location',
          hint:     'location',
          icon:     'mdi-tooltip-text-outline',
          type:     'text'
        },
        {
          id:       2,
          label:    'sqm',
          hint:     'sqm',
          icon:     'mdi-tooltip-text-outline',
          type:     'number'
        },
        {
          id:       3,
          label:    'floor type',
          hint:     'floor type',
          icon:     'mdi-tooltip-text-outline',
          type:     'text'
        },
        {
          id:       4,
          label:    'price per sqm',
          hint:     'price per sqm',
          icon:     'mdi-tooltip-text-outline',
          type:     'number'
        },
        {
          id:       5,
          label:    'total',
          hint:     'total',
          icon:     'mdi-tooltip-text-outline',
          type:     'number'
        },
        {
          id:       6,
          label:    'supplier',
          hint:     'supplier',
          icon:     'mdi-tooltip-text-outline',
          type:     'text'
        }
      ],
      textarea: [
        {
          id:       1,
          label:    'notes',
          hint:     'notes',
          icon:     'mdi-tooltip-text-outline'
        }
      ]
    },
    // end flooring calculations
    color: 'white',
    users: new Users()
  }),
  mounted () {
    this.getuser.project_id.id == null ? this.$router.push({name: 'home'}) : null
    this.users.getUserAccessDataAPI(this.$store)
  },
  created () {
    //this.getflooringcalculation()
    //this.getappliances()
    //this.gfff()
  },
  computed: {
    ...mapGetters({
      getuser:                  'auth/getuser',
      getproject:               'auth/getproject',
      getfff:                   'designtracker/getfff',
      geteditfff:               'designtracker/geteditfff',
      getappliancess:           'designtracker/getappliances',
      geteditappliances:        'designtracker/geteditappliances',
      getflooringcalculationd:  'designtracker/getflooringcalculation',
      geteditflooring:          'designtracker/geteditflooring'
    })
  },
  methods: {
    ...mapActions({
      restore_dt_fff_data:     'designtracker/restore_dt_fff_data_action'
    }),
    async getflooringcalculation(){
      await this.$axios.get(`designtracker/getFlooringCalculation/${this.getuser.id}/${this.getproject.id}`)
      .then(({data}) => {
        if(data.data.length == 0){
          this.genFlooringCalculation()
          return
        }
        this.$store.dispatch('designtracker/setflooringcalculation', data.data)
      })
    },
    async genFlooringCalculation(){
      await this.$axios.post(`designtracker/genFlooringCalculation/${this.getuser.id}/${this.getproject.id}`)
      .then(({data}) => {
        if(data.response){
          this.getflooringcalculation()
          return
        }
      })
    },
    async getappliances(){
      await this.$axios.get(`designtracker/getAppliances/${this.getuser.id}/${this.getproject.id}`)
      .then(({data}) => {
        if(data.data.length === 0){
          this.genappliances()
          return
        }
        this.$store.dispatch('designtracker/setappliances', data.data)
      })
    },
    async genappliances(){
      await this.$axios.post(`designtracker/genAppliances/${this.getuser.id}/${this.getproject.id}`)
      .then(({data}) => {
        if(data.response){
          this.getappliances()
        }
      })
    },
    async gfff(){
      await this.$axios.get(`designtracker/gFFF/${this.getuser.id}/${this.getproject.id}`)
      .then(({data}) => {
        if(data.data.length === 0){
          this.genfff()
          return
        }
        this.$store.dispatch('designtracker/setfff', data.data)
      })
    },
    async genfff(){
      await this.$axios.post(`designtracker/genFFF/${this.getuser.id}/${this.getproject.id}`)
      .then(({data}) => {
        if(data.response){
          this.gfff()
        }
      })
    },
    async markdonefff(data){
      //console.log(data)
      await this.$axios.patch(`designtracker/markdfff/${data.id}`)
      .then(({data}) => {
        if(data.response){
          this.gfff()
        }
      })
    },
    efff(){
      this.editfffdialog = true
    },
    closedialog(){
      this.editfffdialog = false
      this.editappliancesdialog = false
      this.editflooringdialog = false
    },
    async saveeditedfff(data){
      let tp = {
        item:             data.textfield[0].item == null ? '' : data.textfield[0].item,
        quantity:         data.textfield[0].quantity == null ? '' : data.textfield[0].quantity,
        color:            data.textfield[0].color == null ? '' : data.textfield[0].color,
        supplier_id:      data.textfield[0].supplier == null ? '' : data.textfield[0].supplier,
        product_code:     data.textfield[0].product_code == null ? '' : data.textfield[0].product_code,
        link_to_product:  data.textfield[0].link_to_product == null ? '' : data.textfield[0].link_to_product,
        notes:            data.textfield[0].notes == null ? '' : data.textfield[0].notes,
        budget:           data.textfield[0].budget == null ? '' : data.textfield[0].budget,
        actual:           data.textfield[0].actual == null ? '' : data.textfield[0].actual,
        id:               this.geteditfff.id
      }
      await this.$axios.patch('designtracker/updateFFF', tp)
      .then(({data}) => {
        if(data.response){
          this.gfff()
          this.closedialog()
        }
      })
    },
    async markdoneappliance(data){
      await this.$axios.patch(`designtracker/markad/${data.id}`)
      .then(({data}) => {
        if(data.response){
          this.getappliances()
        }
      })
    },
    eappliances(){
      this.editappliancesdialog = true
    },
    async saveeditedappliances(data){
      let tp = {
        item:             data.textfield[0].item == null ? '' : data.textfield[0].item,
        quantity:         data.textfield[0].quantity == null ? '' : data.textfield[0].quantity,
        color:            data.textfield[0].color == null ? '' : data.textfield[0].color,
        supplier_id:      data.textfield[0].supplier == null ? '' : data.textfield[0].supplier,
        product_code:     data.textfield[0].product_code == null ? '' : data.textfield[0].product_code,
        link_to_product:  data.textfield[0].link_to_product == null ? '' : data.textfield[0].link_to_product,
        notes:            data.textfield[0].notes == null ? '' : data.textfield[0].notes,
        budget:           data.textfield[0].budget == null ? '' : data.textfield[0].budget,
        actual:           data.textfield[0].actual == null ? '' : data.textfield[0].actual,
        id:               this.geteditappliances.id
      }
      await this.$axios.patch('designtracker/updateApp', tp)
      .then(({data}) => {
        if(data.response){
          this.closedialog()
          this.getappliances()
        }
      })
    },
    async markdoneflooring(data){
      await this.$axios.patch(`designtracker/markfloordone/${data.id}`)
      .then(({data}) => {
        if(data.response){
          this.getflooringcalculation()
        }
      })
    },
    eflooring(){
      this.editflooringdialog = true
    },
    async saveeditedflooring(data){
      let tp = {
        location:       data.textfield[0].location == null ? '' : data.textfield[0].location,
        sqm:            data.textfield[0].sqm == null ? '' : data.textfield[0].sqm,
        floor_type:     data.textfield[0].floor_type == null ? '' : data.textfield[0].floor_type,
        price_per_sqm:  data.textfield[0].price_per_sqm == null ? '' : data.textfield[0].price_per_sqm,
        total:          data.textfield[0].total == null ? '' : data.textfield[0].total,
        supplier:       data.textfield[0].supplier == null ? '' : data.textfield[0].supplier,
        notes:          data.textfield[0].notes == null ? '' : data.textfield[0].notes,
        id:             this.geteditflooring.id
      }
      await this.$axios.patch('designtracker/updateCalc', tp)
      .then(({data}) => {
        if(data.response){
          this.getflooringcalculation()
          this.closedialog()
        }
      })
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>
import Vue from 'vue'
import App from './App.vue'
import axios from './plugins/axios.js'
import vuetify from './plugins/vuetify'
import store from './states/index.js'
import router from './router.js'
// import date from 'datejs'
import numeral from 'numeral'
import Pusher from 'pusher-js'
import VueEcho from 'vue-echo-laravel'
import VueJsonToCsv from 'vue-json-to-csv'
import wysiwyg from "vue-wysiwyg"
import VueExcelXlsx from "vue-excel-xlsx"
import VueApexCharts from 'vue-apexcharts'

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";


// Vue.filter('formatDate', function(value) {
//   if (value) {
//     return date.parse(value).format('d M, Y')
//   }
// });

Vue.filter("formatNumber", function (value) {
  return numeral(value).format("0,0.00"); // displaying other groupings/separators is possible, look at the docs
});

Vue.filter("bytes", function(value){
  return numeral(value).format('0b')
})

Pusher.logToConsole = false;
const token = localStorage.getItem('token')
Vue.use(VueEcho, {
  broadcaster: "pusher",
  key: process.env.VUE_APP_PUSHER_KEY,
  cluster: process.env.VUE_APP_PUSHER_CLUSTER,
  forceTLS: true,
  authEndpoint: process.env.VUE_APP_API_URL + "broadcasting/auth",
  auth: {
    headers: {
      Accept: 'application/json',
      Authorization: 'Bearer ' + token, // Enabled - If you are using Bearer for authentication
    }
  }
});

Vue.use(axios)
// Vue.use(date)
Vue.use(numeral)
Vue.use(VueJsonToCsv)
Vue.use(wysiwyg, {})
Vue.use(VueExcelXlsx)
Vue.use(VueApexCharts)
Vue.use(Toast, {
    transition: "Vue-Toastification__slideBlurred",
    maxToasts: 20,
    newestOnTop: true  
})
Vue.config.productionTip = false

new Vue({
  router: router,
  vuetify,
  store: store,
  render: h => h(App)
}).$mount('#app')
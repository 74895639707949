<template>
  <div>
    <v-app-bar
      app
      color="#062a82"
      dark
    >
    <v-app-bar-nav-icon v-if="Object.keys(getuser).length !== 0" @click="drawer = true"></v-app-bar-nav-icon>
    <!-- <v-toolbar-title>SelfBuildHelper</v-toolbar-title> -->
    <v-img
      :src="require('../assets/logo.png')"
      contain
      max-height="150"
      max-width="150"
      class="mx-2"
    />
    <v-spacer></v-spacer>
    <small
      v-if="getuser.role === 3 || getuser.role === 2"
      class="mr-3" 
      style="text-decoration: underline; cursor:pointer;"
      @click="clear"
    >
      clear
    </small>
    <i 
      style="cursor:pointer;" 
      v-if="getuser.project_id != null && getuser.role == 3"
      @click="showeditpp"
    >
      <label style="font-size: 15px;">
        <v-icon small style="position: relative; top: -1 px;">
          mdi-pen
        </v-icon>
        {{getuser.project_id.name}}
      </label>
    </i>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      style="position:fixed; top:0; left:0; overflow-y:scroll;"
    >
      <div v-if="getuser.role == 1">
        <v-list
          dense
        >
          <v-list-item-group
            active-class="deep-purple--text text--accent-4"
          >
            <div
              v-for="(nav, navindex) in admin_navs"
              :key="navindex"
            >
              <v-subheader>{{nav.title}}</v-subheader>
              <v-list-item
                v-for="(sbhitem, sbhIIndex) in nav.items"
                :key="sbhIIndex"
                @click="sbhitem.url_name == '' ? btnfunc() : $router.push(sbhitem.url_name)"
              >
                <v-icon class="mr-5">{{sbhitem.icon}}</v-icon>
                <v-list-item-title>{{sbhitem.name}}</v-list-item-title>
              </v-list-item>
            </div>
          </v-list-item-group>
        </v-list>
      </div>
      <div v-if="getuser.role == 2">
        <v-list
          nav
          dense
        >
          <v-img
            v-if="getuser.company.logo == null"
            lazy-src="https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg"
            max-height="150"
            max-width="250"
            src="https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg"
          ></v-img>
          <v-img v-else
            :lazy-src="`${url}frontend/images/${getuser.company.logo}`"
            max-height="150"
            max-width="250"
            :src="`${url}frontend/images/${getuser.company.logo}`"
          >
          </v-img>
          <v-list-item-group
            active-class="deep-purple--text text--accent-4"
            v-for="(nav, navindex) in navs_supplier"
            :key="navindex"
          >
            <v-subheader>{{nav.title}}</v-subheader>
            <v-list-item
              v-for="(sbhitem, sbhIIndex) in nav.items"
              :key="sbhIIndex"
              @click="sbhitem.url_name == '' ? btnfunc() : $router.push(sbhitem.url_name)"
            >
              <v-icon class="mr-5">{{sbhitem.icon}}</v-icon>
              <v-list-item-title>{{sbhitem.name}}</v-list-item-title>
              <!--<v-badge content="2" v-if="sbhitem.has_badge" inline></v-badge>-->
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
      <div v-if="getuser.role == 3">
        <v-list
          nav
          dense
        >
          <v-list-item-group
            active-class="deep-purple--text text--accent-4"
          >
            <div
              v-for="(nav, navindex) in navs"
              :key="navindex"
            >
              <v-subheader>{{nav.title}}</v-subheader>
              <v-list-item
                v-for="(sbhitem, sbhIIndex) in nav.items"
                :key="sbhIIndex"
                @click="sbhitem.url_name == '' ? btnfunc() : $router.push(sbhitem.url_name)"
              >
                <v-icon class="mr-5">{{sbhitem.icon}}</v-icon>
                <v-list-item-title>
                  {{sbhitem.name}}
                </v-list-item-title>
              </v-list-item>
            </div>
          </v-list-item-group>
        </v-list>
      </div>
      <div v-if="getuser.role == 4">
        <v-list
          nav
          dense
        >
          <v-list-item-group
            active-class="deep-purple--text text--accent-4"
          >
            <div
              v-for="(nav, navindex) in sb3d_navs"
              :key="navindex"
            >
              <v-subheader>{{nav.title}}</v-subheader>
              <v-list-item
                v-for="(sbhitem, sbhIIndex) in nav.items"
                :key="sbhIIndex"
                @click="sbhitem.url_name == '' ? btnfunc() : $router.push(sbhitem.url_name)"
              >
                <v-icon class="mr-5">{{sbhitem.icon}}</v-icon>
                <v-list-item-title>{{sbhitem.name}}</v-list-item-title>
              </v-list-item>
            </div>
          </v-list-item-group>
        </v-list>
      </div>
      <Dialog
        v-model="showeditproject"
        :value="showeditproject"
        :dialogvalue="dialogvalue"
        @deletethis="deletethis"
        @closedialog="closedialog"
        @savedata="savedata"
      />
      <DialogWarning 
        v-model="dialogwarning"
        :value="dialogwarning"
        :dialogsvalue="dialogwarningcontent"
        @delproject="delproject"
        @donothing="donothing"
      />
      <DialogWarning
        v-model="showdialogcreateproject"
        :value="showdialogcreateproject"
        :dialogsvalue="dialogcreateproject"
        @createproject="createproject"
      />
    </v-navigation-drawer>
    <EditDeleteProject
      :show_ed_projectmodal="show_project_modal"
      @close_projectmodal="close_pp_modal($event)"
      @deletethis="deletethis"></EditDeleteProject>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Dialog from './DialogComponent.vue'
import DialogWarning from './DialogWarning.vue'
import EditDeleteProject from './project_planner/EditDeleteProject.vue'

export default {
  components: {
    Dialog,
    DialogWarning,
    EditDeleteProject
  },
  props: [
  ],
  data: () => ({
    drawer: false,
    url: null,
    sb3d_navs: [
      {
        title:        'SB3D',
        items: [
          {
            name:       'Logout',
            icon:       'mdi-logout-variant',
            url_name:   ''
          }
        ],
      },
    ],
    admin_navs: [
      {
        title:  'Home Navigation',
        items: [
          {
            name:   'admin home',
            icon:   'mdi-home-outline',
            url_name: {
              name:   'admin_dashboard'
            }
          },
        ],
      },
      {
        title:  'User Related',
        items: [
          {
            name:   'user list',
            icon:   'mdi-account-group-outline',
            url_name: {
              name:   'user_list'
            }
          },
          {
            name:   'plan upload(s)',
            icon:   'mdi-asterisk',
            url_name: {
              name:   'plan_upload_admin'
            }
          },
          {
            name:   'tips',
            icon:   'mdi-lightbulb-outline',
            url_name: {
              name:   'tips_admin'
            }
          },
          {
            name:   'helper training files',
            icon:   'mdi-school',
            url_name: {
              name: 'admin_helper_training_files'
            }
          },
          {
            name:   'video archive',
            icon:   'mdi-video-outline',
            url_name: {
              name: 'admin_video_archived'
            }
          },
          {
            name:       'Assets Uploader',
            icon:       'mdi-file-cabinet',
            url_name:   {
              name:   'admin_asset_uploader'
            }
          }
        ]
      },
      {
        title: "Supplier Related",
        items: [
          {
            name:   'supplier list',
            icon:   'mdi-account-group-outline',
            url_name: {
              name:  'supplier_dashboard'
            }
          },
          {
            name:   'trade / polish rate suppliers',
            icon:   'mdi-wrench-outline',
            url_name: {
              name:  'trade_rate_suppliers'
            }
          },
          {
            name:   'service type',
            icon:   'mdi-account-wrench-outline',
            url_name: {
              name:   'service_type'
            }
          },
          {
            name:   'supplier reports',
            icon:   'mdi-exclamation',
            url_name: {
              name:   'supplier_reports'
            }
          },
          {
            name:   'service requests',
            icon:   'mdi-account-hard-hat-outline',
            url_name: {
              name:   'service_request_report'
            }
          },
          {
            name:   'form request',
            icon:   'mdi-information-outline',
            url_name: {
              name:   '/form_request'
            }
          }
        ]
      },
      {
        title:        'template related',
        items: [
          {
            name:   'project planner',
            icon:   'mdi-notebook-outline',
            url_name: {
              name:   'template_project_planner'
            }
          }
        ]
      },
      {
        title:        'Market',
        items:        [
          {
            name:     'E-Commerce',
            icon:     'mdi-cart-variant',
            url_name:   {
              name:     'admin_shop'
            }
          }
        ]
      },
      {
        title:        'Logout',
        items: [
          {
            name:       'Logout',
            icon:       'mdi-logout-variant',
            url_name:   ''
          }
        ],
      },
    ],
    navs_supplier: [
      {
        title:  'Supplier Navigation',
        items: [
          // {
          //   id:     1,
          //   name:   'profile',
          //   icon:   'mdi-account-outline',
          //   url_name: {
          //     name:   'supplierprofile'
          //   }
          // },
          {
            name:   'supplier profile',
            icon:   'mdi-account-circle-outline',
            url_name: {
              name:   'supplier_company_profile'
            }
          },
          {
            name:   'inbox',
            icon:   'mdi-inbox-outline',
            has_badge: true,
            url_name: {
              name: 'supplier_inbox'
            }
          },
          // {
          //   name:   'notifications',
          //   icon:   'mdi-bell-outline',
          //   has_badge: true,
          //   url_name: {
          //     name: 'supplier_notification'
          //   }
          // },
          {
            name:   'to be quoted',
            icon:   'mdi-alert-outline',
            has_badge: true,
            url_name: {
              name: 'supplier_to_be_quoted'
            }
          },
          {
            name:   'quoted',
            icon:   'mdi-check-outline',
            has_badge: true,
            url_name: {
              name: 'supplier_quoted'
            }
          },
          {
            name:   'approved quotes',
            icon:   'mdi-check-circle-outline',
            has_badge: true,
            url_name: {
              name: 'supplier_approved_quotes'
            }
          },
          {
            name:   'quotes lost',
            icon:   'mdi-close-outline',
            has_badge: true,
            url_name: {
              name: 'supplier_quotes_lost'
            }
          },
          {
            name:       'Tips',
            icon:       'mdi-lightbulb',
            url_name:   {
              name:   'tips'
            }
          },
        ]
      },
      {
        title:        'client plan',
        items: [
          {
            name:       'client plan',
            icon:       'mdi-lightbulb-outline',
            url_name: {
              name:     'client_plan'
            }
          }
        ]
      },
      {
        title:        'form requests',
        items: [
          {
            name:     'form requests',
            icon:     'mdi-information-outline',
            url_name: {
              name:   '/form_request_s'
            }
          }
        ]      
      },
      {
        id:           14,
        title:  'Logout',
        items: [
          {
            name:       'Logout',
            icon:       'mdi-logout-variant',
            url_name:   ''
          }
        ]
      }
    ],
    navs: [
      {
        title: 'Self Build Related',
        items: [
          {
            name:       'Home',
            icon:       'mdi-home',
            url_name:   {
              name:   'home'
            }
          },
          {
            name:       'Site Diary',
            icon:       'mdi-notebook',
            url_name:   {
              name:   'sitediaryv2'
            }
          },
          {
            name:       'Budget Manager',
            icon:       'mdi-credit-card-outline',
            url_name:   {
              name:   'budgetmanager'
            }
          },
          {
            name:       'File  Manager',
            icon:       'mdi-file-multiple',
            url_name:   {
              name:     'filemanager'
            }
          },
          {
            name:       'Project Planner',
            icon:       'mdi-notebook-multiple',
            url_name:   {
              name:   'projectplannerv2'
            }
          },
          // {
          //   name:       'Plan Upload',
          //   icon:       'mdi-briefcase-upload',
          //   url_name:     {
          //     name:   'upload_component'
          //   }
          // },
          {
             name:       'Design Tracker',
             icon:       'mdi-pencil-ruler',
             url_name:   {
               name:   'designtracker'
             }
          },
          {
            name:       'Decisions',
            icon:       'mdi-arrow-decision',
            url_name:   {
              name:   'decision'
            }
          },
          {
            name:       'Snags',
            icon:       'mdi-format-list-checks',
            url_name:   {
              name:   'snags'
            }
          },
          {
            name:       'Tips',
            icon:       'mdi-lightbulb',
            url_name:   {
              name:   'tips'
            }
          },
          {
            name:       'Helper Training',
            icon:       'mdi-school',
            url_name:   {
              name:   'helper_training'
            }
          },
          {
            name:       'Video Archive',
            icon:       'mdi-video',
            url_name:   {
              name:   'video_archives'
            }
          }
          // {
          //   id:         10,
          //   name:       'PM Essentials',
          //   icon:       'mdi-lightbulb',
          //   url_name:    ''
          // },
          // {
          //   id:         11,
          //   name:       'About SelfBuildHelper',
          //   icon:       'mdi-instagram',
          //   url_name:    ''
          // }
        ]
      },
      {
        title:  'Requests',
        items: [
          {
            name:       'Supplier Directory',
            icon:       'mdi-store',
            url_name:   {
              name:   'supplierdirectory'
            }
          },
          {
            name:   'Trade / Polish Rate Suppliers',
            icon:   'mdi-account',
            url_name: {
              name:  'trade_rate_suppliers_directory'
            }
          },
          // {
          //   name:       'Supplier Request',
          //   icon:       'mdi-email-fast',
          //   url_name:   {
          //     name:     'supplierrequest'
          //   }
          // },
          {
            name:       'Inbox',
            icon:       'mdi-inbox',
            url_name:   {
              name:   'inbox'
            }
          },
          // {
          //   id:         4,
          //   name:       'Chase The Trade',
          //   icon:       'mdi-swap-horizontal',
          //   url_name:   {
          //     name:   'chase'
          //   }
          // }
        ]
      },
      // {
      //   title:        'Market',
      //   items:        [
      //     {
      //       name:     'Shop an Item',
      //       icon:     'mdi-cart-variant',
      //       url_name:   {
      //         name:     'shop'
      //       }
      //     }
      //   ]
      // },
      {
        title:  'Logout',
        items: [
          {
            name:       'Logout',
            icon:       'mdi-logout-variant',
            url_name:   ''
          }
        ]
      }
    ],
    showeditproject: false,
    dialogvalue: {
      modal: false,
      reminder: true,
      title: 'Edit / Delete Project',
      icon: 'mdi-pen',
      textfield: [
        {
          id:     1,
          label:  'Project Name',
          hint:   'project name',
          icon:   'mdi-tooltip-text-outline',
          type:   'text'
        }
      ],
      button: [
        {
          id:     1,
          label:  'Delete',
          icon:   'mdi-delete'
        }
      ]
    },
    dialogwarning: false,
    dialogwarningcontent: {
      warningdelete: true,
      title: 'Are you sure you want to delete this project?',
      content: 'All of the data that belongs to this project will be deleted, are you sure about this?',
      buttons: [
        {
          id:         2,
          label:      'Yes',
          action:     true,
          class:      '',
          text:       true,
          dark:       false
        },
        {
          id:         1,
          label:      'No',
          action:     false,
          color:      '#0b4ef3',
          text:       false,
          class:      'pl-16 pr-16',
          dark:       true
        },
      ]
    },
    showdialogcreateproject: false,
    dialogcreateproject: {
      createproject: true,
      title:      'You need to create a project',
      content:    'Kindly click the Proceed button below to create a project, and we can continue to add entries',
      buttons: [
        {
          id:       1,
          label:    'Proceed',
          action:   true,
          class:    'pl-10 pr-10',
          text:     false,
          dark:     true,
          color:    '#0b4ef3',
          block:    true
        },
      ]
    },
    show_project_modal: false
    //project_name: null
  }),
  mounted () {
  },
  created () {
    this.url = process.env.VUE_APP_URL
    if(this.getuser.project_id == null && this.getuser.role === 3){
      this.$router.push({name: 'projectplannerv2'})
    }
  },
  computed: {
    ...mapGetters({
      getuser:          'auth/getuser',
      projectinfo:      'auth/getuser',
      getproject:       'auth/getproject'
    })
  },
  methods: {
    ...mapActions({
      delete_selected_project:    'auth/delete_selected_project_actions',
      delete_project:             'project_planner_v2/delete_project_actions',
    }),
    showeditpp(){
      this.show_project_modal = true
    },
    close_pp_modal() {
      this.show_project_modal = false;
    },
    closedialog(){
      this.showeditproject = false
      this.dialogwarning = false
    },
    deletethis(){
      this.dialogwarning = true
    },
    async savedata(data){
      let tp = {
        template_name: data.input[1],
        old_template_name: this.getproject.template_name
      }
      await this.$axios.patch('projectplanner/etemplate', tp)
      .then(({data}) => {
        if(data.response){
          this.$store.commit('auth/updateProjectId', data.project_id)
          this.closedialog()
          //this.fetchproject()
          //this.$store.commmit('pp/setnewprojectonchangename')
          location.reload()
        }
      })
    },
    async getreminders(){
      await this.$axios.get(`home/getreminders/${this.getuser.id}`)
      .then(({data}) => {
        if(!data.response){
          this.showdialogcreateproject = true
        }
      })
    },
    async delproject(){
      //console.log(this.projectinfo)
      // This is to be revised into the new project planner
      /*await this.$axios.delete(`home/dtemplate/${this.projectinfo.project_id.template_name}`)
      .then(({data}) => {
        if(data.response){
          this.delete_selected_project(this.getproject);
          if (this.getproject == null) {
            this.getreminders()
          }
          //this.closedialog()
          this.$store.commit('budgetmanager/clearbudgetmanager')
          //this.$store.commit('auth/updateProjectId', null)
          this.$store.commit('budgetmanager/clearsets')
          this.$store.commit('costestimation/clearmutation')
          this.show_project_modal = false;
          this.dialogwarning = false;
        }
      })*/
      /** New Delete Project - Project Planner */
      const project_id = this.getuser.project_id.id;
      await this.delete_project({
        project_id: project_id
      });
      this.dialogwarning = false;
      this.show_project_modal = false;
      location.reload();
    },
    donothing(){
      this.dialogwarning = false
    },
    createproject(){
      this.showdialogcreateproject = false
      this.$router.push({name: 'projectplannerv2'})
    },
    async btnfunc(){
      await this.$axios.post('nav/logout').then(({data}) => {
        if(data.response){
          localStorage.removeItem('vuex')
          localStorage.removeItem('token')
          this.$router.push({name: 'login'})
          location.reload()
        }
      })
    },
    async clear(){
      await this.$axios.post('nav/logout')
      localStorage.clear()
      this.$router.push({name: 'login'})
      location.reload(true)
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>
<template>
  <v-app>
    <v-snackbar 
      v-model="mSnackbar.show" 
      bottom="bottom" 
      multi-line="multi-line" 
      :timeout="4000" 
      :vertical="true">
      {{ mSnackbar.message }}<br/>
      <v-btn v-if="mSnackbar.button" @click="sendotp" color="primary">Click here to send out new OTP</v-btn>
    </v-snackbar>
    <v-snackbar
      v-model="notification"
      :timeout="5000"
      :value="true"
      color="blue-grey"
      absolute
      right
      shaped
      top
      style="z-index: 999999;"
    >
      <span class="material-icons-outlined mr-1">
        notifications
      </span><small>Quote Receive</small><br/>
      <small>Project Name: {{notifDetails.desc}}</small><br/>
      <small>Message: {{notifDetails.message}}</small><br/>
      <small>From: {{notifDetails.from}}</small><br/>
    </v-snackbar>
    <!-- message notification -->
    <v-snackbar
      v-model="messageNotification"
      :timeout="5000"
      :value="true"
      color="blue-grey"
      absolute
      right
      shaped
      top
      style="z-index: 999999;"
    >
      <span class="material-icons-outlined mr-1" small>
        notifications
      </span><small>Message Receive</small><br/>
      <small v-if="notifDetails.project_name != null">Project: {{notifDetails.project_name}}</small><br/>
      <small>From: {{notifDetails.from}}</small><br/>
      <small>Message: {{notifDetails.message}}</small><br/>
    </v-snackbar>
    <!-- end notification -->
    <!-- message notification -->
    <v-snackbar
      v-model="request_quote_changes"
      :timeout="5000"
      :value="true"
      color="blue-grey"
      absolute
      right
      shaped
      top
      style="z-index: 999999;"
    >
      <span class="material-icons-outlined mr-1">
        notifications
      </span><small>Request Supplier Changes</small><br/>
    </v-snackbar>
    <!-- end notification -->
    <!-- message plan upload -->
    <v-snackbar
      v-model="plan_upload"
      :timeout="5000"
      :value="true"
      color="blue-grey"
      absolute
      right
      shaped
      top
      style="z-index: 999999;"
    >
      <span class="material-icons-outlined mr-1">
        notifications
      </span><small>Plan Upload Message From Supplier</small><br/>
    </v-snackbar>
    <!-- end message plan upload -->

    <TopNav />
    <v-main>
      <router-view/>
    </v-main>

    <!-- Error Message Dialog -->
    <v-row justify="center">
      <v-dialog
        v-model="get_errormessage.opendialog"
        persistent
        width="500">
        <v-card>
          <v-card-title class="error white--text">
            <v-icon color="error" class="mr-2">mdi-information</v-icon>{{ get_errormessage.title }}
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pt-3">
            {{ get_errormessage.message }}
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#0b4ef3" class="px-16 white--text" @click="close_errormessage_dialog()">Okay</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- ./Error Message Dialog -->

    <!-- Update Message Box -->
    <MessageBox></MessageBox>

    <!-- Alert Notification -->
    <AlertNotification></AlertNotification>
  </v-app>
</template>

<script>
import TopNav from './components/TopNav.vue'
import MessageBox from './components/messagebox/MessageBox.vue';
import AlertNotification from './client/components/NotificationComponent.vue'

import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'App',

  components: {
    TopNav,
    MessageBox,
    AlertNotification
  },

  data: () => ({
    //
    notification: false,
    messageNotification: false,
    request_quote_changes: false,
    plan_upload: false,
    notifDetails: {},
  }),
  async mounted(){    
    await this.$echo.private('quote-notification.' + this.getuser.id)
    .listen('QuoteNotification', (payload) => {
      this.notifDetails = payload
      this.showNotif()
    }),
    await this.$echo.private('message-notification.' + this.getuser.id)
    .listen('ChatMessage', (payload) => {
      this.notifDetails = payload
      this.showMessage()
    })
    await this.$echo.private('message-notification-supplier-directory.' + this.getuser.id)
    .listen('ChatMessageSupplierDirectory', (payload) => {
      this.notifDetails = payload
      this.showMessage()
    })
    await this.$echo.private('inform-supplier-request-quote-changes.' + this.getuser.id)
    .listen('InformSupplierRequestChanges', (payload) => {
      this.notifDetails = payload
      this.request_quote_changes_fn()
    })
    await this.$echo.private('uploadplan.' + this.getuser.id)
    .listen('ChatPlan', (payload) => {
      this.notifDetails = payload
      this.upload_plan_fn()
    })

    /** Inherit Module Broadcast */
    await this.$echo.private('inherit_module_event.' + this.getuser.id)
    .listen('InheritModuleEvent', (payload) => {
      console.log(payload);
    });
    /** ./Inherit Module Broadcast */

    this.close_errormessage_dialog();
  },
  created(){
    if(localStorage.getItem('id') != null){
      this.logout()
    }
    this.check_if_auth()
    this.check_login_count()
    // this.check()    
  },
  methods: {
    ...mapActions({
      set_errormessage_dialog:      'auth/set_errormessage_dialog_action'
    }),
    async logout(){
     await this.$axios.post('nav/logout').then(({data}) => {
       if(data.response){
         localStorage.clear()
         this.$router.push({name: 'login'})
         location.reload(true)
       }
     })
    },
    async check_if_auth(){
      if(localStorage.getItem('token') !== null){
        await this.$axios.get('/profile/is_auth')
        .then(({data}) => {
          if(!data.response){
            localStorage.clear()
            this.$router.push({name: 'login'})
            location.reload(true)
          }
        })
      }
    },
    async check_login_count(){
      await this.$axios.get('/profile/check_login')
    },
    showNotif(){
      this.$store.dispatch('supplierrequest/s_get_requests')
      const soundurl = new Audio(
        'https://www.freesoundslibrary.com/wp-content/uploads/2018/01/ding-sound-effect.mp3'
      )
      this.notification = true
      soundurl.volume = 0.5;
      soundurl.play()
    },
    showMessage(){
      // this.$store.dispatch('setGrabConvo', true)
      this.$store.dispatch('inbox/s_convo_from_server')
      this.$store.dispatch('supplier_inbox/s_convo_from_server')
      const soundurl = new Audio(
        'https://www.freesoundslibrary.com/wp-content/uploads/2018/01/ding-sound-effect.mp3'
      )
      this.messageNotification = true
      soundurl.volume = 0.5;
      soundurl.play()
    },
    request_quote_changes_fn(){
      const soundurl = new Audio(
        'https://www.freesoundslibrary.com/wp-content/uploads/2018/01/ding-sound-effect.mp3'
      )
      this.request_quote_changes = true
      soundurl.volume = 0.5;
      soundurl.play()
    },
    upload_plan_fn(){
      const soundurl = new Audio(
        'https://www.freesoundslibrary.com/wp-content/uploads/2018/01/ding-sound-effect.mp3'
      )
      soundurl.volume = 0.5
      soundurl.play()
      this.plan_upload = true
    },
    close_errormessage_dialog() {
      this.set_errormessage_dialog({
        title: null,
        message: null,
        opendialog: false,
      })
    }
  },
  computed: {
    ...mapGetters({
      mSnackbar:            'auth/mSnackbar',
      getproject:           'auth/getproject',
      getuser:              'auth/getuser',
      get_errormessage:     'auth/get_errormessage'
    }),
  },
  watch: {
    snackbar: function(newVal) {
        console.log(newVal)
    }
  }
};
</script>
import store from '@/states/index.js'

import BudgetManager from '../BudgetManager.vue'

export default [
    {
        path: '/budgetmanager_v2',
        component: BudgetManager,
        name: 'budgetmanager_v2',
        beforeEnter: (to, from, next) => {
            const role = store.getters['auth/getuser']
            if(role.info.role === 3){
                next()
            }else{
                next('/')
            }
            return
        }
    }
]
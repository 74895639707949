<template>
    <v-row justify="center">
        <v-dialog
            :value="value"
            persistent
            max-width="50%"
        >
            <v-card>
                <v-card-title class="text-h5">
                    <v-icon>mdi-cart</v-icon> Cart
                </v-card-title>
                <v-card-text>
                    <v-tabs
                        v-model="tab"
                        background-color="transparent"
                        color="basil"
                        grow
                    >
                    <v-tab
                        v-for="(item, index) in items"
                        :key="index"
                        :href="item.href"
                    >
                        {{ item.label }}
                    </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                        <v-tab-item
                            value="tab-1"
                        >
                            <Cart
                                :headers="headers"
                                :get_cart="get_cart"
                                :get_user="get_user"
                                @close_dialog="close_dialog"
                            />
                        </v-tab-item>
                        <v-tab-item
                            value="tab-2"
                        >
                            <CheckCart
                                :header_checked_ut="header_checked_ut"
                                :get_cart="get_checked_out_items"
                                :get_user="get_user"
                                @close_dialog="close_dialog"
                            />
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import Cart from './CartToCheck.vue'
import CheckCart from './CartCheckedOut.vue'
export default {
  components: {
    Cart,
    CheckCart
  },
  props: [
    'value'
  ],
  data () {
    return {
        headers: [
            {
                text:   'item',
                value:  'name',
                width:  '20%'
            },
            {
                text:   'image',
                width:  '10%'
            },
            {
                text:   'quantity',
                width:  '20%'
            },
            {
                text:   'date added',
                width:  '20%'
            },
            {
                text:   '',
            }
        ],
        tab: null,
        items: [
            {
                label:        'Cart',
                href:         '#tab-1'
            },
            {
                label:        'Checked out items',
                href:         '#tab-2'
            }
        ],
        header_checked_ut: [
            {
                text: 'item',
                value: 'name',
                width:  '20%'
            },
            {
                text: 'image',
                width: '20%'
            },
            {
                text: 'quantity',
                width: '20%'
            },
            {
                text: 'date ordered',
                width: '30%'
            }
        ]
    }
  },
  mounted () {
  },
  created () {
  },
  computed: {
    ...mapGetters({
        get_cart:               'shop/get_cart',
        get_user:               'auth/getuser',
        get_checked_out_items:  'shop/get_checked_out_items',
        // get_has_error:      'shop/get_has_error'
    })
  },
  methods: {
    close_dialog(){
        this.$emit('close_dialog')
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>
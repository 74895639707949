<template>
    <v-card
        flat
    >
        <v-card-text>
            <v-data-table
                :headers="header_checked_ut"
                :items="get_cart"
                class="elevation-1"
                hide-default-header
            >
                <template v-slot:header="{ props }">
                    <th 
                        v-for="(head, headindex) in props.headers"
                        :key="headindex"
                    >
                        <label
                            style="font-weight: 500; font-size: large; font-family: 'Nunito', sans-serif;"
                        >
                            {{ head.text }}
                        </label>
                    </th>
                </template>
                <template v-slot:item="props">
                    <tr>
                        <td><strong>{{ props.item.name }}</strong></td>
                        <td>
                            <v-img
                                width="300"
                                :aspect-ratio="16/9"
                                :src="`${image}${props.item.image}`"
                                class="ma-5"
                            />
                        </td>
                        <td>
                            <label>{{ props.item.qty }}</label>
                        </td>
                        <td>
                            <label
                                style="font-weight: 500; font-size: small;"
                            >
                                {{ props.item.created_at }}
                            </label>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="green darken-1"
            text
            @click="close_dialog()"
        >
            Close
        </v-btn>
        </v-card-actions>
    </v-card>
    </template>
    
    <script>
    export default {
      components: {
      },
      props: [
        'header_checked_ut',
        'get_cart',
        'get_user'
      ],
      data () {
        return {
            image: process.env.VUE_APP_URL,
        }
      },
      mounted () {
      },
      created () {
      },
      computed: {
      },
      methods: {
        close_dialog(){
            this.$emit('close_dialog')
        }
      },
      watch: {
      }
    }
    </script>
    
    <style scoped lang="scss">
    </style>
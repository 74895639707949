<template>
    <v-container fluid class="mt-3 flex">
        <v-card>
            <v-card-title class="mt-3 white--text" style="background: #062a82">
                <v-icon class="white--text pr-4">
                    mdi-monitor-dashboard
                </v-icon>
                <small>Dashboard</small>
            </v-card-title>
            <v-card-text>
                <v-row dense>
                    <v-col cols="3">
                        <v-card class="mt-3">
                            <v-card-title class="mt-3 white--text" style="background: #062a82">Reminders</v-card-title>                            
                            <v-card-text class="pt-4" style="background-color: #F5F5F5;height: 400px;">
                                <VueApexCharts type="donut" height="350" :options="get_todos.chart_options" :series="get_todos.series"></VueApexCharts>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="3">
                        <v-card class="mt-3">
                            <v-card-title 
                                class="mt-3 white--text" 
                                style="background: #062a82">
                                Site Diary 
                                <v-spacer></v-spacer>
                                <v-btn @click="view_module('sitediary')" small text class="white--text ml-3">view</v-btn>
                            </v-card-title>                            
                            <v-card-text class="pt-4" style="background-color: #F5F5F5;height: 400px;">
                                <VueApexCharts type="polarArea" height="350" :options="get_sitediary.chart_options" :series="get_sitediary.series"></VueApexCharts>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="3">
                        <v-card class="mt-3">
                            <v-card-title class="mt-3 white--text" style="background: #062a82">
                                Snags
                                <v-spacer></v-spacer>
                                <v-btn @click="view_module('snags')" small text class="white--text ml-3">view</v-btn>
                            </v-card-title>                            
                            <v-card-text class="pt-4" style="background-color: #F5F5F5;height: 400px;">
                                <VueApexCharts type="donut" height="350" :options="get_snags.chart_options" :series="get_snags.series"></VueApexCharts>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="3">
                        <v-card class="mt-3">
                            <v-card-title class="mt-3 white--text" style="background: #062a82">
                                File Manager
                                <v-spacer></v-spacer>
                                <v-btn @click="view_module('filemanager')" small text class="white--text ml-3">view</v-btn>
                            </v-card-title>                            
                            <v-card-text class="pt-4" style="background-color: #F5F5F5;height: 400px;">
                                <VueApexCharts type="polarArea" height="350" :options="get_filemanager.chart_options" :series="get_filemanager.series"></VueApexCharts>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>   
                <v-row dense>
                    <v-col cols="12">
                        <v-card class="mt-3">
                            <v-card-title class="mt-3 white--text" style="background: #062a82">
                                Design Tracker
                                <v-spacer></v-spacer>
                                <v-btn @click="view_module('designtracker')" small text class="white--text ml-3">view</v-btn>
                            </v-card-title>
                            <v-card-text class="mt-2">
                                <v-tabs
                                    v-model="tab"
                                    fixed-tabs
                                    background-color="#062a82"
                                    dark>
                                    <v-tab href="#fff">
                                        Furnitures, Fixtures and Fittings
                                    </v-tab>
                                    <v-tab href="#appliances">
                                        Appliances
                                    </v-tab>
                                    <v-tab href="#fc">
                                        Flooring Calculations
                                    </v-tab>
                                </v-tabs>
                                <v-tabs-items v-model="tab">
                                    <v-tab-item
                                        value="fff">
                                        <VueApexCharts type="bar" height="350" :options="get_designtracker_fff.chart_options" :series="get_designtracker_fff.series"></VueApexCharts>
                                    </v-tab-item>
                                    <v-tab-item
                                        value="appliances">
                                        <VueApexCharts type="bar" height="350" :options="get_designtracker_appliances.chart_options" :series="get_designtracker_appliances.series"></VueApexCharts>
                                    </v-tab-item>
                                    <v-tab-item
                                        value="fc">
                                        <VueApexCharts type="bar" height="350" :options="get_designtracker_fc.chart_options" :series="get_designtracker_fc.series"></VueApexCharts>
                                    </v-tab-item>
                                </v-tabs-items>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12">
                        <v-card class="mt-3">
                            <v-card-title class="mt-3 white--text" style="background: #062a82">
                                Decisions
                                <v-spacer></v-spacer>
                                <v-btn @click="view_module('decision')" small text class="white--text ml-3">view</v-btn>
                            </v-card-title>                            
                            <v-card-text class="pt-4" style="background-color: #F5F5F5;height: 400px;">
                                <VueApexCharts type="bar" height="350" :options="get_decisions.chart_options" :series="get_decisions.series"></VueApexCharts>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>    
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import { mapActions, mapGetters } from 'vuex';

export default {
    data: () => ({
        tab: null,
        todos_chart_options: {            
            labels: ['Pending', 'Completed'],
            chart: {
                type: 'donut'
            },
            fill: {
                colors: ['#2396f1', '#53af5c']
            },
            dataLabels: {
                enabled: true
            },
            legend: {
                position: 'bottom'
            },            
        }
    }),
    components: {
        VueApexCharts        
    },
    computed: {
        ...mapGetters({
            getuser:                        'auth/getuser',
            get_todos:                      'dashboard/get_todos_getter',
            get_sitediary:                  'dashboard/get_sitediary_getter',
            get_snags:                      'dashboard/get_snags_getter',
            get_filemanager:                'dashboard/get_filemanager_getter',
            get_decisions:                  'dashboard/get_decisions_getter',
            get_designtracker_fff:          'dashboard/get_designtracker_fff_getter',
            get_designtracker_appliances:   'dashboard/get_designtracker_appliances_getter',
            get_designtracker_fc:           'dashboard/get_designtracker_fc_getter'
        })
    },
    methods: {
        ...mapActions({
            fetch_reminders:                    'dashboard/fetch_reminders_action',
            fetch_sitediary:                    'dashboard/fetch_sitediary_action',
            fetch_snags:                        'dashboard/fetch_snags_action',
            fetch_filemanager:                  'dashboard/fetch_filemanager_action',
            fetch_decisions:                    'dashboard/fetch_decisions_action',
            fetch_designtracker_fff:            'dashboard/fetch_designtracker_fff_action',
            fetch_designtracker_appliances:     'dashboard/fetch_designtracker_appliances_action',
            fetch_designtracker_fc:             'dashboard/fetch_designtracker_fc_action'
        }),
        view_module(module_name) {
            this.$router.push({name: module_name});
        }
    },
    mounted() {
        setTimeout(() => {
            this.fetch_reminders({users_id: this.getuser.id, project_id: this.getuser.project_id.id});
            this.fetch_sitediary({users_id: this.getuser.id, project_id: this.getuser.project_id.id});
            this.fetch_snags({users_id: this.getuser.id, project_id: this.getuser.project_id.id});
            this.fetch_filemanager({users_id: this.getuser.id, project_id: this.getuser.project_id.id});
            this.fetch_decisions({users_id: this.getuser.id, project_id: this.getuser.project_id.id});
            this.fetch_designtracker_fff({users_id: this.getuser.id, project_id: this.getuser.project_id.id});
            this.fetch_designtracker_appliances({users_id: this.getuser.id, project_id: this.getuser.project_id.id});
            this.fetch_designtracker_fc({users_id: this.getuser.id, project_id: this.getuser.project_id.id});
        }, 400);
    },
    created() {
        
    }
}
</script>
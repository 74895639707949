<template>
    <v-container fluid class="mt-5 flex">
      <div v-if="getuser.project_id == null" align="center">
        <strong>You need to create a project</strong>
        <p>Kindly click the Proceed button below to create a project, and we can continue to add entries</p>
        <v-btn 
          color="#0b4ef3" 
          dark 
          class="pl-16 pr-16" 
          rounded
          @click="$router.push({name: 'projectplannerv2'})"
        >
          Proceed
        </v-btn>
        <DataTable
          class="mt-5"
          :setup="tablesetupempty"
          :items="emptydata"
        />
      </div>
      <div v-else>
        <!--<DataTable
          :setup="tablesetup"
          :items="getsitediary"
          @addsitediary="addsitediary"
          @viewimage="viewimage"
          @editchild="editchild"
        />-->
        <ViewSiteDiary></ViewSiteDiary>
        <!--<DialogComponent
          v-model="showdialogsitediary"
          :value="showdialogsitediary"
          :dialogvalue="dialogsitediaryvalue"
          :ddvalues="gettags"
          @closedialog="closedialog"
          @savesitediary="savesitediary"
        />
        <DialogComponent
          v-model="viewimagedialog"
          :value="viewimagedialog"
          :dialogvalue="childimagesetup"
          :childdata="getimage"
          @closedialog="closedialog"
        />
        <DialogComponent
          v-model="editchilddialog"
          :value="editchilddialog"
          :dialogvalue="editchildsetup"
          :ddvalues="gettags"
          :childdata="getchilddata"
          @closedialog="closedialog"
          @saveeditedsitediary="saveeditedsitediary"
          @deletesitediaryentry="deletesitediaryentry"
        />
        <DialogWarning
          v-model="deletesitediaryentrydialog"
          :value="deletesitediaryentrydialog"
          :dialogsvalue="dialogwarningvalues"
          @delproject="deleteitem"
        />-->
      </div>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
//import DataTable from '../../components/DataTable.vue'
//import DialogComponent from '../../components/DialogComponent.vue'
//import DialogWarning from '../../components/DialogWarning.vue'
import ViewSiteDiary from './components/ViewSiteDiary.vue'

export default {
  components: {
    //DataTable,
    //DialogComponent,
    //DialogWarning,
    ViewSiteDiary
  },
  props: [
  ],
  data: () => ({
    tablesetup: {
      sitediary: true,
      name:       'Site Diary',
      icon:       'mdi-notebook',
      tableheader: [
        { text: 'Log Name', filterable: true},
        { text: 'Notes', filterable: true},
        { text: 'Tags', filterable: true},
        { text: 'Date', filterable: true},
        { text: 'Site Date', filterable: true},
        { text: 'Media', filterable: true}
      ],
      button: [
        {
          id:           1,
          icon:         'mdi-plus',
          label:        'add entry',
          dark:         true,
          action:       true,
          text:         false,
          class:        'pl-16 pr-16',
          color:        '#0b4ef3',
          classicon:    'mr-2'
        }
      ]
    },
    tablesetupempty: {
      empty: true,
      name:       'Site Diary | Sample Entries',
      icon:       'mdi-notebook',
      tableheader: [
        { text: 'Log Name', filterable: true, value: 'log_name'},
        { text: 'Notes', filterable: true, value: 'notes'},
        { text: 'Tags', filterable: true, value: 'tags'},
        { text: 'Date', filterable: true, value: 'created_at'},
        { text: 'Site Date', filterable: true, value: 'site_diary_date'},
        { text: 'Media', filterable: true, value: 'image'}
      ],
    },
    emptydata: [
      {id: 1, log_name: 'Example: Door Damage', notes: 'Example: Damage to front door', tags: ["Studding"], created_at: 'June 05, 2020', site_diary_date: 'May 07, 2020', image: '1633531735.jpg'},
      {id: 2, log_name: 'Example: Underfloor heating kitchen', notes: 'Underfloor heating kitchen for the kitchen area', tags: ["Underfloor Heating"], created_at: 'June 05, 2020', site_diary_date: 'May 07, 2020', image: '1633531746.jpg'},
      {id: 3, log_name: 'Example: Chased walls sitting room', notes: 'This is in the corner next to the TV', tags: ["Sitting Room Chasing"], created_at: 'June 05, 2020', site_diary_date: 'May 07, 2020', image: '1633531757.jpg'},
      {id: 4, log_name: 'Example: Mechanical Ventilation Kitchen', notes: 'Mechanical Ventilation Kitchen', tags: ["Mechanical Kitchen"], created_at: 'June 05, 2020', site_diary_date: 'May 07, 2020', image: '1633531772.jpg'},
      {id: 5, log_name: 'Example: Scaffolding', notes: 'Ladder not tied off', tags: ["Scaffolding"], created_at: 'June 05, 2020', site_diary_date: 'May 07, 2020', image: '1633532176.jpg'},
      {id: 6, log_name: 'Example: Foundation', notes: 'Foundation', tags: ["Foundation Base"], created_at: 'June 05, 2020', site_diary_date: 'May 07, 2020', image: '1633531784.jpg'},
      {id: 7, log_name: 'Example: Studding', notes: 'This is the studding between the 2 small bedroom up stairs', tags: ["Studding"], created_at: 'June 05, 2020', site_diary_date: 'May 07, 2020', image: '1633531853.jpg'},
      {id: 8, log_name: 'Example: 1st fix plumbing and electrics kitchen', notes: '1st fix plumbing and electrics kitchen', tags: [""], created_at: 'June 05, 2020', site_diary_date: 'May 07, 2020', image: '1633532060.jpg'},
      {id: 9, log_name: 'Example: Sewage pipe locations foundation', notes: 'Location sewage pipes', tags: [""], created_at: 'June 05, 2020', site_diary_date: 'May 07, 2020', image: '1633531955.png'},
    ],
    showdialogsitediary: false,
    dialogsitediaryvalue: {
      sitediary:  true,
      title:      'add site diary',
      icon:       'mdi-notebook',
      datenow:    new Date().toLocaleDateString(),
      date: [
        {
          id:       0,
          label:    'site date',
          hint:     'site date',
          icon:     'mdi-calendar'
        }
      ],
      textfield: [
        {
          id:       1,
          label:    'log name',
          hint:     'log name',
          icon:     'mdi-tooltip-text-outline'
        }
      ],
      textarea: [
        {
          id:       2,
          label:    'notes',
          hint:     'notes',
          icon:     'mdi-tooltip-text-outline'
        }
      ],
      dropdown: [
        {
          id:       3,
          label:    'add some tags',
          hint:     'add some tags',
          icon:     'mdi-tooltip-text-outline'
        }
      ],
      image: [
        {
          id:       4,
          label:    'add image',
          hint:     'add image',
          icon:     'mdi-tooltip-text-outline'
        }
      ]
    },
    viewimagedialog: false,
    childimagesetup: {
      isimage:  true,
      title:  'view image'
    },
    editchilddialog: false,
    editchildsetup: {
      editchildsitediary: true,
      title:              'edit item',
      button:  [
        {
          id:           1,
          icon:         'mdi-delete',
          label:        'delete',
          dark:         false,
          text:         true,
          class:        '',
          color:        '#0b4ef3',
          classicon:    'mr-2',
          action:       true,
        }
      ],
      date: [
        {
          id:       2,
          label:    'site date',
          hint:     'site date',
          icon:     'mdi-calendar'
        }
      ],
      textfield: [
        {
          id:       3,
          label:    'log name',
          hint:     'log name',
          icon:     'mdi-tooltip-text-outline'
        }
      ],
      textarea: [
        {
          id:       4,
          label:    'notes',
          hint:     'notes',
          icon:     'mdi-tooltip-text-outline'
        }
      ],
      dropdown: [
        {
          id:       5,
          label:    'add some tags',
          hint:     'add some tags',
          icon:     'mdi-tooltip-text-outline'
        }
      ],
      image: [
        {
          id:       6,
          label:    'replace image',
          hint:     'replace image',
          icon:     'mdi-tooltip-text-outline'
        }
      ]
    },
    deletesitediaryentrydialog: false,
    dialogwarningvalues: {
      warningdelete: true,
      title: 'Are you sure you want to delete this entry?',
      content: 'All of the data that belongs to this entry will be deleted, are you sure about this?',
      buttons: [
        {
          id:         2,
          label:      'Yes',
          action:     true,
          class:      '',
          text:       true,
          dark:       false
        },
        {
          id:         1,
          label:      'No',
          action:     false,
          color:      '#0b4ef3',
          text:       false,
          class:      'pl-16 pr-16',
          dark:       true
        },
      ]
    }
  }),
  async mounted () {    
    await this.restore_sitediaries_data({
      user_id: this.getuser.info.id,
      project_id: this.getproject.id
    })
    this.fetchrecord()
  },
  created () {
  },
  computed: {
    ...mapGetters({
      getuser:              'auth/getuser',
      getproject:           'auth/getproject',
      getsitediary:         'sitediary/getsitediary',
      gettags:              'sitediary/gettags',
      getimage:             'sitediary/getimage',
      getchilddata:         'sitediary/getedititem'
    })
  },
  methods: {
    ...mapActions({
      restore_sitediaries_data:     'sitediary/restore_sitediaries_data_action',      
    }),
    showSnackBar(message){
      this.$store.commit("auth/setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    async fetchrecord(){
      //this.$store.dispatch('sitediary/callsitediary', this.getproject)
      await this.$axios.get(`sitediary/getSiteDiary/${this.getproject.id}`)
      .then(({data}) => {
        this.$store.dispatch('sitediary/setsitediary', data.data)
      })
      //this.$store.dispatch('sitediary/fetchtags')
      await this.$axios.get('sitediary/getTags')
      .then(({data}) => {
        let toflat = []
        data.data.forEach(q => {
          toflat.push(q.tags)
        })
        this.$store.dispatch('sitediary/settags', toflat)
      })
    },
    closedialog(){
      this.showdialogsitediary = false
      this.viewimagedialog = false
      this.editchilddialog = false
      this.deletesitediaryentrydialog = false
    },
    addsitediary(){
      this.showdialogsitediary = true
    },
    async savesitediary(data){
      const formData = new FormData()
      formData.append('date', data.textfield[0].sitedate)
      formData.append('logname', data.textfield[0].logname)
      formData.append('tags', data.dropdown)
      formData.append('file', data.textfield[0].image)
      formData.append('notes', data.textfield[0].notes)      
      await this.$axios.post(`sitediary/addSiteDiaryEntry/${this.getproject.id}`, formData)
      .then(({data}) => {
        if(data.response){
          this.showSnackBar(data.message)
          this.closedialog()
          this.fetchrecord()
        }
      })
    },
    viewimage(){
      this.viewimagedialog = true
    },
    editchild(){
      this.editchilddialog = true
    },
    async saveeditedsitediary(data){
      const formData = new FormData()
      formData.append('date', data.textfield[0].date)
      formData.append('logname', data.textfield[0].logname)
      formData.append('tags', data.dropdown)
      formData.append('file', data.textfield[0].image)
      formData.append('notes', data.textfield[0].notes)      
      formData.append('dImage', data.textfield[0].toremove)
      formData.append('id', this.getchilddata.id)
      await this.$axios.post(`sitediary/editDiaryLog/${this.getproject.id}`, formData)
      .then(({data}) => {
        if(data.response){
          this.closedialog()
          this.fetchrecord()
        }
      })
    },
    deletesitediaryentry(){
      //console.log('deletesitediaryentry')
      this.deletesitediaryentrydialog = true
    },
    async deleteitem(){
      //console.log('here')
      await this.$axios.delete(`sitediary/deleteItem/${this.getchilddata.id}`)
      .then(({data}) => {
        if(data.response){
          this.closedialog()
          this.fetchrecord()
        }
      })
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>
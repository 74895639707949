import Vue from 'vue'

export default {
    namespaced: true,
    state: {
        categories: [],
        selected_category: {},
        items: [],
        to_delete_item: {},
        to_edit_item: {}
    },
    mutations: {
        set_categories(state, payload){
            state.categories = [...payload]
        },
        set_selected_category(state, payload){
            state.selected_category = payload
        },
        set_items(state, payload){
            state.items = [...payload]
        },
        set_to_delete_item(state, payload){
            state.to_delete_item = payload
        },
        set_to_edit_item(state, payload){
            state.to_edit_item = payload
        }
    },
    getters: {
        get_categories : (state) => state.categories,
        get_selected_category : (state) => state.selected_category,
        get_items : (state) => (value) => {
            if(value == null || value == ""){
                return state.items
            }
            return state.items.filter( q => {
                return q.name.toLowerCase().includes(value)
            })
        },
        get_to_delete_item : (state) => state.to_delete_item,
        get_to_edit_item : (state) => state.to_edit_item
    },
    actions: {
        async get_categories({commit}){
            await Vue.axios.get('/shop/get_all_categories')
            .then(({data}) => {
                commit('set_categories', data.data)
            })
        },
        async add_category(_, payload){
            await Vue.axios.post('/shop/add_category_data', payload)
        },
        async get_items_via_cat_id({commit}, payload){
            commit('set_selected_category', payload)
            await Vue.axios.get(`/shop/get_item_via_category_id/${payload.id}`)
            .then(({data}) => {
                commit('set_items', data.data)
            })
        },
        async delete_item(_, payload){
            await Vue.axios.delete(`/shop/delete_item/${payload.id}`)
        },
        set_to_delete_item({commit}, payload){
            commit('set_to_delete_item', payload)
        },
        set_to_edit_item({commit}, payload){
            commit('set_to_edit_item', payload)
        }
    }
}
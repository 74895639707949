import Vue from 'vue'
import VueRouter from 'vue-router'

import AuthRoutes from './modules/auth/routes/authroutes.js'
import HomeRoutes from './modules/home/routes/home.js'
import ProjectPlannerRoutes from './modules/projectplanner/routes/projectplanner.js'
import UploadPlanRoutes from './modules/uploadplans/routes/upload.js'
import SiteDiaryRoutes from './modules/sitediary/routes/sitediary.js'
import FileManagerRoutes from './modules/filemanager/routes/filemanager.js'
import DesignTracker from './modules/designtracker/routes/designtracker.js'
import Decision from './modules/decisions/routes/decision.js'
import Snags from './modules/snags/routes/snag.js'
import BudgetManager from './modules/budgetmanager/routes/budgetmanager.js'
import SupplierDirectory from './modules/supplierdirectory/routes/supplierdirectory.js'
import SupplierRequest from './modules/supplierrequest/routes/supplierrequest.js'
import Inbox from './modules/inbox/routes/inbox.js'
import Chase from './modules/ChaseTheTrade/routes/chasethetrade.js'
import Tips from './modules/tips/routes/tips.js'
import HelperTraining from './modules/helpertraining/routes/index.js'
import TradeRateSuppliers from './modules/traderatesuppliers/routes/suppliers.js'
import VideoArchives from './modules/video_archived/routes/index.js'
import ProjectPlannerV2 from './modules/project_planner_v2/routes/index.js'
import SiteDiaryV2 from './client/sitediary/routes'
import BudgetManagerV2 from './client/budgetmanager/routes'

// Supplier
import SupplierProfile from './supplier/profile/routes/profile.js'
import SupplierCompanyProfile from './supplier/company_profile/routes/company_profile.js'
import SupplierInbox from './supplier/inbox/routes/inbox.js'
import SupplierNotification from './supplier/notification/routes/notification.js'
import SupplierToBeQuoted from './supplier/to_be_quoted/routes/tobequoted.js'
import SupplierQuoted from './supplier/supplier_quoted/routes/quoted.js'
import SupplierApprovedQuotes from './supplier/supplier_approved_quotes/routes/supplier_approved_quoted.js'
import SupplierLostQuotes from './supplier/supplier_lost_quotes/routes/supplier_lost_quotes.js'
import ClientPlan from './supplier/client_plans/routes/clientplan.js'
import FormRequestS from './supplier/form_requests/routes/index.js'
// End Supplier

// SB3D
import SB3DRoute from './sb3d/landing_page/routes/sb3d.js'
// SB3D End

// Admin Route
import AdminHomeDashboard from './admin/landing_page/routes/home.js'
import AdminSupplierDashboard from './admin/suppliers/routes/supplier.js'
import AdminServiceTypes from './admin/service_types/routes/servicetype.js'
import AdminSupplierReports from './admin/supplier_reports/routes/supplier_reports.js'
import AdminUser from './admin/users/routes/user.js'
import ProjectPlannerTemplate from './admin/project_planner/routes/project_planner.js'
import AdminServiceRequestReports from './admin/service_request_reports/routes/index.js'
import FormRequest from './admin/form_request/routes/index.js'
import PlanUpload from './admin/plan_upload/routes/plan_upload.js'
import AdminTips from './admin/tips/routes/tips.js'
import AdminHelperTraining from './admin/helper_training_files/routes/index.js'
import AdminTradeRateSuppliers from './admin/traderatesuppliers/routes/suppliers.js'
import AdminVideoArchived from './admin/video_archived/routes/index.js'
import AdminAssetsUploader from './admin/assets_uploader/routes/index.js'
import AdminECommerce from './admin/shop/routes/index.js'
// End Admin Route

// non auth route
import NonAuthUploadPlan from './modules/non_auth_upload_plan/routes/non_auth_upload.js'
import NonAuthForm from './modules/form/routes/index.js'

// Shop component
import ShopComponent from './modules/shop/router/shop.js'

Vue.use(VueRouter)

export default new VueRouter({
    mode: 'history',
    routes : [
        ...AuthRoutes,
        ...HomeRoutes,
        ...ProjectPlannerRoutes,
        ...UploadPlanRoutes,
        ...SiteDiaryRoutes,
        ...FileManagerRoutes,
        ...DesignTracker,
        ...Decision,
        ...Snags,
        ...BudgetManager,
        ...SupplierDirectory,
        ...SupplierRequest,
        ...Inbox,
        ...Chase,
        ...SupplierProfile,
        ...SupplierCompanyProfile,
        ...SupplierInbox,
        ...SupplierNotification,
        ...SupplierToBeQuoted,
        ...SupplierQuoted,
        ...SupplierApprovedQuotes,
        ...SupplierLostQuotes,
        ...SB3DRoute,
        ...AdminSupplierDashboard,
        ...AdminHomeDashboard,
        ...AdminServiceTypes,
        ...AdminUser,
        ...AdminSupplierReports,
        ...ClientPlan,
        ...NonAuthUploadPlan,
        ...ProjectPlannerTemplate,
        ...AdminServiceRequestReports,
        ...NonAuthForm,
        ...FormRequest,
        ...FormRequestS,
        ...PlanUpload,
        ...AdminTips,
        ...Tips,
        ...AdminHelperTraining,
        ...HelperTraining,
        ...AdminTradeRateSuppliers,
        ...TradeRateSuppliers,
        ...AdminVideoArchived,
        ...VideoArchives,
        ...AdminAssetsUploader,
        ...ProjectPlannerV2,
        ...ShopComponent,
        ...AdminECommerce,
        ...SiteDiaryV2,
        ...BudgetManagerV2
    ]
})
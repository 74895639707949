import Vue from 'vue';

export default class GeoLocation {
    constructor(store) {                              
        this.store = store
    }

    saveClientPinBuildLocation(payload) {
        Vue.axios.post(`build_location/save_build_location`, payload).then( (data) => {
            if ( data.status == 200 ) {
                const result = data.data
                if ( result.response == true ) {
                    this.store.dispatch('auth/updateUserBuildLocationAction', {
                        address: result.data.address,
                        town: result.data.town,
                        county: result.data.county,
                        zipcode: result.data.zipcode
                    })
                } 
            }
        })
    }
   
}
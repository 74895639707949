<template>
    <v-row justify="center" class="mt-4 mb-2">
        <v-card width="800">
            <v-card-title>
                <v-icon class="mr-2" color="success">mdi-map</v-icon>
                Build Location
                <v-spacer></v-spacer>
                <v-btn v-if="getuser.info.zipcode != null && getuser.info.town != null && getuser.info.county != null" small fab color="primary" @click="evtOpenPinGeoLocationDialog()">
                    <v-icon>mdi-map-search</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>            
            <v-card-text>
                <v-list two-line>            
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon color="primary">
                                mdi-map-marker
                            </v-icon>
                        </v-list-item-icon>

                        <v-list-item-content v-if="getuser.info.zipcode != null && getuser.info.town != null && getuser.info.county != null">
                            <v-list-item-title><strong>{{ getuser.info.address != null ? getuser.info.address + "," : "" }} {{ getuser.info.town }}</strong></v-list-item-title>
                            <v-list-item-subtitle>{{ getuser.info.county }} {{ getuser.info.zipcode }}, Ireland</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content v-else>
                            <v-list-item-title><strong>Currently, there are no build location specified.</strong></v-list-item-title>
                            <v-list-item-subtitle>Click the red pin marker to the left to properly pin your build location.</v-list-item-subtitle>
                        </v-list-item-content> 
                        
                        <v-list-item-action>
                            <v-btn icon @click="evtOpenPinBuildLocationDialog()">
                                <v-icon color="error">mdi-map-marker-circle</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-card-text>
            <ViewGeoLoation
                :pin_geo_location_dialog="pin_geo_location_dialog"
                @evntClosePinGeoLocationDialog="evtClosePinGeoLocationDialog()"></ViewGeoLoation>

            <PinBuildLocation
                :pin_build_location_dialog="pin_build_location_dialog"
                @evtClosePinBuildLocationDialog="evtClosePinBuildLocationDialog()"></PinBuildLocation>
        </v-card>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import GeoLocation from '../modules/GeoLocation.js'
import ViewGeoLoation from './ViewGeoLoation.vue';
import PinBuildLocation from './PinBuildLocation.vue';

export default {
    data: () => ({
        formatted_address: null,       
        geo_location: null,
        pin_geo_location_dialog: false,
        pin_build_location_dialog: false,
        api_key: process.env.VUE_APP_GOOGLE_MAP_API_KEY
    }),
    components: {
        ViewGeoLoation,
        PinBuildLocation
    },
    computed: {
        ...mapGetters({
            getuser:                    'auth/getuser',
        })
    },
    methods: {
        evtOpenPinGeoLocationDialog() {
            this.pin_geo_location_dialog = true
        },
        evtClosePinGeoLocationDialog() {
            this.pin_geo_location_dialog = false
        },
        evtOpenPinBuildLocationDialog() {
            this.pin_build_location_dialog = true
        },
        evtClosePinBuildLocationDialog() {
            this.pin_build_location_dialog = false
        }        
    },
    mounted() {        
        this.geo_location = new GeoLocation(this.api_key, this.$store)
        //this.geo_location.getClientGeoLocationByAddressOrEircode('P25 E193')
    }
}
</script>
<template>
    <div>
        <v-row justify="center" class="mt-6">
            <v-col lg="6">
                <v-data-table            
                    :items="get_reminders_data(false)"
                    :headers="headers"
                    class="elevation-1">
                    <template v-slot:[`item.is_completed`]="{ item }">
                        <v-checkbox
                            v-model="item.is_completed"
                            @click="complete_reminder(item)">
                        </v-checkbox>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row justify="center" class="mt-3 text-center">
            <v-col lg="6">
                <v-btn                     
                    color="#0b4ef3"
                    dark
                    rounded
                    outlined
                    class="pl-16 pr-16"
                    @click="expand = !expand">COMPLETED REMINDER</v-btn> 
            </v-col>
        </v-row>
        <v-expand-transition>
            <v-row justify="center" class="mt-3 text-center" v-show="expand">
                <v-col lg="6">
                    <v-data-table            
                        :items="get_reminders_data(true)"
                        :headers="headers"
                        class="elevation-1">
                        <template v-slot:[`item.is_completed`]="{ item }">
                            <v-checkbox
                                v-model="item.is_completed"
                                @click="uncomplete_reminder(item)">
                            </v-checkbox>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-expand-transition>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data: () => ({
        headers: [
            { text: 'Action', value: 'is_completed' },
            { text: 'Reminders', value: 'todo', width: '85%'}
        ],
        expand: false
    }),
    computed: {
        ...mapGetters({
            getuserinfo:            'auth/getuser',
            getproject:             'auth/getproject',
            get_reminders_data:     'reminders/get_reminders_data_getter'
        })
    },
    methods: {
        ...mapActions({
            fetch_all_reminders_data:   'reminders/fetch_all_reminders_data_action',
            set_complete_reminder:      'reminders/set_complete_reminder_action',
            set_uncomplete_reminder:    'reminders/set_uncomplete_reminder_action'
        }),
        complete_reminder(item) {
            this.set_complete_reminder(item);
        },
        uncomplete_reminder(item) {
            this.set_uncomplete_reminder(item);
        }
    },
    mounted() {
        
    },
    created() {
        if ( this.getproject.id != null ) {
            this.fetch_all_reminders_data({
                users_id: this.getuserinfo.id,
                project_id: this.getproject.id
            });
        }
    },
    watch: {

    }
}
</script>
<template>
    <v-row justify="center">
        <v-dialog
            v-model="edit_dialog"
            scrollable
            width="400">
            <v-card>
                <v-card-title>
                    Update Outstanding Amount
                </v-card-title>
                <v-card-text>
                    <small>Current Outstanding Amoung: {{ get_auth_users.info.currency_symbol }} {{ current_outstanding_amount }}</small>
                    <v-text-field
                        v-model="new_outstanding_amount"
                        prefix="€"
                        ></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small text color="error" @click="close_dialog()" 
                        class="px-6">
                        <v-icon>mdi-cancel</v-icon>No
                    </v-btn>
                    <v-btn small class="white--text px-6"
                        color="#0b4ef3"
                        @click="update_outstanding()">
                        <v-icon>mdi-check-circle-outline</v-icon>Yes
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data: () => ({
        new_outstanding_amount: 0,
        current_outstanding_amount: 0,
        edit_dialog: false        
    }),
    props: {
        budgetmanager_data: Object,
        edit_outstanding_dialog: Boolean
    },
    computed: {
        ...mapGetters({
            getbudgetmanagertransactionhistory:     'budgetmanager/getbudgetmanagertransactionhistory',
            get_auth_users:                         'auth/getuser'
        })
    },
    methods: {
        ...mapActions({
            fetch_budgetmanager_transaction_history:        'budgetmanager/fetch_budgetmanager_transaction_history_action',
            update_budgetmanager_outstanding_amount:        'budgetmanager/update_budgetmanager_outstanding_amount_action'
        }),
        close_dialog() {
            this.$emit('close_dialog');
        },
        update_outstanding() {
            this.update_budgetmanager_outstanding_amount({budgetmanager_id: this.budgetmanager_data.item.id, amount: this.new_outstanding_amount});
            this.close_dialog();
        } 
    },
    watch: {
        async edit_outstanding_dialog() {
            this.edit_dialog = this.edit_outstanding_dialog;
            
            if ( this.edit_outstanding_dialog == true ) {
                if ( this.budgetmanager_data.item.outstanding == null ) {
                    this.current_outstanding_amount = 0;
                } else {
                    this.current_outstanding_amount = this.budgetmanager_data.item.outstanding;
                }
                console.log(this.budgetmanager_data.item.id);
                const tp = {
                    parent_id:              this.budgetmanager_data.item.parent_id,
                    budget_manager_id:      this.budgetmanager_data.item.id,
                    project_id:             this.budgetmanager_data.item.project_id
                };

                await this.fetch_budgetmanager_transaction_history({users_id: this.budgetmanager_data.item.users_id, tp: tp});
            }

        }
    }
}
</script>
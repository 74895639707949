<template>
    <v-container fill-height fluid>
        <v-row align="center" justify="center">
            <v-card
                class="mx-auto"
                outlined
                style="width: 500px;"
            >
            <v-toolbar dark color="#073095">
                <v-card-title>
                    Login
                </v-card-title>
            </v-toolbar>
            <v-card-text>
                <v-text-field
                    prepend-icon="person"
                    v-model="c.username"
                    name="Email or Username"
                    label="Email or Username"
                    type="text"
                >
                </v-text-field>
                <v-text-field
                    prepend-icon="lock"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    name="Password"
                    label="Password"
                    v-model="c.password"
                    class="input-group--focused"
                    @click:append="show = !show"
                    @keypress.enter="login"
                >
                </v-text-field>
            </v-card-text>
            <v-card-actions>
              <small @click="$router.push({name: 'forgotpassword'})" style="cursor: pointer;" class="ml-4">Forgot Password?</small>
              <v-spacer></v-spacer>
              <v-btn color="#0942cd" text @click="$router.push({name: 'register'})">Register</v-btn>
              <v-btn 
                color="#093cba" 
                class="white--text pl-15 pr-15" 
                @click="login"
              >
                Login
              </v-btn>
            </v-card-actions>
            </v-card>
        </v-row>
        <v-dialog
            transition="dialog-bottom-transition"
            max-width="600"
            persistent
            v-model="OTPdialog"
        >
            <v-card>
                <v-toolbar
                    color="primary"
                    dark
                >Please enter the OTP code sent to your email: <br/>{{c.username}}</v-toolbar>
                <v-card-text>
                    <v-text-field
                        standard
                        v-model="otpInput"
                        label="OTP Code"
                    >
                    </v-text-field>
                </v-card-text>
                <v-card-actions class="justify-end">
                <v-btn
                    text
                    @click="verifyOtp"
                >Verify</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
  components: {
  },
  props: [
  ],
  data: () => ({
    c: {
      username: null,
      password: null
    },
    show: false,
    OTPdialog: false,
    otpInput: '',
    userId: '',
  }),
  mounted () {
  },
  created () {
    if(localStorage.getItem('token') !== null){
      //this.$router.push({name: 'home'})
      const data = JSON.parse(localStorage.getItem('vuex'))
      //console.log(data.auth.user.role)
      if(data.auth.user.role == 1){
        this.$router.push({name: 'admin_dashboard'})
        return
      }
      if(data.auth.user.role == 2){
        this.$router.push({name: 'supplier_company_profile'})
        return
      }
      if(data.auth.user.role == 3){
        this.$router.push({name: 'home'})
        return
      }
      if(data.auth.user.role == 4){
        this.$router.push({name: 'sb3d_page'})
        return
      }
    }
  },
  computed: {
  },
  methods: {
    showOtp(){
      this.OTPdialog = true
    },
    async getIdForUser(){
      await this.$axios.get(`getIdForUser/${this.c.username}`)
      .then(({data}) => {
        this.userId = data.id
      })
    },
    async verifyOtp(){
      const toPass = {
          id: this.userId,
          otp: this.otpInput
      };
      await this.$axios.post('verifyOtp', toPass)
      .then(({data}) => {
          if(data.response){
              this.OTPdialog = false
              this.showSnackBar(data.message)
          }else{
              this.showSnackBar(data.message)
          }
      })
    },
    showSnackBar(message){
      this.$store.commit("auth/setMessage", 
      {show: true, message: message}, 
      {root: 1})
    },
    async login(){
      const logincreds = {
        username:   this.c.username,
        password:   this.c.password
      }
      await this.$axios.get('/csrf-cookie').then(response => {
        if(response){
          this.$axios.post('login', logincreds)
          .then(({data}) => {
            if(data.response){
              switch(data.info.role){
                case 1:
                  // admin
                  this.$store.dispatch('auth/setuser', data)
                  localStorage.setItem('token', data.data)
                  this.$router.push({name: 'admin_dashboard'})
                  location.reload()
                  break
                case 2:
                  // supplier
                  this.$store.dispatch('auth/setuser', data)
                  localStorage.setItem('token', data.data)
                  this.$router.push({name: 'supplier_company_profile'})
                  location.reload()
                  break
                case 3:
                  // client / user                 
                  this.$store.dispatch('auth/setuser', data)
                  localStorage.setItem('token', data.data)                  
                  this.$router.push({name: 'home'})
                  location.reload()
                  break
                case 4:
                  // admin
                  this.$store.dispatch('auth/setuser', data)
                  localStorage.setItem('token', data.data)
                  this.$router.push({name: 'sb3d_page'})
                  location.reload()
                  break
              }
            }else{
              this.showSnackBar(data.message)
              if(data.requireOtp){
                this.getIdForUser()
                this.showOtp()
              }
            }
            //if(data.response){
            //  this.$store.dispatch('auth/setuser', data)
            //  localStorage.setItem('token', data.data)
            //  this.$router.push({name: 'home'})
            //}else{
            //  this.showSnackBar(data.message)
            //}
          })
        }
      })
    },
    register(){
      console.log('meow')
    },
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>